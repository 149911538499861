import React from "react"

const DownloadOriginalDocumentResource = ({ documentSlug }) => {
    return <a className="button is-link" href={`/documents/${documentSlug}/download_original`} target="_blank">
        <span className="icon is-small">
            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z"/></g></svg>
        </span>
        <b> Download Original</b>
    </a>
}

export default DownloadOriginalDocumentResource
