import React from "react"

import BaseToggleStep from "../BaseToggleStep"

const SecuritySummaryStep = ({
  trustCenter,
  onPanelClose = () => { },
  onTrustCenterChange = () => { }
}) => {
  return <BaseToggleStep
    labelAttr="text"
    onPanelClose={() => onPanelClose()}
    onTrustCenterChange={trustCenter => onTrustCenterChange(trustCenter)}
    paramSetUrl="/security_summaries.json"
    trustCenter={trustCenter}
    trustCenterAttr="securitySummaries"
    trustCenterParam="security_summary_ids"
    title="Security Summaries" />
}

export default SecuritySummaryStep