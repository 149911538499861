import React, { useState } from "react"

import styles from "./style.module"

const FAQ = ({
  faq,
  isFirstRow = false,
  isLastRow = false,
}) => {
  const [expanded, setExpanded] = useState(false)

  return <>
    <div className={expanded ? styles.faqExpanded : styles.faq} onClick={() => setExpanded(!expanded)} style={{
      borderBottom: isLastRow ? 'solid 1px #CED0D2' : 0,
      borderTopLeftRadius: isFirstRow ? '8px' : 0,
      borderTopRightRadius: isFirstRow ? '8px' : 0,
      borderBottomLeftRadius: isLastRow ? '8px' : 0,
      borderBottomRightRadius: isLastRow ? '8px' : 0,
    }}>
      <div className="has-text-weight-bold is-flex is-align-items-center is-flex-direction-row is-justify-content-space-between">
        {faq.question}
        {!expanded && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
          <path fill="#8C9296" fillRule="evenodd" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" />
        </svg>}
        {expanded && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
          <rect width="14" height="2" x="5" y="11" fill="#8C9296" fillRule="evenodd" />
        </svg>}
      </div>
      {expanded && <div style={{ fontSize: '14px', marginTop: '8px' }}>{faq.answer}</div>}
    </div>
  </>
}

export default FAQ