import React from "react"
import ErrorContent from "../../../../ShareDocuments/ErrorContent"

const ShareResult = ({
  response,
  responseErrors,
}) => {

  if (responseErrors && Object.keys(responseErrors).length) {
    return <ErrorContent title="Request Error" content={<>
      <p>
        Something went wrong, please try again.
        <br />
        <br />
        If the problem persists, please <a href="mailto:support@pima.app">contact support</a>.
      </p>
    </>} />
  }

  return (
    <div className="has-text-centered">
      <div style={{ border: 'solid 4px #2bc9b4', borderRadius: '50%', padding: '9px', width: '72px', height: '72px', margin: 'auto' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
          <path fill="#2BC9B4" fillRule="evenodd" d="M18 32.34L9.66 24 6.82 26.82 18 38 42 14 39.18 11.18z" />
        </svg>
      </div>

      <h2 style={{ fontSize: '26px', margin: '1rem 0 0 0', fontWeight: 700 }}>Request Sent</h2>

      <p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '20px 10px', maxWidth: '400px', marginTop: '20px' }}>
        Next, check your email inbox and click the link to confirm your request.
      </p>
      <br />
      <p>If you don’t see the email, check your spam folder.</p>
    </div>
  )
}

export default ShareResult
