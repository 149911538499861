import React, { useEffect, useMemo, useState } from "react"

import ResourceRow from './ResourceRow'

const ContactList = ({
  contacts,
  onContactSelected = (contactIds) => { },
  onSignerSelected = (signer) => { },
  selectedContacts: initialSelectedContacts,
  setNewContact,
  signerRequired,
  signer: initialSigner
}) => {
  const [selectedContacts, setSelectedContacts] = useState(initialSelectedContacts)
  const [signer, setSigner] = useState(initialSigner)

  useEffect(() => {
    if (selectedContacts.indexOf(signer) === -1 && signer) {
      setSelectedContacts([...selectedContacts, signer])
    }
    onSignerSelected(signer)
  }, [signer])

  useEffect(() => {
    if (selectedContacts.indexOf(signer) === -1) {
      setSigner(null)
    }
    onContactSelected(selectedContacts)
    if (signerRequired && !signer && selectedContacts.length == 1) {
      setSigner(selectedContacts[0])
    }
  }, [selectedContacts])

  const contactList = useMemo(() => (
    contacts.map(contact => (
      <ResourceRow
        checked={selectedContacts.indexOf(contact.id) > -1}
        contactCanBeSigner={signerRequired}
        contactIsSigner={signer && signer.toString() == contact.id.toString()}
        key={`contact-selector-${contact.id}`}
        onResourceSelected={(contactId, selected) => {
          setSelectedContacts(selected ? [...selectedContacts, contactId] : selectedContacts.filter(id => contactId !== id))
        }}
        resource={contact}
        resourceType="contact"
        signerSelected={contactId => { setSigner(contactId) }} />
    ))
  ), [contacts, selectedContacts, signer])

  return <ul className="contacts">
    {contactList}
    <li key="newContactAction" style={{ padding: '20px 0px 0px 11px' }}>
      <a style={{ fontWeight: 'bold' }} onClick={() => setNewContact(true)}>
        <div className="columns">
          <div className="column fixed-55-width">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="#0075e0" fillRule="evenodd" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" />
            </svg>
          </div>
          <div className="column">Add New Contact</div>
        </div>
      </a>
    </li>
  </ul>
}

export default ContactList
