import React, { useMemo } from "react"

const Modal = ({
  cancelAction,
  confirmAction,
  confirmButtonText = 'Continue',
  deleteAction = false,
  deleteButtonText = 'Delete',
  disableBackgroundCancelAction = false,
  children: content,
  modalCardStyle,
  modalCardBodyStyle,
  modalCardClassName,
  modalCardHeaderClassName,
  modalCardFooterClassName,
  title,
  newTitle = false,
  cancelButton: initialCancelButton,
  confirmButton: initialConfirmButton,
  deleteButton: initialDeleteButton,
}) => {
  const cancel = (e) => {
    e.preventDefault()

    if (cancelAction) {
      cancelAction()
    }
  }

  const confirm = (e) => {
    e.preventDefault()

    if (confirmAction) {
      confirmAction()
    }
  }

  const deleteButton = useMemo(() => {
    if (initialDeleteButton) {
      return initialDeleteButton
    }

    if (deleteAction) {
      return <a className="button is-danger is-inverted"
        draggable="false"
        onClick={deleteAction}
        style={{ position: 'absolute', left: '40px' }}>
        {deleteButtonText}
      </a>
    }
  }, [deleteAction, initialDeleteButton])

  const confirmButton = useMemo(() => (
    initialConfirmButton || <a className="button is-info submit-form-on-enter-key-press" draggable="false" onClick={confirm}>{confirmButtonText}</a>
  ), [initialConfirmButton])

  const cancelButton = useMemo(() => (
    initialCancelButton || <a className="button is-white" draggable="false" onClick={cancel}>Cancel</a>
  ), [initialCancelButton])

  const headerDom = useMemo(() => {
    if (!newTitle) {
      return <header className={`modal-card-head noselect${modalCardHeaderClassName ? ` ${modalCardHeaderClassName}` : ''}`} id="draggable-modal-header">
        <p className="modal-card-title" style={{ marginBottom: 0 }}>{title}</p>
        {cancelAction && <a className="delete visit-link-on-escape-key-press" aria-label="close" draggable="false" onClick={cancel}>Cancel</a>}
      </header>
    } else {
      return <header className={`modal-card-head noselect${modalCardHeaderClassName ? ` ${modalCardHeaderClassName}` : ''}`} id="draggable-modal-header" style={{ borderBottom: 'none', paddingBottom: 0, paddingTop: '70px' }}>
        <p style={{ fontSize: '26px', fontWeight: 'bold', textAlign: 'center', paddingLeft: cancelAction ? '36px' : '0' }} className="modal-card-title">{title}</p>
        {cancelAction && <a style={{ backgroundColor: 'transparent', marginTop: '-100px', position: 'relative', marginRight: '10px' }} className="delete visit-link-on-escape-key-press" aria-label="close" draggable="false" onClick={cancel}>Cancel</a>}
      </header>
    }
  }, [cancel, cancelAction, modalCardHeaderClassName, title])

  return <div className="modal is-active">
    <a className="modal-background" onClick={(e) => !disableBackgroundCancelAction ? cancel(e) : e.preventDefault()}></a>
    <div className={`modal-card${modalCardClassName ? ` ${modalCardClassName}` : ''}`} style={modalCardStyle}>
      {headerDom}
      <section className="modal-card-body" style={modalCardBodyStyle}>
        {content}
      </section>
      <footer className={`modal-card-foot noselect${modalCardFooterClassName ? ` ${modalCardFooterClassName}` : ''}`} id="draggable-modal-footer">
        {deleteButton}
        {cancelButton}
        {confirmButton}
      </footer>
    </div>
  </div>
}

export default Modal
