import React, { useEffect, useState } from "react"
import { TailSpin } from "react-loader-spinner"

import utils from "../../utils/utils"

import Modal from "../../Modal"

const PackageDeleteForm = ({
  onCancel = () => { },
  packageData
}) => {
  const [deletionStatus, setDeletionStatus] = useState(false)

  useEffect(() => {
    const fetchCanDeletePackage = () => {
      fetch(`/packages/${packageData.slug}/destroy_status`, {
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': utils.csrfToken()
        }
      })
        .then(res => res.json())
        .then(status => setDeletionStatus(status))
    }
    fetchCanDeletePackage()
  }, [])

  const deletePackage = () => {
    fetch(`/packages/${packageData.slug}`, {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    })
      .then(() => {
        window.location = `/packages`
      })
  }

  return <Modal
    cancelButton={deletionStatus?.requests?.length !== 0 || deletionStatus?.trust_centers?.length !== 0}
    cancelAction={() => onCancel()}
    confirmButton={(deletionStatus?.requests?.length === 0 && deletionStatus?.trust_centers?.length === 0) ? <a
      className="button is-danger"
      draggable="false"
      onClick={() => deletePackage()}>
      Delete
    </a> : <a className="button is-info"
      draggable="false"
      onClick={() => onCancel()}>Close</a>
    }
    title="Delete Package"
  >
    <div className="columns">
      <div className="column">
        {!deletionStatus && <div className="is-flex is-align-items-center is-justify-content-center">
          <TailSpin color="#18242d" height={24} width={24} />
        </div>}
        {deletionStatus && deletionStatus.requests.length === 0 && deletionStatus.trust_centers.length === 0 && `Are you sure?`}
        {deletionStatus && deletionStatus.requests.length !== 0 && <>
          <p>{packageData.name} cannot be deleted because there are pending requests that include this package.</p>
          <br />
          <p>Please manage pending requests before archiving this package:</p>
          <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            {deletionStatus.requests.map(request => (
              <li><a href={request.url}>{request.companyName}</a></li>
            ))}
          </ul>
        </>}
        {deletionStatus && deletionStatus.trust_centers.length !== 0 && <>
          <p>{packageData.name} cannot be deleted because it is listed on your trust center.</p>
          <br />
          <p>Please remove the package from your trust center.</p>
        </>}
      </div>
    </div>
  </Modal >
}

export default PackageDeleteForm
