import React from "react"

import BaseToggleStep from "../BaseToggleStep"

const PackageStep = ({
  trustCenter,
  onPanelClose = () => { },
  onTrustCenterChange = () => { }
}) => {
  return <BaseToggleStep
    disabledSubtitle="No agreements or documents"
    labelAttr="name"
    onPanelClose={() => onPanelClose()}
    onTrustCenterChange={trustCenter => onTrustCenterChange(trustCenter)}
    paramSetUrl="/packages.json"
    trustCenter={trustCenter}
    trustCenterAttr="packages"
    trustCenterParam="package_ids"
    title="Packages" />
}

export default PackageStep
