import utils from '../utils/utils'

const validURL = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i') // fragment locator
  return !!pattern.test(str)
}

const saveValue = (
  fieldName,
  method,
  modelName,
  url,
  value
) => {
    const params = {}
    params[modelName] = {}
    params[modelName][fieldName] = value

    fetch(url, {
        method: method,
        body: JSON.stringify(params),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': utils.csrfToken()
        }
    })
}

export default {
  saveValue: saveValue,
  validURL: validURL
}