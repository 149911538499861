import React, { useMemo, useState } from "react"
import Moment from 'react-moment'
import 'moment-timezone'

import Agreement from './Agreement'
import Document from './Document'
import DetailsModalForm from './DetailsModalForm'

const Envelope = ({
  agreements,
  companyId,
  companyName,
  createdAt,
  currentCompany,
  currentUserId,
  emailDomain,
  envelopeType,
  documents,
  partnerId,
  partnerName,
  requester,
  requestSlug,
  slug,
  tosAccepted,
}) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

  const envelopeSigned = (agreements && agreements.length > 0) ? (agreements.find(agreement => agreement.signed === false) || []).length === 0 : true
  const isSharingDocument = currentCompany === companyId

  const [showDetailsPopup, setShowDetailsPopup] = useState(false)

  let agreementType
  let documentType
  if (envelopeType == 'approved') {
    agreementType = 'agreement'
    documentType = 'document'
  } else {
    agreementType = 'agreement_resource'
    documentType = 'document_resource'
  }

  const agreementList = useMemo(() => (
    agreements ? agreements.map((agreement) => {
      return <li key={`agreement-${agreement.slug}`}>
        <Agreement
          available={agreement.id}
          agreement={agreement}
          agreementIsInternal={envelopeType == 'approved' ? false : isSharingDocument}
          agreementIsToBeSigned={!isSharingDocument}
          agreementType={agreementType}
          companyName={companyName}
          currentUserId={currentUserId}
          emailDomain={emailDomain}
          partnerName={partnerName}
          requester={requester}
          requestSlug={requestSlug}
          tosAccepted={tosAccepted} />
      </li>
    }) : []
  ), [agreements])

  const documentList = useMemo(() => (
    documents ? documents.map((document) => {
      return <li key={`document-${document.slug}`}>
        <Document
          available={document.id && envelopeSigned}
          document={document}
          documentIsInternal={envelopeType == 'approved' ? false : isSharingDocument}
          documentType={documentType}
          requester={requester}
          requestSlug={requestSlug} />
      </li>
    }) : []
  ), [documents])

  const detailModal = useMemo(() => {
    if (showDetailsPopup) {
      const manuallyUploadedAgreement = agreements.length == 1 && documents.length == 0 && agreements[0].acted_by && !agreements[0].signed_by
      const users = []
      let agreementsWithPendingSignature = false

      agreements.forEach((agreement) => {
        if (!agreement.signed) {
          agreementsWithPendingSignature = true
        }

        const contactAlreadyInList = users.filter((user) => user.id == agreement.signed_by.id).length > 0

        if (!contactAlreadyInList) {
          users.push(manuallyUploadedAgreement ? agreement.acted_by : agreement.signed_by)
        }
      })

      documents.forEach((document) => {
        document.contacts.forEach((contact) => {
          const contactAlreadyInList = users.filter((user) => user.id == contact.id).length > 0

          if (!contactAlreadyInList) {
            users.push(contact)
          }
        })
      })

      const reminderType = agreementsWithPendingSignature ? 'pending_agreement_signature' : 'send_reminder'

      return <DetailsModalForm agreements={agreements}
        createdAt={createdAt}
        documents={documents}
        manuallyUploadedAgreement={manuallyUploadedAgreement}
        onCancel={() => setShowDetailsPopup(false)}
        onDone={() => setShowDetailsPopup(false)}
        partnerSlug={slug}
        reminderType={reminderType}
        timezone={tz}
        users={users} />
    }
  }, [showDetailsPopup])

  return (<div style={{ marginBottom: '25px' }}>
    <strong>
      <Moment format="MMMM DD, YYYY - hh:mm A zz" date={createdAt} tz={tz} />
      {!requester && isSharingDocument && <a href="" style={{ float: 'right' }} onClick={(e) => { e.preventDefault(); setShowDetailsPopup(true) }}>Details</a>}
    </strong>
    <div className="separator"></div>
    <ul>{agreementList}</ul>
    <ul>{documentList}</ul>
    {detailModal}
  </div>)
}

export default Envelope
