import React from "react"
import styles from "./style.module"

const DateFilter = ({
    onChange
}) => {
    return <div className={styles.selectWrapper}>
        <select onChange={(e) => onChange(e.target.value)}>
            <option value="week">Last Week</option>
            <option value="month">Last Month</option>
            <option value="quarter">Last 3 Months</option>
            <option value="year">Last 12 Months</option>
        </select>
    </div>
}

export default DateFilter