import React from "react"

import EmptyState from 'components/TrustCenters/TrustCenterEdit/EmptyState'
import { scrollTopInCookie } from 'components/TrustCenters/TrustCenterEdit/utils'

import styles from "./style.module"

const ContactUs = ({
  edit,
  trustCenter,
}) => {
  return <>
    {(trustCenter.contactEmail || trustCenter.supportEmail || edit) && <div className={styles.contactUs}>
      {edit && <a onClick={scrollTopInCookie} href={`/trust_centers/${trustCenter.slug}/edit?contactUs=1`} className="is-pulled-right has-text-weight-bold">Edit</a>}
      {!(trustCenter.contactEmail || trustCenter.supportEmail) && edit && <h2 className={styles.h2}>Contact Us</h2>}

      {trustCenter.contactEmail && <>
        <h2 className={styles.h2}>Support</h2>
        <div>If you need help with this Trust Center or need further information, please email <a href={`mailto:${trustCenter.contactEmail}`}>{trustCenter.contactEmail}</a></div>
        <br />
      </>}

      {trustCenter.supportEmail && <>
        <h2 className={styles.h2}>Make a Report</h2>
        <div>If you think you’ve discovered a vulnerability, please email <a href={`mailto:${trustCenter.supportEmail}`}>{trustCenter.supportEmail}</a></div>
      </>}

      {!(trustCenter.contactEmail || trustCenter.supportEmail) && edit && <EmptyState message="Set up contact and support email addresses." />}

    </div>}
    {!(trustCenter.contactEmail || trustCenter.supportEmail) && <div style={{ margin: '24px auto' }} />}
  </>
}

export default ContactUs
