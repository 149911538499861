import React, { useEffect, useMemo, useState } from "react"

import Label from "./Label"
import LabelForm from "./LabelForm"

import styles from "./style.module"

const LabelList = ({
  addAllOption,
  editMode,
  initialLabels,
  onLabelSelectionChange,
  selectedLabelIds,
  withBorderBottom
}) => {
  const [addNew, setAddNew] = useState(false)
  const [labels, setLabels] = useState(initialLabels || [])

  useEffect(() => {
    if (initialLabels) {
      return
    }

    fetch('/settings/labels.json')
      .then(res => res.json())
      .then(labels => {
        setLabels(addAllOption ? [{ id: 'all', name: 'All' }, ...labels] : labels)
      })
  }, [])

  const onLabelSelected = (label, selected) => {
    if (label.id == 'all') {
      if (selectedLabelIds.length == 0) {
        return
      }
      onLabelSelectionChange(['all'])
    } else {
      let options = selectedLabelIds.filter(labelId => labelId !== 'all')
      options = selected ? [...options, label.id] : options.filter(labelId => labelId !== label.id)
      if (options.length === 0) {
        options = addAllOption ? ['all'] : []
      }
      onLabelSelectionChange(options)
    }
  }

  const labelList = useMemo(() => (
    labels.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameB == 'ALL') {
        return 1
      }
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    }).map((label) => (
      <Label
        key={label.id}
        label={label}
        editMode={editMode}
        onDelete={deletedLabel => {
          setLabels(labels.filter(label => label.id !== deletedLabel.id))
        }}
        onSelect={(label, selected) => onLabelSelected(label, selected)}
        onUpdate={updatedLabel => {
          setLabels(labels.map(label => (
            label.id === updatedLabel.id ? { ...updatedLabel } : label
          )))
        }}
        selected={selectedLabelIds?.find(selectedLabelId => selectedLabelId === label.id)} />
    ))
  ), [labels, selectedLabelIds])

  return <>
    <div>{labelList}</div>
    <div className={`${styles.separator} ${withBorderBottom ? styles.withBorder : ''}`}>
      <br />
      {editMode && <>
        <a className="button is-primary" onClick={() => setAddNew(true)}>
          Add Label
        </a>
      </>}
    </div>
    {addNew && <LabelForm
      cancelAction={() => setAddNew(false)}
      onSave={(newLabel) => {
        setLabels([...labels, newLabel])
        setAddNew(false)
      }}
      title={"Add Label"}
    />}
  </>
}

export default LabelList