import React, { useEffect, useState } from "react"

const DownloadFileRedirect = ({
  downloadUrl,
  initialSeconds = 4,
  redirectPath
}) => {
  const [cancelled, setCancelled] = useState(false)
  const [seconds, setSeconds] = useState(initialSeconds)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSeconds(seconds - 1)
    }, 1000)

    return () => {
      clearInterval(timeout)
    }
  }, [])

  useEffect(() => {
    let timeout

    if (cancelled) {
      clearInterval(timeout)
      return
    }

    if (seconds == 0) {
      window.location = redirectPath
    } else {
      timeout = setTimeout(() => {
        setSeconds(seconds - 1)
      }, 1000)
    }
  }, [seconds, cancelled])

  return (
    <p>
      The document should have downloaded
      {!cancelled && <> automatically,</>}
      {cancelled && <> again,</>}
      {!cancelled && <> you will be redirected back in {seconds + 1}s...</>}
      {cancelled && <> click <a href={redirectPath}>here</a> to go back.</>}
      <br />
      <a href={downloadUrl} onClick={() => setCancelled(true)}>
        {!cancelled && <>Cancel redirect and download again</>}
        {cancelled && <>Download again</>}
      </a>
    </p>
  )
}

export default DownloadFileRedirect
