import React from "react"
import Markdown from 'react-markdown'

import EmptyState from 'components/TrustCenters/TrustCenterEdit/EmptyState'
import { scrollTopInCookie } from 'components/TrustCenters/TrustCenterEdit/utils'

import styles from "./style.module"

const Description = ({
  edit,
  trustCenter,
}) => {
  return <>
    {(trustCenter.description?.length != 0 || edit) && <div className={styles.Description}>
      {edit && <a onClick={scrollTopInCookie} href={`/trust_centers/${trustCenter.slug}/edit?overview=1`} className="is-pulled-right has-text-weight-bold">Edit</a>}
      <h2 className={styles.h2}>Overview</h2>
      {trustCenter.description?.length > 0 && <div className={`content ${styles.content}`}>
        <Markdown>{trustCenter.description}</Markdown>
      </div>}
      {edit && !(trustCenter.description?.length > 0) && <EmptyState message="Add a written overview of your company and security details." />}
    </div>}
  </>
}

export default Description
