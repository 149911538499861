import React, { useMemo, useState } from "react"
import { useForm } from 'react-hook-form'
import LoaderButton from 'components/shared/LoaderButton'

import ActionSelector from './ActionSelector'
import AllRowSelect from "../../ShareDocuments/AllRowSelect"
import LabelList from '../../LabelList'
import Modal from '../../Modal'
import ResourceRow from '../../ShareDocuments/ResourceRow'
import ShareResult from './ShareResult'
import utils from '../../utils/utils'

const ShareDocumentModalForm = ({
  agreementResources,
  availableAgreementResources = [],
  cancel,
  documentResources,
  onDone,
  onSave,
  packageData,
  planAllowsLabels,
}) => {
  // DEFAULT STATES

  const { register, handleSubmit, errors, formState, setValue, getValues } = useForm(formOptions)
  const [action, setAction] = useState('bind_document_with_agreement')
  const [error, setError] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [sentData, setSentData] = useState(false)
  const [step, setStep] = useState('actionSelect')
  const [selectedLabelIds, setSelectedLabelIds] = useState(['all'])

  // DEFAULT VARS

  let cancelButton
  let content
  let saveButton

  const formOptions = {}

  // FUNCTIONS

  const formIsValid = () => {
    if (action == 'send_agreement') {
      return hasSelectedAgreements()
    } else if (action == 'send_document') {
      return hasSelectedDocuments()
    } else {
      return hasSelectedAgreements() && hasSelectedDocuments()
    }
  }

  const shouldSubmitForm = () => {
    if (action == 'send_document') {
      return step == 'documents'
    } else {
      return step == 'agreements'
    }
  }

  const hasSelectedDocuments = () => {
    const values = getValues()
    return 'document_resource_ids' in values && values['document_resource_ids'].length > 0
  }

  const hasSelectedAgreements = () => {
    const values = getValues()
    return 'agreement_resource_ids' in values && values['agreement_resource_ids'].length > 0
  }

  const stepIsValid = () => {
    if (step == 'actionSelect') {
      return action !== ''
    }
    if (step == 'documents') {
      return hasSelectedDocuments()
    }
    if (step == 'agreements') {
      return hasSelectedAgreements()
    }
  }

  const hasDataToProgress = () => {
    if (step === 'actionSelect') {
      return documentResources.length > 0 || availableAgreementResources.length > 0
    }

    if (action == 'send_agreement') {
      return agreementResources.length > 0
    } else if (action == 'send_document') {
      return documentResources.length > 0
    } else {
      return documentResources.length > 0 && agreementResources.length > 0
    }
  }

  const checkboxSelected = (field) => {
    const values = getValues()
    const hasError = field in values && (values[field].length == 0 || !values[field])
    const error = hasError ? errorMessage() : ''
    setError(error)
  }

  const errorMessage = () => {
    return `Select at least one ${step.slice(0, -1)} to add to the envelope`
  }

  const popupTitle = () => {
    switch (step) {
      case 'actionSelect':
        if (documentList.length == 0 && availableAgreementResources.length == 0) {
          return 'No Files'
        }
        return 'Add Envelope'
      case 'documents':
        return 'Choose Documents'
      case 'agreements':
        return 'Choose Agreements'
      case 'done':
        return 'Added!'
      case 'error':
        return 'Error'
      default:
        return ''
    }
  }

  const popupContent = () => {
    switch (step) {
      case 'actionSelect':
        return <p>Choose what you'll add to the envelope:</p>
      case 'documents':
        return !planAllowsLabels ? <p>Next, choose documents to add to the envelope:</p> : null
      case 'agreements':
        return <p>Next, choose agreements to add to the envelope:</p>
      default:
        return false
    }
  }

  const nextStep = () => {
    if (action == 'send_agreement') {
      if (step == 'actionSelect') {
        return 'agreements'
      }
    }
    else if (action == 'send_document') {
      if (step == 'actionSelect') {
        return 'documents'
      }
      if (step == 'documents') {
        return 'done'
      }
    } else {
      if (step == 'actionSelect') {
        return 'documents'
      }
      if (step == 'documents') {
        return 'agreements'
      }
      if (step == 'agreements') {
        return 'done'
      }
    }
    if (step == 'done') {
      return
    }
  }

  const previousStep = () => {
    if (action == 'send_agreement') {
      if (step == 'agreements') {
        return 'actionSelect'
      }
    } else if (action == 'send_document') {
      if (step == 'documents') {
        return 'actionSelect'
      }
    } else {
      if (step == 'documents') {
        return 'actionSelect'
      }
      if (step == 'agreements') {
        return 'documents'
      }
    }
  }

  const stepForward = () => {
    if (stepIsValid()) {
      setStep(nextStep())
      setError(false)
    } else {
      setError(errorMessage())
    }
  }

  const shouldDisplayBackButton = () => {
    return step !== 'actionSelect' && step !== 'done' && step !== 'error'
  }

  const errorContent = (title, content) => {
    return <div className="column">
      <div className="has-text-centered" style={{ paddingTop: '80px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72">
          <path fill="#FADB47" d="M36,0 C55.882251,0 72,16.117749 72,36 C72,55.882251 55.882251,72 36,72 C16.117749,72 0,55.882251 0,36 C0,16.117749 16.117749,0 36,0 Z M36,4 C18.326888,4 4,18.326888 4,36 C4,53.673112 18.326888,68 36,68 C53.673112,68 68,53.673112 68,36 C68,18.326888 53.673112,4 36,4 Z M38,49 L38,53 L34,53 L34,49 L38,49 Z M38,19 L38,45 L34,45 L34,19 L38,19 Z" />
        </svg>
        <h2 style={{ fontSize: '26px', margin: '1rem 0 0 0' }}>{title}</h2>
        <p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '12px 16px', maxWidth: '500px', marginTop: '40px' }}>
          {content}
        </p>
      </div>
    </div>
  }

  const stepBack = () => {
    if (step == 'actionSelect') {
      return
    }

    resetStepSelection(step)
    setStep(previousStep())
    setError(false)
  }

  const resetStepSelection = (step) => {
    if (step == 'documents') {
      setValue('document_resource_ids', [])
    }
    if (step == 'agreements') {
      setValue('agreement_resource_ids', [])
    }
  }

  const fieldsBasedOnCurrentAction = () => {
    switch (action) {
      case 'send_agreement':
        return ["agreement_resource_ids"]
      case 'send_document':
        return ["document_resource_ids"]
      case 'bind_document_with_agreement':
        return ["agreement_resource_ids", "document_resource_ids"]
      default:
        return []
    }
  }

  const shareDocuments = (data) => {
    setIsSending(true)

    if (!formIsValid()) {
      setIsSending(false)
      return
    }

    const values = getValues()
    const fields = fieldsBasedOnCurrentAction()

    for (var i = 0; i < fields.length; i++) {
      const field = fields[i]
      if (field in values && (values[field].length == 0 || !values[field])) {
        setError(errorMessage())
        return
      }
      // force array values
      data[field] = Array.isArray(data[field]) ? data[field] : [data[field]]
    }

    fetch(`/packages/${packageData.slug}/add_envelope`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }).then(res => {
      onSave()
      setIsSending(false)
      setSentData(data)
      setStep('done')
    }).catch(err => {
      setIsSending(false)
      setStep('error')
    })
  }

  // LOGIC BEGINS

  // build document list

  const documentList = useMemo(() => (
    documentResources.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    }).map((documentResource, index) => {
      if (selectedLabelIds[0] !== 'all') {
        if (documentResource.label_ids.filter(label_id => selectedLabelIds.indexOf(label_id) > -1).length === 0) {
          return
        }
      }

      return <ResourceRow
        key={`document-resource-${index}`}
        resource={documentResource}
        resourceType="document"
        name="document_resource_ids"
        onResourceSelected={(documentResourceId) => checkboxSelected('document_resource_ids')}
        reference={register()} />
    })
  ), [documentResources, step, selectedLabelIds])

  const agreementList = useMemo(() => (
    agreementResources.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    }).map((agreementResource, index) => {
      return <ResourceRow
        key={`agreement-resource-${index}`}
        resource={agreementResource}
        resourceType="agreement"
        name="agreement_resource_ids"
        onResourceSelected={(agreementResourceId) => checkboxSelected('agreement_resource_ids')}
        reference={register()} />
    })
  ), [agreementResources, step])


  const availableAgreementList = useMemo(() => {
    return availableAgreementResources.map((agreementResource, index) => {
      return <ResourceRow
        key={`available-agreement-resource-${index}`}
        resource={agreementResource}
        resourceType="agreement"
        name="agreement_resource_ids"
        onResourceSelected={(agreementResourceId) => checkboxSelected('agreement_resource_ids')}
        reference={register()} />
    })
  }, [availableAgreementResources, step])

  const shareResult = useMemo(() => {
    if (!sentData) {
      return
    }

    return <div style={{ visibility: step == 'done' ? 'visible' : 'hidden', position: step == 'done' ? 'relative' : 'absolute', top: step == 'done' ? 'auto' : '-1000px' }}>
      <ShareResult
        agreements={agreementResources.filter((agreementResource) => (sentData['agreement_resource_ids'] && sentData['agreement_resource_ids'].indexOf(agreementResource.id.toString()) > -1))}
        documents={documentResources.filter((documentResource) => (sentData['document_resource_ids'] && sentData['document_resource_ids'].indexOf(documentResource.id.toString()) > -1))}
        packageData={packageData} />
    </div>
  }, [sentData, step])

  const documentListDom = useMemo(() => {
    return <ul
      className="documents"
      style={{ visibility: step == 'documents' ? 'visible' : 'hidden', position: step == 'documents' ? 'relative' : 'absolute', top: step == 'documents' ? 'auto' : '-1000px' }}>
      {documentList.length > 0 && <AllRowSelect
        checked={(getValues()['document_resource_ids'] ? (Array.isArray(getValues()['document_resource_ids']) ? getValues()['document_resource_ids'] : [getValues()['document_resource_ids']]) : []).length === documentResources.map(d => d.id.toString()).length && (getValues()['document_resource_ids'] ? (Array.isArray(getValues()['document_resource_ids']) ? getValues()['document_resource_ids'] : [getValues()['document_resource_ids']]) : []).every((value, index) => value === documentResources.map(d => d.id.toString())[index])}
        indeterminate={(getValues()['document_resource_ids'] || []).length > 0}
        onChange={() => { checkboxSelected('document_resource_ids') }}
        resourceList={documentList}
        resourceType="document" />}
      {documentList}
    </ul>
  }, [step, documentList])

  const agreementListDom = useMemo(() => {
    return <ul className="agreements"
      style={{ visibility: step == 'agreements' ? 'visible' : 'hidden', position: step == 'agreements' ? 'relative' : 'absolute', top: step == 'agreements' ? 'auto' : '-1000px' }}>
      {(action == 'send_agreement') ? availableAgreementList : agreementList}
    </ul>
  }, [action, step, availableAgreementList, agreementList])

  const actionSelect = useMemo(() => {
    if (!hasDataToProgress()) {
      return
    }

    return <ActionSelector
      availableAgreementResources={availableAgreementResources}
      agreementResources={agreementResources}
      documentResources={documentResources}
      key="actionSelector"
      innerStyle={{ visibility: step == 'actionSelect' ? 'visible' : 'hidden', position: step == 'actionSelect' ? 'relative' : 'absolute', top: step == 'actionSelect' ? 'auto' : '-1000px' }}
      onChange={(action) => setAction(action)} />
  }, [step])

  if (shouldSubmitForm()) {
    saveButton = isSending ? <LoaderButton width="140px" /> : <input className={`button is-info ${formIsValid() ? '' : 'disabled'}`}
      draggable="false"
      type="submit"
      style={{ width: '140px' }}
      value="Add Envelope" />
  } else {
    if (documentResources.length > 0 || agreementResources.length > 0) {
      saveButton = <a className={`button is-info ${stepIsValid() ? '' : 'disabled'}`}
        draggable="false"
        onClick={() => stepForward()}>Next</a>
    } else {
      saveButton = false
    }
  }

  if (step === 'done') {
    cancelButton = true
    saveButton = <a className="button is-info"
      draggable="false"
      onClick={() => onDone()}>Done</a>
  } else if (step === 'error') {
    cancelButton = true
    saveButton = <a className="button is-info"
      draggable="false"
      onClick={() => onDone()}>Close</a>
    content = errorContent('Could not send this envelope', 'Please try again later...')
  } else if (hasDataToProgress()) {
    cancelButton = <a className="button is-white" onClick={cancel}>Cancel</a>
    content = popupContent()
  } else if (documentList.length == 0 && availableAgreementResources.length == 0) {
    cancelButton = true
    saveButton = <a className="button" onClick={cancel}>Close</a>
    content = errorContent('Add Documents or Agreements', `Add at least one Document or Agreement to contribute to this package.`)
  }

  const errorElement = useMemo(() => {
    if (!error) {
      return
    }
    return <label className="error">{error}</label>
  }, [error])

  const backButton = shouldDisplayBackButton() ? <a className="button"
    draggable="false"
    onClick={() => stepBack()}
    style={{ paddingLeft: '12px', position: 'absolute', left: '40px' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g fill="none" fillRule="evenodd">
        <path fill="#F4F5F5" d="M-633-21H807v1470H-633z" />
        <path fill="#FFF" d="M-32-21h839v60H-32z" />
        <g transform="translate(-12 -9)">
          <rect width="89" height="35" x=".5" y=".5" stroke="#CED0D2" rx="6" />
          <path fill="#424C53" d="M27 17.25h-9.128l4.193-4.192L21 12l-6 6 6 6 1.057-1.057-4.185-4.193H27z" />
        </g>
      </g>
    </svg>
    <span style={{ paddingLeft: '10px' }}>Back</span>
  </a> : null

  const labelList = useMemo(() => {
    if (!planAllowsLabels || step !== 'documents') {
      return
    }

    return <LabelList
      addAllOption={true}
      onLabelSelectionChange={labels => setSelectedLabelIds(labels)}
      selectedLabelIds={selectedLabelIds}
      withBorderBottom={true} />
  }, [planAllowsLabels, step, selectedLabelIds])

  return <form onSubmit={handleSubmit(shareDocuments)}>
    <Modal
      cancelAction={step === 'done' ? onDone : cancel}
      cancelButton={cancelButton}
      confirmButton={saveButton}
      deleteButton={backButton}
      title={popupTitle()}
      modalCardStyle={{ height: '600px', minWidth: '750px', overflowX: 'hidden' }}
      modalCardBodyStyle={{ padding: 0, overflowX: 'hidden' }}>
      <div className="content">
        <div className="columns">
          <div className="column document-list">
            {content}
            {actionSelect}
            {labelList}
            {documentListDom}
            {agreementListDom}
            {shareResult}
            {errorElement && step !== 'documents' && <p style={{ color: '#d92022', fontSize: '14px' }}>{errorElement}</p>}
          </div>
        </div>
      </div>
    </Modal>
  </form>
}

export default ShareDocumentModalForm
