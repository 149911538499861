import React from "react"

const CompanyListTips = () => {
  return <div className="column is-main-content">
    <div className="content is-title-block"></div>
    <div className="content has-text-centered">
      <div className="protip-wrapper">
        <div className="protip-content">
          This is where requests that have been accepted are stored.
          You can share additional documents with companies with whom you have an existing relationship.
        </div>
      </div>
    </div>
  </div>
}

export default CompanyListTips
