import React, { useState } from "react"
import ReactDOM from 'react-dom'

import DeleteEnvelopeModal from './DeleteEnvelopeModal'
import DetailsRow from './DetailsRow'
import Modal from "./../Modal"
import Moment from 'react-moment'
import 'moment-timezone'

const DetailsModalForm = (props) => {
  const agreements = props.agreements
  const createdAt = props.createdAt
  const documents = props.documents
  const manuallyUploadedAgreement = props.manuallyUploadedAgreement
  const onDone = props.onDone
  const onCancel = props.onCancel
  const partnerSlug = props.partnerSlug
  const reminderType = props.reminderType
  const timezone = props.timezone
  const users = props.users

  const [showDeleteEnvelopeModal, setShowDeleteEnvelopeModal] = useState(false)

  const envelopeIsCancellable = (agreements, documents) => {
    if (agreements.length == 0) {
      return false
    }

    return agreements.filter(agreement => agreement.signed).length == 0
  }

  const userList = !manuallyUploadedAgreement ? users.map((user, index) => <DetailsRow key={index} partnerSlug={partnerSlug} reminderType={reminderType} user={user} />) : null

  const userContent = manuallyUploadedAgreement ? `${users[0].first_name} ${users[0].last_name}` : `${users.length} ${users.length == 1 ? 'person' : 'people'}`
  const content = <p>Envelope was {manuallyUploadedAgreement ? 'created by' : 'sent to'} {userContent} on <Moment format="MMMM DD, YYYY" date={createdAt} tz={timezone} /></p>

  const confirmButton = <a className="button submit-form-on-enter-key-press" draggable="false" onClick={onDone}>Close</a>

  const deleteAction = envelopeIsCancellable(agreements, documents) ? () => setShowDeleteEnvelopeModal(true) : null

  const detailModal = ReactDOM.createPortal(
    <Modal cancelAction={onDone}
      cancelButton={true}
      confirmButton={confirmButton}
      deleteAction={deleteAction}
      deleteButtonText="Delete Envelope"
      title="Envelope Details" >
      <div className="columns">
        <div className="column">
          {content}
          <ul style={{ margin: '30px 0' }}>{userList}</ul>
        </div>
      </div>
    </Modal>,
    document.getElementById('modal-container'))

  let deleteEnvelopeModal

  if (showDeleteEnvelopeModal) {
    deleteEnvelopeModal = ReactDOM.createPortal(
      <DeleteEnvelopeModal agreements={agreements}
        documents={documents}
        onCancel={() => setShowDeleteEnvelopeModal(false)}
        onDone={onDone}
        partnerSlug={partnerSlug} />,
      document.getElementById('modal-container'))
  }

  return showDeleteEnvelopeModal ? deleteEnvelopeModal : detailModal
}

export default DetailsModalForm
