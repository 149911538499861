import React from "react"

import checkMark from './check.png'
import point from './point-left-emoji.png'

const CheckListEntry = ({
  checked,
  link,
  next = false,
  position,
  title,
}) => {
  const entryContent = <>
    <div style={{
      backgroundColor: 'white',
      borderRadius: '50%',
      color: '#424C53',
      fontSize: '10px',
      fontWeight: 'bold',
      height: '20px',
      marginRight: '10px',
      paddingLeft: '7px',
      paddingTop: '3px',
      width: '20px'
    }}>{position}</div>
    <div style={{ minWidth: '130px' }}>
      {!checked && <a href={link} style={{ color: '#424C53', textDecoration: next ? 'underline' : 'normal' }}>{title}</a>}
      {checked && title}
    </div>
    {(next || checked) && <img
      alt={next ? 'action' : 'checked'}
      src={next ? point : checkMark}
      style={{ height: '20px', width: '20px' }}
    />}
  </>

  return <li
    className="is-flex is-justify-content-flex-start"
    style={{
      margin: '10px 0px 2px 0px',
      fontSize: '14px',
      fontWeight: 600
    }}>
    {entryContent}
  </li >
}

export default CheckListEntry
