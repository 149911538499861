import React from "react"

import GetStartedForFree from './GetStartedForFree'
import LoginShell from './LoginShell'
import LogoCloud from './LogoCloud'
import SignInWithGoogle from './SignInWithGoogle'

const Login = ({ error, signup }) => {

  if (error) {
    switch (error) {
      case 'disabled':
        error = 'Your account has been disabled. Contact your administrator for further information.'
        break
      case 'pending':
        error = <>
          Thank you! An administrator has been notified.
          <br />
          You will be able to sign in as soon as they approve your request to join the organization.
        </>
        break
      case 'gmail':
        error = <>
          To use Pima, you must sign in with a G Suite account, <span style={{ fontFamily: 'monospace' }}>@gmail.com</span> are not supported. Please sign in again. If you need help, please reach out to <a href="mailto:support@pima.app">support@pima.app</a>
        </>
        break
      case 'okta_not_found':
        error = <>
          To enable Okta, you must sign in with a G Suite account and set up Okta.<br />More info at <a href="https://support.pima.app/okta-integration" target="_blank">https://support.pima.app/okta-integration</a>. If you need help, please reach out to <a href="mailto:support@pima.app">support@pima.app</a>
        </>
        break
      case 'okta_plan_support':
        error = <>
          Your plan does not support Okta. Please update to a plan including Okta intergration.<br />More info at <a href="https://support.pima.app/okta-integration" target="_blank">https://support.pima.app/okta-integration</a>. If you need help, please reach out to <a href="mailto:support@pima.app">support@pima.app</a>
        </>
        break
      case 'error':
        error = 'Sorry, something went wrong. Please try again'
        break
      case 'google_auth':
        error = 'Authenticating through Google has been deactivated for your company. Please contact your administrators for more information.'
        break
    }
  }

  return (
    <LoginShell>
      <p className="has-text-centered">Use Google to create or access<br />your Pima account.</p>

      {error && <p
        className="has-text-centered"
        style={{
          backgroundColor: '#f4f5f5',
          borderRadius: '16px',
          margin: 'auto',
          marginTop: '30px',
          padding: '20px 60px',
          width: '500px'
        }}>
        {error}
      </p>}

      <SignInWithGoogle />

      {signup && <>
        <GetStartedForFree />
        <br />
        <LogoCloud />
      </>}
    </LoginShell>
  )
}

export default Login
