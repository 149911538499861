import React from "react"

import styles from "./style.module"

const Subprocessor = ({
  subprocessor,
}) => {
  return <tr className={styles.Subprocessor}>
    <td>
      {subprocessor.name}
    </td>
    <td>
      {subprocessor.description}
    </td>
    <td>
      <div className="is-flex">
        <a href={subprocessor.url} target="_blank">{subprocessor.url}</a>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ height: '18px', marginTop: '3px' }}>
          <path fill="#0075E0" fillRule="evenodd" d="M19,19 L5,19 L5,5 L12,5 L12,3 L5,3 C3.89,3 3,3.9 3,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,12 L19,12 L19,19 Z M14,3 L14,5 L17.59,5 L7.76,14.83 L9.17,16.24 L19,6.41 L19,10 L21,10 L21,3 L14,3 Z" />
        </svg>
      </div>
    </td>
  </tr>
}

export default Subprocessor
