import React from "react"

import EmptyState from 'components/TrustCenters/TrustCenterEdit/EmptyState'

import FAQ from "./FAQ"

import styles from "./style.module"

const FAQs = ({
  edit,
  trustCenter,
}) => {
  return <>
    {(trustCenter.faqs.length > 0 || edit) && <div className={styles.faqs}>
      {edit && <a href={`/trust_centers/${trustCenter.slug}/trust_center_faqs`} className="is-pulled-right has-text-weight-bold">Edit</a>}

      <h2 className={styles.h2}>FAQs</h2>

      {trustCenter.faqs.length > 0 && <>
        {trustCenter.faqs.map((faq, index) => <FAQ key={faq.slug} faq={faq} isLastRow={trustCenter.faqs.length === index + 1} isFirstRow={index === 0} />)}
      </>}

      {trustCenter.faqs.length == 0 && <EmptyState message="Add FAQs that will display on your trust page." />}
    </div>}
  </>
}

export default FAQs
