import React, { useState } from "react"
import Modal from './Modal'

function ContactAlreadyExist(props) {
  const archived = props.archived || false
  const company = props.company
  const contact = props.contact

  const [showModal, setShowModal] = useState(1)

  const closeModal = () => {
    if (!archived && contact) {
      Turbolinks.visit(window.location.pathname.toString())
    } else {
      setShowModal(0)
    }
  }

  let modal

  if (showModal) {
    modal = <Modal
      cancelAction={closeModal}
      cancelButton={true}
      confirmAction={closeModal}
      confirmButtonText="View Company"
      title={archived ? 'Archived Company' : 'Contact Exists'}>
      <div className="content">
        <div className="columns">
          <div className="column document-list">
            {archived && <p>This company exists but has previously been archived. To add this contact you must first restore the company.</p>}
            {(!archived && contact) && <p>{contact.first_name} {contact.last_name} is already a contact for the company {company.name}</p>}
          </div>
        </div>
      </div>
    </Modal>
  }

  return (<div>{modal}</div>)
}

export default ContactAlreadyExist
