import React, { useState } from "react"
import ReactDOM from 'react-dom'
import { useForm } from 'react-hook-form'

import utils from '../../utils/utils'

import Modal from "../../Modal"
import Radio from "../../inputs/Radio"

const RequestCenterCompanySlugModalForm = ({
	baseUrl,
	companySlug,
	defaultRequestCenter,
	onCancel,
	slugs
}) => {
	const [showForm, setShowForm] = useState(false)
	const [requestCenterType, setRequestCenterType] = useState(defaultRequestCenter.request_center_type)
	const [slug, setSlug] = useState(companySlug)
	const [step, setStep] = useState('form')

	const { register, handleSubmit, errors, formState, getValues } = useForm({ defaultValues: { slug: slug }, mode: "onChange" })

	const updateCompanySlug = () => {
		const values = getValues()

		if (!values.slug) {
			return
		}

		fetch(`/settings/companies/${companySlug}`, {
			body: JSON.stringify({ company: { slug: slug } }),
			method: 'PATCH',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-Token': utils.csrfToken()
			}
		})
			.then(() => {
				fetch(`/request_centers/${defaultRequestCenter.id}`, {
					body: JSON.stringify({ request_center: { request_center_type: requestCenterType } }),
					method: 'PATCH',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'X-CSRF-Token': utils.csrfToken()
					}
				})
					.then(() => setStep('done'))
			})
	}

	const refreshCurrentPage = () => {
		Turbolinks.visit(window.location.toString(), { action: 'replace' })
	}

	const cancelAction = () => {
		if (step == 'done') {
			refreshCurrentPage()
			return
		}

		onCancel()
	}

	const doneContent = step == 'done' ? <div className="content">
		<div className="columns">
			<div className="column document-list">
				<div className="has-text-centered" style={{ paddingTop: '80px' }}>
					<div style={{ border: 'solid 4px #2bc9b4', borderRadius: '50%', padding: '9px', width: '72px', height: '72px', margin: 'auto' }}>
						<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
							<path fill="#2BC9B4" fillRule="evenodd" d="M18 32.34L9.66 24 6.82 26.82 18 38 42 14 39.18 11.18z" />
						</svg>
					</div>
					<h2 style={{ fontSize: '26px', margin: '1rem 0 0 0' }}>Request Center Updated</h2>
					<p>You can now add files to this request center.</p>
					<p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '12px 16px', maxWidth: '500px', marginTop: '40px' }}>
						{baseUrl}/p/{slug}
					</p>
				</div>
			</div>
		</div>
	</div> : null

	const confirmUpdate = () => {
		const values = getValues()

		if (!values.slug) {
			return
		}

		setSlug(values.slug)
		setStep('confirm')
	}

	const confirmButton = () => {
		if (step == 'done') {
			return <a className="button" onClick={cancelAction}>Done</a>
		}

		if (step == 'confirm') {
			return <a className="button is-info	" onClick={updateCompanySlug}>Save</a>
		}

		return <input className={`button is-info ${formState.isValid ? '' : 'disabled'}`} draggable="false" type="submit" value="Next" />
	}

	const error = errors['slug'] && errors['slug'].type == 'requestCenterNameTaken' ? <span className="error">Name already taken</span> : null

	const modalForm = showForm ? ReactDOM.createPortal(
		<form onSubmit={handleSubmit(confirmUpdate)}>
			<Modal cancelAction={() => setShowForm(false)}
				cancelButton={step == 'done'}
				confirmButton={confirmButton()}
				modalCardStyle={{ minWidth: '750px' }}
				title="Edit Request Center">
				{step == 'form' && <>
					<div className="columns">
						<div className="column">
							<p>This is the default request center. It can not be deleted.</p>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="field">
								<div className="control">
									<label className="label" htmlFor="companySlug">Access URL:</label>
									<span className={`input ${errors['slug'] ? 'is-danger' : ''}`} style={{ width: 'auto', borderRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: '4px', borderTopLeftRadius: '4px', color: '#424c53', borderLeft: 'solid 1px #8c9296', borderTop: 'solid 1px #8c9296', borderBottom: 'solid 1px #8c9296', padding: '8px 0 8px 8px' }}>{baseUrl}/p/</span>
									<input
										autoComplete="off"
										style={{ width: '336px', borderLeft: 'none', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, paddingLeft: 0 }}
										placeholder="E.g., sales"
										className={`input ${errors['slug'] ? 'is-danger' : ''}`}
										ref={register({
											required: true,
											validate: {
												requestCenterNameTaken: value => (slugs.indexOf(value) === -1)
											}
										})}
										type="text"
										name="slug"
										id="companySlug" />
									{error}
								</div>
							</div>
						</div>
					</div>
				</>}
				{step == 'confirm' && <p>Renaming this request center changes all access URLs and breaks any integrations.</p>}
				{doneContent}
			</Modal>
		</form>,
		document.getElementById('modal-container')
	) : null

	return <>
		<a onClick={() => setShowForm(true)}>Edit</a>
		{modalForm}
	</>
}

export default RequestCenterCompanySlugModalForm
