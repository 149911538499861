import React, { useState, useEffect } from "react"
import ReactDOM from 'react-dom'

import utils from '../utils/utils'

import CheckListEntry from './CheckListEntry'
import Onboarding from './Onboarding'

const CheckList = ({
  bottom,
  agreementCount,
  documentCount,
  logoAttached,
  packageCount,
}) => {
  const [hideCheckList, setHideCheckList] = useState(false)
  const [showOnboardingModal, setShowOnboardingModal] = useState(false)

  const markCheckListAsCompleted = () => {
    fetch('/settings/companies/mark_check_list_as_completed', {
      body: JSON.stringify({ check_list_completed: true }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      },
      method: 'POST'
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          setHideCheckList(true)
          document.querySelector('.button.is-navigation.is-account.sticky-to-bottom').style['bottom'] = '5px'
        }
      })
  }

  let docCountChecked = false
  let docCountNext = false
  let agreementCountChecked = false
  let agreementCountNext = false
  let logoAttachedChecked = false
  let logoAttachedNext = false
  let packageCountChecked = false
  let packageCountNext = false

  if (documentCount > 0) {
    docCountChecked = true
    if (agreementCount > 0) {
      agreementCountChecked = true
      if (logoAttached) {
        logoAttachedChecked = true
        if (packageCount > 0) {
          packageCountChecked = true
        } else {
          packageCountNext = true
        }
      } else {
        logoAttachedNext = true
      }
    } else {
      agreementCountNext = true
    }
  } else {
    docCountNext = true
  }

  const allChecked = docCountChecked && agreementCountChecked && logoAttachedChecked && packageCountChecked

  useEffect(() => {
    document.querySelector('.navigation-container').style['bottom'] = allChecked ? '294px' : '374px'
    document.querySelector('.account-nav-background').style['bottom'] = allChecked ? '210px' : '280px'
    document.querySelector('.button.is-navigation.is-account.sticky-to-bottom').style['bottom'] = allChecked ? '220px' : '290px'
  }, [])

  const checkList = <div style={{
    color: '#424C53',
    padding: '16px 24px',
    backgroundColor: '#E9F5FF',
    bottom: bottom,
    position: 'absolute',
    width: '100%'
  }}>
    <h3 style={{
      color: '#0075E0',
      margin: '0 1px 16px 0',
      fontSize: '16px',
      fontWeight: 'bold'
    }}>
      {allChecked ? 'Great work! You\'re set.' : 'Get Started Fast'}
    </h3>
    {allChecked && <p style={{ fontSize: '14px' }}> Want to learn more? View the <a onClick={() => setShowOnboardingModal(true)} style={{ color: '#424C53', textDecoration: 'underline' }}>Onboarding</a> again or dive into Pima’s extensive <a style={{ color: '#424C53', textDecoration: 'underline' }} href="https://support.pima.app/" target="_blank">FAQ</a>.</p>}
    {!allChecked && <ul>
      <CheckListEntry checked={docCountChecked} next={docCountNext} link="/documents/new" position={1} title="Upload document" />
      <CheckListEntry checked={agreementCountChecked} next={agreementCountNext} link="/agreements/new" position={2} title="Upload agreement" />
      <CheckListEntry checked={logoAttachedChecked} next={logoAttachedNext} link="/settings/companies/pima" position={3} title="Add company logo" />
      <CheckListEntry checked={packageCountChecked} next={packageCountNext} link="/packages?action=create" position={4} title="Create package" />
    </ul>}
    <a style={{
      backgroundColor: '#D9EBFB',
      borderRadius: '8px',
      color: '#0075E0',
      display: 'block',
      fontWeight: 'bold',
      fontSize: '12px',
      margin: '15px 0',
      padding: '4px 8px',
      textAlign: 'center'
    }}
      href="https://www.pima.app/pricing"
      target="_blank"
    >Your first 10 shares are free!</a>
    {!allChecked && <p style={{ fontSize: '14px', fontWeight: 600 }}>
      Want to learn more?
      <br />
      <a onClick={() => setShowOnboardingModal(true)} style={{
        color: '#424C53',
        textDecoration: 'underline'
      }}>Onboarding</a> &#183; <a style={{
        color: '#424C53',
        textDecoration: 'underline'
      }} href="https://support.pima.app/" target="_blank">
        FAQ
      </a>
    </p>}
    {allChecked && <p style={{ fontSize: '14px', fontWeight: 600 }}>
      <a onClick={() => markCheckListAsCompleted()} style={{
        color: '#424C53',
        textDecoration: 'underline'
      }}>Dismiss</a>
    </p>}
  </div>

  const onboardingModal = showOnboardingModal ? ReactDOM.createPortal(
    <Onboarding cancelAction={() => setShowOnboardingModal(false)} />,
    document.getElementById('modal-container')
  ) : null

  return <>
    {!hideCheckList && checkList}
    {onboardingModal}
  </>
}

export default CheckList
