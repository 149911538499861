import React, {
  useEffect,
  useState
} from "react"
import ReactDOM from 'react-dom'
import UserPlanLimitReachModal from '../UserPlanLimitReachModal'

import styles from './style.module'

import utils from '../../utils/utils'

const PlanUpdateBanner = ({
  isAdmin,
  admins,
  adminEmails,
  notifications,
  renewalDate
}) => {
  const [content, setContent] = useState(null)
  const [dismissed, setDismissed] = useState(false)
  const [popupContent, setPopupContent] = useState(null)
  const [notification, setNotification] = useState(notifications[0])
  const [popupLogo, setPopupLogo] = useState(null)

  const getNextNotification = () => {
    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i].id == notification.id) {
        if (notifications[i + 1]) {
          return notifications[i + 1]
        }
      }
    }
    return false
  }

  const animateDismissButton = () => {
    const dismissNotificationButton = document.querySelector("a.dismissNotification")
    dismissNotificationButton.style.opacity = 0
    setTimeout(() => dismissNotificationButton.style.opacity = 1, 1000)
  }

  const dismissNotification = () => {
    fetch(`/mark_plan_notification_as_dismissed/${notification.id}`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    })

    const nextNotification = getNextNotification()
    if (nextNotification) {
      animateDismissButton()
      setNotification(nextNotification)
    } else {
      setDismissed(true)
    }
  }

  useEffect(() => {
    const className = "with-banner"
    const classList = document.getElementsByTagName("body")[0].classList
    if (dismissed) {
      classList.remove(className)
    } else {
      classList.add(className)
    }
  }, [dismissed])

  useEffect(() => {
    if (notification) {
      switch (notification.notification_type) {
        case 'share_count':
          if (window.storedNotificationContent) {
            setContent(
              <span>
                {window.storedNotificationContent}
                {isAdmin && <> <a href="/settings/billing?prompt_modal=1">Update Plan</a></>}
                {!isAdmin && <> <a onClick={() => setShowUserPlanLimitReachModal(true)}>Update Plan</a></>}
              </span >
            )
          }
          fetch('/settings/billing/plan_data')
            .then((response) => response.json())
            .then((planData) => {
              const percentage = Math.round(planData.usage.share_count / planData.currentPlan.share_count * 100)
              setPopupContent(<>Your team has reached ${percentage}% of it's share limit.<br />Contact your team admins to upgrade.</>)
              const notificationString = `You've used ${percentage}% of shares included in the ${planData.currentPlan.name} plan. Your shares renew on ${renewalDate}.`
              window.storedNotificationContent = notificationString
              setPopupLogo(null)
              setContent(
                <span>
                  {notificationString}
                  {isAdmin && <> <a href="/settings/billing?prompt_modal=1">Update Plan</a></>}
                  {!isAdmin && <> <a onClick={() => setShowUserPlanLimitReachModal(true)}>Update Plan</a></>}
                </span >
              )
            })
          break
        case 'credit_card':
          setPopupContent(<>Your renews on {renewalDate}. Contact admins to update your credit card details.</>)
          setPopupLogo(
            <svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1">
              <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Modal-Graphics-/-Credit-Card" fill="#0075E0">
                  <path d="M36,0 C55.882251,0 72,16.117749 72,36 C72,55.882251 55.882251,72 36,72 C16.117749,72 0,55.882251 0,36 C0,16.117749 16.117749,0 36,0 Z M36,4 C18.326888,4 4,18.326888 4,36 C4,53.673112 18.326888,68 36,68 C53.673112,68 68,53.673112 68,36 C68,18.326888 53.673112,4 36,4 Z" id="Shape" fill-rule="nonzero"></path>
                  <path d="M49.6,24 L22.4,24 C20.513,24 19.017,25.335 19.017,27 L19,45 C19,46.665 20.513,48 22.4,48 L49.6,48 C51.487,48 53,46.665 53,45 L53,27 C53,25.335 51.487,24 49.6,24 Z M50,45 L22,45 L22,34 L50,34 L50,45 Z M50,31 L22,31 L22,27 L50,27 L50,31 Z" id="Shape"></path>
                </g>
              </g>
            </svg>
          )
          setContent(
            <span>
              Your plan renews on {renewalDate}. Your credit card details need to be updated.
              {isAdmin && <> <a href="/settings/billing?expend_cc=1#billing-details">Update Credit Card</a></>}
              {!isAdmin && <> <a onClick={() => setShowUserPlanLimitReachModal(true)}>Update Credit Card</a></>}
            </span>
          )
          break
      }
    }
  }, [notification])

  const [showUserPlanLimitReachModal, setShowUserPlanLimitReachModal] = useState(false)

  const userPlanLimitReachModal = showUserPlanLimitReachModal && ReactDOM.createPortal(
    <UserPlanLimitReachModal
      admins={admins}
      cancelAction={() => setShowUserPlanLimitReachModal(false)}
      confirmAction={() => {
        window.open(`mailto:${adminEmails.join(',')}?subject=Update%20Pima%20Plan&body=Hi%20all%2C%0A%0AOur%20team%20has%20reach%20our%20plan%20limit%20on%20Pima.%0A%0APlease%20update%20our%20plan%20so%20I%20can%20keep%20using%20Pima.%0A%0ABest%2C`)
      }}
      confirmButtonText={'Contact Admins'}
      logoOverride={popupLogo}
      contentOverride={popupContent} />,
    document.getElementById('modal-container')
  )

  return (
    <>
      <div className={styles.banner} style={dismissed ? { height: 0 } : {}}>
        <div className={styles.dismissNotification}></div>
        <div>{content}</div>
        <div className={styles.dismissNotification}>
          <a className="delete dismissNotification" aria-label="close" draggable="false" onClick={() => dismissNotification()}>
            Dismiss
          </a>
        </div>
      </div>
      {userPlanLimitReachModal}
    </>
  )
}

export default PlanUpdateBanner