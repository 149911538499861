import React from "react"

const ActionSelector = ({
  agreementResources,
  availableAgreementResources,
  documentResources,
  innerStyle,
  onChange,
}) => {
  if (agreementResources.length == 0) {
    onChange('send_document')
  }

  if (documentResources.length == 0) {
    onChange('send_agreement')
  }

  return <div style={innerStyle} className="action-selector">
    <div className="is-relative" style={{ opacity: ((agreementResources.length == 0 || documentResources.length == 0) ? '0.5' : '1') }}>
      <input key="actionSelectDocumentAndAgreement" type="radio" defaultChecked={(agreementResources.length == 0 ? '' : 'checked')} id="bind_document_with_agreement" name="action" value="bind_document_with_agreement" onChange={() => onChange('bind_document_with_agreement')} disabled={(agreementResources.length == 0 || documentResources.length == 0)} />
      <label className="button" htmlFor="bind_document_with_agreement"><strong>Bundle Documents with Agreements</strong><br />Require company to sign agreements before viewing documents.</label>
    </div>
    <div className="is-relative" style={{ opacity: (availableAgreementResources.length == 0 ? '0.5' : '1') }}>
      <input key="actionSelectAgreement" type="radio" defaultChecked={(documentResources.length == 0 ? 'checked' : '')} id="send_agreement" name="action" value="send_agreement" onChange={() => onChange('send_agreement')} disabled={availableAgreementResources.length == 0} />
      <label className="button" htmlFor="send_agreement"><strong>Add Agreements</strong><br />Choose agreements to sign.</label>
    </div>
    <div className="is-relative" style={{ opacity: (documentResources.length == 0 ? '0.5' : '1') }}>
      <input key="actionSelectDocument" type="radio" defaultChecked={(agreementResources.length == 0 ? 'checked' : '')} id="send_document" name="action" value="send_document" onChange={() => onChange('send_document')} disabled={documentResources.length == 0} />
      <label className="button" htmlFor="send_document"><strong>Add Documents</strong><br />Choose documents.</label>
    </div>
  </div>
}

export default ActionSelector
