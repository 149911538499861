import React, { useEffect, useMemo, useState } from "react"
import ReactDOM from 'react-dom'
import moment from 'moment'

import AgreementModalForm from './AgreementModalForm'
import HistoryEntry from './HistoryEntry'
import NoteModalForm from './NoteModalForm'
import UserNotification from './UserNotification'

const History = ({
  highlightRequestSlug,
  onPanelClose,
  partnerName,
  partnerSlug
}) => {
  const signedAgreements = []
  const signedAgreementIds = []
  const archivedRequests = []
  const archivedRequestIds = []

  const formatNotes = (notes) => {
    notes.forEach((note) => {
      if (typeof note.notes != 'string') {
        return
      }
      note.html_notes = note.notes.split('\n').map((item, key) => {
        return <React.Fragment key={key}>{item}<br /></React.Fragment>
      })
    })
    return notes
  }

  const [currentCompanyId, setCurrentCompanyId] = useState(null)
  const [currentUserId, setCurrentUserId] = useState(null)
  const [envelopes, setEnvelopes] = useState([])
  const [history, setHistory] = useState(null)
  const [historyData, setHistoryData] = useState([])
  const [isAdmin, setIsAdmin] = useState(null)
  const [notes, setNotes] = useState(null)
  const [showAgreementModalForm, setShowAgreementModalForm] = useState(false)
  const [showNoteModal, setShowNoteModal] = useState(false)

  useEffect(() => {
    fetch(`${window.location.pathname}/history_and_tools`)
      .then(res => res.json())
      .then(data => {
        setCurrentCompanyId(data.current_company_id)
        setCurrentUserId(data.current_user_id)
        setIsAdmin(data.current_user_is_admin)
        setEnvelopes(data.envelopes)
        setNotes(formatNotes(data.notes))
        setHistoryData(data.history)
        setHistory(buildHistory(data.current_company_id, data.current_user_id, data.envelopes, data.history, data.notes))
      })
  }, [])

  const buildHistory = (currentCompanyId, currentUserId, envelopes, historyData, notes) => {
    if (envelopes.length > 0) {
      envelopes.forEach((envelope) => {
        envelope.agreements.forEach((agreement) => {
          if (agreement.signed && signedAgreementIds.indexOf(agreement.id) == -1) {
            const signedAgreement = JSON.parse(JSON.stringify(agreement)) // deep clone
            signedAgreement.type = 'signed_agreement'
            signedAgreements.push(signedAgreement)
            signedAgreementIds.push(signedAgreement.id)
          }
        })
      })
    }

    if (historyData.length > 0) {
      historyData.forEach((entity) => {
        const isOwnCompanyArchivedRequest = entity.company.id == currentCompanyId
        if (entity.type == 'request' && entity.status == 'archived' && archivedRequestIds.indexOf(entity.id) == -1 && !isOwnCompanyArchivedRequest) {
          const archivedRequest = JSON.parse(JSON.stringify(entity)) // deep clone
          archivedRequest.type = 'archived_request'
          archivedRequests.push(archivedRequest)
          archivedRequestIds.push(archivedRequest.id)
        }
      })
    }

    const history = historyData.concat(signedAgreements)
      .concat(archivedRequests)
      .concat(envelopes)
      .concat(notes)
      .sort((entryA, entryB) => {
        let entryADate = entryA.created_at
        if (entryA.type && entryA.type === 'signed_agreement') {
          entryADate = entryA.signed_on
        }
        if (entryA.type && entryA.type === 'archived_request') {
          entryADate = entryA.acted_on
        }

        let entryBDate = entryB.created_at
        if (entryB.type && entryB.type === 'signed_agreement') {
          entryBDate = entryB.signed_on
        }
        if (entryB.type && entryB.type === 'archived_request') {
          entryBDate = entryB.acted_on
        }

        const dateA = new Date(entryADate)
        const dateB = new Date(entryBDate)
        return dateB - dateA
      })

    if (history.length == 0) {
      return []
    }

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

    const months = []
    const historyByMonth = []

    const markEnvelopeHasResponseToRequest = (highlightRequestSlug, history) => {
      let requestAgreementResourceSlugs = []
      let requestDocumentResourceSlugs = []

      // collect the requested agreements and documents of the highlighted request
      history.forEach((historyEntry, key) => {
        if (historyEntry.request_slug && historyEntry.request_slug == highlightRequestSlug) {
          requestAgreementResourceSlugs = historyEntry.request_agreement_resources.map(r => r.slug)
          requestDocumentResourceSlugs = historyEntry.request_document_resources.map(r => r.slug)
        }
      })
      // look if entity has agreements and/or documents matching the request
      history.forEach(entry => {
        const agreementResourceSlugs = entry.agreements ? entry.agreements.map(a => a.resource_slug) : []
        const documentResourceSlugs = entry.documents ? entry.documents.map(d => d.resource_slug) : []
        const commonAgreementResourceSlugs = agreementResourceSlugs.filter(slug => requestAgreementResourceSlugs.includes(slug))
        const commonDocumentResourceSlugs = documentResourceSlugs.filter(slug => requestDocumentResourceSlugs.includes(slug))
        if (commonAgreementResourceSlugs.length > 0 || commonDocumentResourceSlugs.length > 0) {
          entry.request_slug = highlightRequestSlug
        }
      })
    }

    if (highlightRequestSlug) {
      markEnvelopeHasResponseToRequest(highlightRequestSlug, history)
    }

    const historyEntryIsUploadedAgreement = (historyEntry) => {
      if (historyEntry.agreements && historyEntry.agreements.length == 1 && historyEntry.documents.length == 0) {
        return historyEntry.agreements[0].acted_by && !historyEntry.agreements[0].signed_by
      }
    }

    history.forEach((historyEntry, key) => {
      if (historyEntryIsUploadedAgreement(historyEntry)) {
        return
      }

      let entryDate = historyEntry.created_at
      if (historyEntry.type && historyEntry.type === 'signed_agreement') {
        entryDate = historyEntry.signed_on
      }
      if (historyEntry.type && historyEntry.type === 'archived_request') {
        entryDate = historyEntry.acted_on
      }
      const month = moment(entryDate).startOf("month").format('MMMM')

      if (months.indexOf(month) == -1 && key != 0) {
        historyByMonth.push(<b key={key + 1000000} style={{ fontSize: '16px' }}>{month}</b>)
      }
      months.push(month)

      historyByMonth.push(
        <HistoryEntry
          key={key}
          currentCompanyId={currentCompanyId}
          currentUserId={currentUserId}
          entity={historyEntry}
          highlighted={highlightRequestSlug ? highlightRequestSlug == historyEntry.request_slug : false}
          onEdit={(entity) => entity.type == 'note' && setShowNoteModal(entity)}
          tz={tz}
        />
      )
    })

    if (highlightRequestSlug) {
      setTimeout(() => location.hash = highlightRequestSlug, 1000)
    }

    return historyByMonth
  }

  const onNoteSave = (note, created) => {
    if (created) {
      notes.unshift(note)
    } else {
      notes.forEach((existingNote) => {
        if (existingNote.id == note.id) {
          existingNote = note
        }
      })
    }

    setNotes(formatNotes(notes))
    setShowNoteModal(false)
    setHistory(buildHistory(currentCompanyId, currentUserId, envelopes, historyData, notes))
  }

  const onNoteDelete = (deletedNote) => {
    let deleteIndex
    notes.forEach((note, index) => {
      if (note.id == deletedNote.id) {
        deleteIndex = index
      }
    })
    notes.splice(deleteIndex, 1)

    setNotes(notes)
    setShowNoteModal(false)
    setHistory(buildHistory())
  }

  const noteModal = useMemo(() => {
    if (showNoteModal) {
      return ReactDOM.createPortal(<NoteModalForm
        cancelAction={(e) => setShowNoteModal(false)}
        note={typeof showNoteModal === 'object' ? showNoteModal : null}
        onSave={(note, created) => onNoteSave(note, created)}
        onDelete={(note) => onNoteDelete(note)}
        partnerSlug={partnerSlug}
        title={"Add Note"} />,
        document.getElementById('modal-container')
      )
    }
  }, [showNoteModal])

  const agreementModal = useMemo(() => {
    if (showAgreementModalForm) {
      return <AgreementModalForm
        cancelAction={(e) => setShowAgreementModalForm(false)}
        partnerSlug={partnerSlug} />
    }
  }, [showAgreementModalForm])

  return (
    <>
      <a className="side-panel-background" onClick={() => onPanelClose()}></a>
      <div className="side-panel">
        {noteModal}
        {agreementModal}
        <div className="content is-title-block">
          <a className="delete" onClick={() => onPanelClose()}></a>
          History &amp; Tools
        </div>
        <div className="content">
          <h2>Stats</h2>
          <div className="separator"></div>
          <div className="columns">
            <div className="column">
              {history && <div className="message">
                <div className="columns">
                  <div className="column" style={{ paddingTop: '24px', maxWidth: '40px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path fill="#8C9296" fillRule="evenodd" d="M14 9V5l7 7-7 7v-4.1c-5 0-8.5 1.6-11 5.1 1-5 4-10 11-11z" />
                    </svg>
                  </div>
                  <div className="column">
                    <b>Envelopes Sent</b>
                    <br />
                    {envelopes.length}
                  </div>
                </div>
              </div>}
              {!history && <div style={{ position: 'relative' }}><div className="message loading-row" style={{ height: '68px' }}></div></div>}
            </div>
            <div className="column">
              {history && <div className="message">
                <div className="columns">
                  <div className="column" style={{ paddingTop: '24px', maxWidth: '40px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path fill="#8C9296" fillRule="evenodd" d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
                    </svg>
                  </div>
                  <div className="column">
                    <b>Envelopes Viewed</b>
                    <br />
                    {historyData.filter((historyEntry) => historyEntry.type == 'access_log').length}
                  </div>
                </div>
              </div>}
              {!history && <div style={{ position: 'relative' }}><div className="message loading-row" style={{ height: '68px' }}></div></div>}
            </div>
          </div>
          <h2>Tools</h2>
          <div className="separator"></div>
          <div className="columns">
            <div className="column">
              {history && <div className="message" onClick={(e) => setShowNoteModal(true)} style={{ cursor: 'pointer' }}>
                <div className="columns">
                  <div className="column" style={{ paddingTop: '24px', maxWidth: '40px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path fill="#8C9296" fillRule="evenodd" d="M20.71 7.043c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.389-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83zM3 17.253v3.75h3.75l11.06-11.06-3.75-3.75L3 17.253z" />
                    </svg>
                  </div>
                  <div className="column" style={{ color: '#0075e0' }}>
                    <b style={{ color: '#4a4a4a' }}>Notes</b>
                    <br />
                    Add Note
                  </div>
                </div>
              </div>}
              {!history && <div style={{ position: 'relative' }}><div className="message loading-row" style={{ height: '68px' }}></div></div>}
            </div>
            <div className="column">
              {history && <div className="message" onClick={(e) => setShowAgreementModalForm(true)} style={{ cursor: 'pointer' }}>
                <div className="columns">
                  <div className="column" style={{ paddingTop: '24px', maxWidth: '40px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path fill="#8C9296" fillRule="evenodd" d="M19 21H5v-2h14v2zm-4-4v-6h4l-7-7-7 7h4v6h6z" />
                    </svg>
                  </div>
                  <div className="column" style={{ color: '#0075e0' }}>
                    <b style={{ color: '#4a4a4a' }}>Existing Agreement</b>
                    <br />
                    Upload
                  </div>
                </div>
              </div>}
              {!history && <div style={{ position: 'relative' }}><div className="message loading-row" style={{ height: '68px' }}></div></div>}
            </div>
          </div>

          <h2>History</h2>
          {!currentCompanyId && <div style={{ overflow: 'hidden', marginBottom: '8px', position: 'relative', width: '100%' }}><div className="message loading-row" style={{ height: '39px' }}></div></div>}
          {currentCompanyId && <UserNotification
            currentUserId={currentUserId}
            isAdmin={isAdmin}
            partnerName={partnerName}
            partnerSlug={partnerSlug} />}
          <div className="separator"></div>
          {!history && <div style={{ overflow: 'hidden', position: 'relative', width: '100%' }}><div className="message loading-row" style={{ height: '68px' }}></div></div>}
          {history}
        </div>
      </div>
    </>
  )
}

export default History
