import React, { useState } from "react"
import { useForm } from 'react-hook-form'

import utils from '../utils/utils'
import resourceFormUtils from '../utils/ResourceFormUtils'

import FileField from "./FileField"
import Modal from "../Modal"
import { MultiSelect } from "react-multi-select-component"
import TextArea from "./TextArea"
import TextField from "./TextField"

function AgreementResourceForm(props) {
  const agreementResource = props.agreementResource
  const agreementResourceErrors = props.agreementResourceErrors
  const companyHasResourceRequestCenters = props.companyHasResourceRequestCenters
  const requestCenters = props.requestCenters

  const { handleSubmit, register, errors, formState } = useForm({
    defaultValues: {
      'agreement_resource[name]': agreementResource ? agreementResource.name : null,
      'agreement_resource[description]': agreementResource ? agreementResource.description : null
    },
    mode: "onChange"
  })

  const requestCenterOptions = resourceFormUtils.requestCenterOptions(requestCenters)
  const selectedRequestCenterOptions = requestCenterOptions.filter(option => props.selectedRequestCenterIds.indexOf(option.value) > -1)

  const [isPublic, setIsPublic] = useState(agreementResource.public)
  const [selfServe, setSelfServe] = useState(agreementResource.self_serve)
  const [selectedRequestCenterIds, setSelectedRequestCenterIds] = useState(selectedRequestCenterOptions)
  const [submited, setSubmited] = useState(false)

  const onCancel = () => {
    Turbolinks.visit('/agreements', { action: 'replace' })
  }

  const requestCenterValid = () => {
    if (!isPublic) {
      return true
    }

    const hasSelectedRequestCenters = selectedRequestCenterIds.length != 0
    return isPublic && hasSelectedRequestCenters
  }

  const confirmButton = <input className={`button is-info ${formState.isValid && requestCenterValid() ? '' : 'disabled'}`}
    draggable="false"
    type="submit"
    value='Save Changes' />

  const formAction = `/agreements${agreementResource.id ? `/${agreementResource.slug}` : ''}`

  const submitForm = () => {
    if (!requestCenterValid()) {
      return
    }

    if (submited) {
      return
    } else {
      setSubmited(true)
    }

    const form = document.querySelector("#agreement-resource-form")
    form.setAttribute("method", "post")
    form.submit()
  }

  if (!isPublic && selectedRequestCenterIds.length > 0) {
    setSelectedRequestCenterIds([])
  }
  if (!isPublic && selfServe) {
    setSelfServe(false)
  }

  const deleteButton = agreementResource.id ? <a className="button is-danger is-inverted is-pulled-left" draggable="false" style={{ position: 'absolute', left: '40px' }} href={`/agreements/${agreementResource.slug}/confirm_archive`}>Archive</a> : false

  const CustomClearIcon = () => <div></div>

  return <form id="agreement-resource-form" action={formAction} encType="multipart/form-data" onSubmit={handleSubmit(submitForm)}>
    {agreementResource.id && <input type="hidden" name="_method" value="patch" />}
    <input type="hidden" name="authenticity_token" value={utils.csrfToken()} />

    <Modal
      cancelAction={onCancel}
      confirmButton={confirmButton}
      deleteButton={deleteButton}
      modalCardStyle={{ overflow: 'initial' }}
      modalCardBodyStyle={{ overflow: 'initial' }}
      title={agreementResource.id ? 'Edit Details' : 'Upload New Agreement'}>

      {(!agreementResource.id || agreementResourceErrors?.file?.length) && <div className="columns">
        <div className="column">
          <FileField
            errors={errors}
            label="Upload Agreement"
            name="agreement_resource[file]"
            placeholder="E.g. Soc 2 Report"
            reff={register({
              required: true,
              validate: {
                agreementResourceFileFormat: file => resourceFormUtils.fileFormatValid(file, resourceFormUtils.supportedAgreementResourceFormats),
                agreementResourceFileSize: file => resourceFormUtils.fileSizeValid(file)
              }
            })} />
          {agreementResourceErrors?.file?.length && <span style={{ color: '#d92022', fontSize: '12px', fontWeight: 600, position: 'absolute', marginTop: '-10px' }} className="error">{agreementResourceErrors.file[0]}</span>}
        </div>
      </div>}

      <div className="columns">
        <div className="column">
          <TextField
            errors={errors}
            label="Agreement Name"
            name="agreement_resource[name]"
            placeholder="E.g. NDA 2022"
            reff={register({ required: true })} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <TextArea
            errors={errors}
            label="Description"
            name="agreement_resource[description]"
            placeholder="E.g. This report contains..."
            reff={register()} />
        </div>
      </div>

      {companyHasResourceRequestCenters && <>
        <div className="columns">
          <div className="column">
            <span className="control">
              <span className="is-primary pure-material-checkbox">
                <label className="pure-material-checkbox" htmlFor="agreement_resource_public">
                  <input name="agreement_resource[public]" type="hidden" value="0" />
                  <input type="checkbox"
                    value="1"
                    disabled={requestCenterOptions.length == 0}
                    checked={isPublic}
                    onChange={() => { setIsPublic(!isPublic) }}
                    name="agreement_resource[public]"
                    id="agreement_resource_public"
                    style={{ opacity: (requestCenterOptions.length == 0 ? 0.5 : 1) }} />
                  <span style={{ opacity: (requestCenterOptions.length == 0 ? 0.5 : 1) }}>
                    List this agreement in these Request Centers
                  </span>
                </label>
              </span>
            </span>
            <div className="control" style={{ marginLeft: '34px' }}>
              <input name="agreement_resource[request_center_ids][]" type="hidden" value="" readOnly />

              {selectedRequestCenterIds.map(selectedRequestCenterId => {
                return <input name="agreement_resource[request_center_ids][]"
                  type="hidden"
                  readOnly
                  key={selectedRequestCenterId.value}
                  value={selectedRequestCenterId.value} />
              })}

              {!requestCenterValid() && formState.isSubmitted && <span style={{ color: '#d92022', fontSize: '12px', fontWeight: 600, position: 'absolute', top: '-18px', right: 0 }} className="error">Required</span>}
              <MultiSelect
                ClearSelectedIcon={<CustomClearIcon />}
                className={`agreement-dropdown ${!requestCenterValid() && formState.isSubmitted ? 'error' : ''}`}
                disabled={!isPublic || requestCenterOptions.length == 0}
                options={requestCenterOptions}
                value={selectedRequestCenterIds}
                onChange={setSelectedRequestCenterIds}
                hasSelectAll={false}
                disableSearch={true}
                style={{ opacity: ((selfServe || requestCenterOptions.length == 0) ? 0.5 : 1) }}
                labelledBy={"Choose Request Centers…"} />
            </div>

            {requestCenterOptions.length == 0 && <p style={{ background: '#F5F5F5', borderRadius: '3px', fontSize: '14px', marginLeft: '34px', marginTop: '10px', padding: '10px 15px' }}>
              No request center found. Click <a href="/settings/request_centers">here</a> to create a request center.
            </p>}
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <span className="control">
              <span className="is-primary pure-material-checkbox">
                <label className="pure-material-checkbox" htmlFor="agreement_resource_self_serve">
                  <input name="agreement_resource[self_serve]" type="hidden" value="0" />
                  <input type="checkbox"
                    value="1"
                    checked={selfServe}
                    onChange={() => setSelfServe(!selfServe)}
                    name="agreement_resource[self_serve]"
                    id="agreement_resource_self_serve"
                    disabled={!isPublic} />
                  <span>Automatically approve requests and send a signing link for this agreement</span>
                </label>
              </span>
            </span>
          </div>
        </div>
      </>}

      {agreementResource.id && <>
        <br />
        <div className="columns">
          <div className="column is-three-quarters">
            <h3><b>Customize Template</b></h3>
            <p style={{ fontSize: '14px' }}>To edit or change this template, you must customize it again.<br />Note: this will remove the old template. </p>
          </div>
          <div className="column is-one-quarter has-text-right">
            <br />
            <a style={{ width: '110px' }} className="button is-primary is-outlined" draggable="false" href={`/agreements/${agreementResource.slug}/create_hellosign_template`}>
              Customize
            </a>
          </div>
        </div>

        <div className="columns">
          <div className="column is-three-quarters">
            <h3><b>Preview Template</b></h3>
            <p style={{ fontSize: '14px' }}>View the signing process to see a preview of what your<br />prospects will see.</p>
          </div>
          <div className="column is-one-quarter has-text-right">
            <br />
            <a style={{ width: '110px' }} className="button is-primary is-outlined" draggable="false" href={`/agreements/${agreementResource.slug}/test_hellosign_template`}>
              Preview
            </a>
          </div>
        </div>
      </>}
    </Modal>
  </form>
}

export default AgreementResourceForm
