import React, { useMemo } from "react"
import utils from 'components/utils/utils'

const ResourceRow = ({
  checked,
  contactCanBeSigner,
  contactIsSigner,
  disabled = false,
  name,
  onResourceSelected,
  resource,
  resourceType,
  signerSelected,
}) => {
  const setSignerClicked = (e) => {
    e.preventDefault()
    signerSelected(resource.id)
  }

  const content = useMemo(() => {
    if (resourceType === 'contact') {
      return <>
        <div className="column" style={{ alignItems: 'center', display: 'flex' }}>
          <p style={{ fontSize: '14px' }}>
            <strong style={{ fontSize: '16px' }}>{resource.first_name} {resource.last_name}</strong>
            <br />
            {resource.email}
          </p>
        </div>
        {contactCanBeSigner && <div className="column" style={{ alignItems: 'center', display: 'flex', fontSize: '14px', flex: 'none', width: '100px' }}>
          {!contactIsSigner && <p style={{ textAlign: 'right', width: '100%' }}><a onClick={(e) => setSignerClicked(e)} style={{ zIndex: 1000 }}>Set Signer</a></p>}
          {contactIsSigner && <p style={{ textAlign: 'right', width: '100%' }}>Signer</p>}
        </div>}
        {!contactCanBeSigner && contactIsSigner && <div className="column" style={{ alignItems: 'center', display: 'flex', fontSize: '14px', flex: 'none', width: '100px' }}><p style={{ textAlign: 'right', width: '100%' }}>Signer</p></div>}
      </>
    } else {
      return <div className="column" style={{ alignItems: 'center', display: 'flex' }}>
        <p style={{ color: '#424c53', fontSize: '14px', fontWeight: 600 }}>
          <span style={{ fontSize: '16px', color: '#18242d', fontWeight: 'bold' }}>{resource.name}</span>
        </p>
      </div>
    }
  }, [resource, contactIsSigner])

  return <li style={{ borderBottom: 'solid 1px silver', marginTop: '0px', padding: '11px' }}>
    <label>
      <div className={`columns share-${resourceType}-row`}>
        <div className="column fixed-55-width">
          <div className="pure-material-checkbox is-vertically-centered-checkbox">
            <input
              checked={checked}
              disabled={disabled}
              id={`${resourceType}_ids_${resource.id}`}
              name={name}
              onChange={(e) => onResourceSelected(resource.id, e.target.checked)}
              type="checkbox" />
            <span></span>
          </div>
        </div>

        {false && resourceType !== 'contact' && <div className="column is-one-fifth is-file-preview is-small-file-preview">
          <span className="preview">
            <img
              alt={resource.name}
              className="small"
              src={utils.usePreviewCDN(`/${resourceType}_resources/${resource.slug}/preview?resize=47x60`, resource)}
              style={{ height: '60px', width: '47px', display: 'block' }} />
          </span>
        </div>}

        {content}
      </div>
    </label>
  </li>
}

export default ResourceRow
