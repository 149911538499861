import React, { useEffect, useMemo, useState } from "react"
import Turbolinks from "turbolinks"
import PackageList from 'components/SharePackages/PackageList'

import utils from '../../utils/utils'

import Modal from "../../Modal"

const RequestCenterUpgradeModal = ({
  onCancel,
  requestCenter,
}) => {
  const [step, setStep] = useState('warning')
  const [packages, setPackages] = useState([])
  const [selectedPackages, setSelectedPackages] = useState([])

  const navigateToRequestForm = () => {
    Turbolinks.visit(`/request_forms/${requestCenter.slug}?request_center_updated=1`, { action: 'replace' })
  }

  const requestCenterURL = () => {
    return `/request_centers/${requestCenter.id}`
  }

  const requestCenterParams = (method, data) => {
    const params = {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }

    if (data) {
      params['body'] = JSON.stringify({ request_center: data })
    }

    return params
  }

  const upgradeRequestCenter = () => {
    fetch(
      requestCenterURL(),
      requestCenterParams(
        'PATCH',
        {
          package_ids: selectedPackages,
          request_center_type: 'packages'
        }
      )
    )
      .then(response => response.json())
      .then(() => navigateToRequestForm())
  }

  const confirmButton = useMemo(() => {
    if (step == 'warning') {
      return <a className="button is-info" onClick={() => setStep(packages.length > 0 ? 'packages' : 'empty')}>Next</a>
    }
    if (step == 'packages') {
      return <a className={`button ${selectedPackages.length > 0 ? 'is-info' : ''}`} disabled={selectedPackages.length == 0} onClick={() => selectedPackages.length > 0 && setStep('confirm')}>Next</a>
    }
    if (step == 'empty') {
      return <a className="button is-info" onClick={() => onCancel()}>Close</a>
    }
    if (step == 'confirm') {
      return <a className="button is-info" onClick={() => upgradeRequestCenter()}>Save</a>
    }
  }, [packages, selectedPackages, step])

  useEffect(() => {
    utils.getJson('/packages.json').then(packages => {
      setPackages(packages.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
    })
  }, [])

  const backButton = useMemo(() => {
    if (step == 'warning') {
      return null
    }

    return <a className="button is-outline is-pulled-left" draggable="false" style={{ position: 'absolute', left: '40px' }} onClick={() => setStep(step == 'confirm' ? 'packages' : 'warning')}>
      <svg style={{ marginLeft: '-5px', marginRight: '7px' }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd"><g fill="#424C53"><g><g><g><g>
          <path d="M15 8.25L5.872 8.25 10.065 4.058 9 3 3 9 9 15 10.057 13.943 5.872 9.75 15 9.75z" transform="translate(-391 -735) translate(347 140) translate(0 568) translate(32 18) translate(12 9)" />
        </g></g></g></g></g></g>
      </svg>
      Back
    </a>
  }, [step])

  return <Modal
    cancelAction={() => onCancel()}
    cancelButton={step == 'empty'}
    confirmButton={confirmButton}
    deleteButton={backButton}
    title="Upgrade to request form" >
    {step == 'warning' && <>
      <p>Upgrading this request center to a request form <b>can’t be undone</b>. Are you sure you want to continue? The current name and URL will remain the same after the upgrade.</p>
      <br />
      <br />
      <div style={{ backgroundColor: '#F4F5F5', borderRadius: '8px', fontSize: '14px', padding: '16px' }}>
        <p>
          Request forms provide an updated way to share packages with your prospects. Legacy request centers are being turned off by the end of Q3 2024, so be sure to upgrade before then.
          <br />
          <br />
          To learn more about this change and how to migrate to packages and request forms, <a href="https://changelog.pima.app/request-form-migration" target="_blank">read the announcement</a>.
        </p>
      </div>
    </>}
    {step == 'packages' && <>
      <p>Choose the packages to include in the upgraded request form:</p>
      <br />
      <PackageList
        disabledEmptyPackages={true}
        onChange={packageIds => setSelectedPackages(packageIds)}
        packages={packages}
        selectedPackages={selectedPackages} />
    </>}
    {step == 'empty' && <p>
      To upgrade this request center, you must have at least one package. Navigate to the packages tab to create one, or click <a href="/packages">here</a>.
    </p>}
    {step == 'confirm' && <div>
      {(requestCenter.documents.length > 0 || requestCenter.agreements > 0) && <>
        <p>The following documents and/or agreements will be removed:</p>
        <ul>
          {requestCenter.documents.map(document => <li>- {document.name}</li>)}
          {requestCenter.agreements.map(agreement => <li>- {agreement.name}</li>)}
        </ul>
        <br />
      </>}
      <p>The following packages will be added:</p>
      <ul>
        {packages.filter(packageData => selectedPackages.includes(packageData.id)).map(packageData => <p>
          <li>- {packageData.name}</li>
        </p>)}
      </ul>
    </div>}
  </Modal>
}

export default RequestCenterUpgradeModal
