import React, { useState } from "react"
import { useForm } from 'react-hook-form'

import utils from '../utils/utils'
import resourceFormUtils from '../utils/ResourceFormUtils'

import FileField from "./FileField"
import GroupSelector from "./GroupSelector"
import LabelSelector from "./LabelSelector"
import Modal from "../Modal"
import { MultiSelect } from "react-multi-select-component"
import TextArea from "./TextArea"
import TextField from "./TextField"

const DocumentResourceForm = (props) => {
  const isAdmin = props.isAdmin
  const agreementResources = props.agreementResources
  const canEditGroups = props.canEditGroups
  const companyHasResourceRequestCenters = props.companyHasResourceRequestCenters
  const documentResource = props.documentResource
  const documentResourceErrors = props.documentResourceErrors
  const groupOptions = props.groups
  const labels = props.labels
  const planAllowsLabels = props.planAllowsLabels
  const requestCenters = props.requestCenters
  const planAllowsSelfServeDocuments = props.planAllowsSelfServeDocuments

  const { handleSubmit, register, errors, formState } = useForm({
    defaultValues: {
      'document_resource[name]': documentResource ? documentResource.name : null,
      'document_resource[description]': documentResource ? documentResource.description : null
    },
    mode: "onChange"
  })

  const options = agreementResources.map((agreementResource) => {
    return { label: agreementResource.name, value: agreementResource.id }
  })
  const requestCenterOptions = resourceFormUtils.requestCenterOptions(requestCenters)
  const selectedOptions = options.filter(option => props.selectedAgreementResourceIds.indexOf(option.value) > -1)
  const selectedRequestCenterOptions = requestCenterOptions.filter(option => props.selectedRequestCenterIds.indexOf(option.value) > -1)

  const [hasGroups, setHasGroups] = useState(props.selectedGroupIds.length > 0)
  const [isPublic, setIsPublic] = useState(documentResource.public)
  const [selfServe, setSelfServe] = useState(documentResource.self_serve)
  const [requireAgreement, setRequireAgreement] = useState(selectedOptions.length > 0)
  const [selectedAgreementResourceIds, setSelectedAgreementResourceIds] = useState(selectedOptions)
  const [selectedLabelIds, setSelectedLabelIds] = useState(props.selectedLabelIds || [])
  const [selectedRequestCenterIds, setSelectedRequestCenterIds] = useState(selectedRequestCenterOptions)
  const [submited, setSubmited] = useState(false)

  const disabledSelfServe = !isPublic || !planAllowsSelfServeDocuments
  const disabledRequireAgreement = !(isPublic && selfServe)
  const disabledAgreementResourceIds = !(isPublic && selfServe && requireAgreement)
  const disabledRequestCenterIds = !isPublic

  const [selectedGroupIds, setSelectedGroupIds] = useState(
    groupOptions.filter(option => props.selectedGroupIds.indexOf(option.value) > -1)
  )

  const [groupsValid, setGroupsValid] = useState(true)

  const formError = () => {
    return !requestCenterValid() || !agreementResourceListValid() || !groupsValid
  }

  const onCancel = () => {
    Turbolinks.visit('/documents', { action: 'replace' })
  }

  const requestCenterValid = () => {
    if (!isPublic) {
      return true
    }

    const hasSelectedRequestCenters = selectedRequestCenterIds.length != 0
    return isPublic && hasSelectedRequestCenters
  }

  const agreementResourceListValid = () => {
    if (!requireAgreement) {
      return true
    }

    const hasSelectedAgreementResources = selectedAgreementResourceIds.length != 0
    return requireAgreement && hasSelectedAgreementResources
  }

  const confirmButton = <input className={`button is-info ${formState.isValid && requestCenterValid() ? '' : 'disabled'}`}
    draggable="false"
    type="submit"
    value='Save Changes' />

  const formAction = `/documents${documentResource.id ? `/${documentResource.slug}` : ''}`

  const submitForm = () => {
    if (formError()) {
      return
    }

    if (submited) {
      return
    } else {
      setSubmited(true)
    }

    const form = document.querySelector("#document-resource-form")
    form.setAttribute("method", "post")
    form.submit()
  }

  if (!isPublic && selectedRequestCenterIds.length > 0) {
    setSelectedRequestCenterIds([])
  }
  if (!isPublic && selfServe) {
    setSelfServe(false)
  }
  if (!selfServe && requireAgreement) {
    setRequireAgreement(false)
  }
  if (!requireAgreement && selectedAgreementResourceIds.length > 0) {
    setSelectedAgreementResourceIds([])
  }

  const deleteButton = documentResource.id ? <a className="button is-danger is-inverted is-pulled-left" draggable="false" style={{ position: 'absolute', left: '40px' }} href={`/documents/${documentResource.slug}/confirm_archive`}>Archive</a> : false

  const CustomClearIcon = () => <div></div>

  return <form id="document-resource-form" action={formAction} encType="multipart/form-data" onSubmit={handleSubmit(submitForm)}>
    {documentResource.id && <input type="hidden" name="_method" value="patch" />}
    <input type="hidden" name="authenticity_token" value={utils.csrfToken()} />

    <Modal
      cancelAction={onCancel}
      confirmButton={confirmButton}
      deleteButton={deleteButton}
      modalCardStyle={{ overflow: 'auto' }}
      modalCardBodyStyle={{ overflow: 'auto' }}
      title={documentResource.id ? 'Edit Details' : 'Upload New Document'}>

      {(!documentResource.id || documentResourceErrors?.file?.length) && <div className="columns">
        <div className="column">
          <FileField
            errors={errors}
            label="Upload Document"
            name="document_resource[file]"
            placeholder="E.g. Soc 2 Report"
            reff={register({
              required: true,
              validate: {
                documentResourceFileFormat: file => resourceFormUtils.fileFormatValid(file, resourceFormUtils.supportedDocumentResourceFormats),
                documentResourceFileSize: file => resourceFormUtils.fileSizeValid(file)
              }
            })} />
          {documentResourceErrors?.file?.length && <span style={{ color: '#d92022', fontSize: '12px', fontWeight: 600, position: 'absolute', marginTop: '-10px' }} className="error">{documentResourceErrors.file[0]}</span>}
        </div>
      </div>}

      <div className="columns">
        <div className="column">
          <TextField
            errors={errors}
            label="Document Name"
            name="document_resource[name]"
            placeholder="E.g. Soc 2 Report"
            reff={register({ required: true })} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <TextArea
            errors={errors}
            label="Description"
            name="document_resource[description]"
            placeholder="E.g. This report contains..."
            reff={register()} />
        </div>
      </div>

      {companyHasResourceRequestCenters && <>
        <div className="columns">
          <div className="column">
            <span className="control">
              <span className="is-primary pure-material-checkbox">
                <label className="pure-material-checkbox" htmlFor="document_resource_public">
                  <input name="document_resource[public]" type="hidden" value="0" />
                  <input type="checkbox"
                    value="1"
                    checked={isPublic}
                    disabled={requestCenterOptions.length == 0}
                    onChange={() => { setIsPublic(!isPublic) }}
                    name="document_resource[public]"
                    id="document_resource_public"
                    style={{ opacity: (requestCenterOptions.length == 0 ? 0.5 : 1) }} />
                  <span style={{ opacity: (requestCenterOptions.length == 0 ? 0.5 : 1) }}>
                    List this document in these Request Centers:
                  </span>
                </label>
              </span>
            </span>
            <div className="control" style={{ marginLeft: '34px' }}>
              <input name="document_resource[request_center_ids][]" type="hidden" value="" readOnly />

              {selectedRequestCenterIds.map(selectedRequestCenterId => {
                return <input name="document_resource[request_center_ids][]"
                  type="hidden"
                  readOnly
                  key={selectedRequestCenterId.value}
                  value={selectedRequestCenterId.value} />
              })}

              {!requestCenterValid() && formState.isSubmitted && <span style={{ color: '#d92022', fontSize: '12px', fontWeight: 600, position: 'absolute', top: '-18px', right: 0 }} className="error">Required</span>}
              <MultiSelect
                ClearSelectedIcon={<CustomClearIcon />}
                className={`agreement-dropdown ${!requestCenterValid() && formState.isSubmitted ? 'error' : ''}`}
                disabled={disabledRequestCenterIds || requestCenterOptions.length == 0}
                options={requestCenterOptions}
                value={selectedRequestCenterIds}
                onChange={setSelectedRequestCenterIds}
                hasSelectAll={false}
                disableSearch={true}
                style={{ opacity: ((disabledRequestCenterIds || requestCenterOptions.length == 0) ? 0.5 : 1) }}
                labelledBy={"Choose Request Centers…"} />
            </div>

            {requestCenterOptions.length == 0 && <p style={{ background: '#F5F5F5', borderRadius: '3px', fontSize: '14px', marginLeft: '34px', marginTop: '10px', padding: '10px 15px' }}>
              No request center found. Click <a href="/settings/request_centers">here</a> to create a request center.
            </p>}
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <span className="control">
              <span className="is-primary pure-material-checkbox">
                <label className="pure-material-checkbox" htmlFor="document_resource_self_serve">
                  <input name="document_resource[self_serve]" type="hidden" value="0" />
                  <input type="checkbox"
                    value="1"
                    checked={selfServe}
                    onChange={() => setSelfServe(!selfServe)}
                    name="document_resource[self_serve]"
                    id="document_resource_self_serve"
                    disabled={disabledSelfServe}
                    style={{ opacity: (disabledSelfServe ? 0.5 : 1) }} />
                  <span style={{ opacity: (disabledSelfServe ? 0.5 : 1) }}>
                    Automatically approve requests for this document and
                  </span>
                </label>
              </span>
            </span>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="field">
              <div className="control" style={{ marginLeft: '34px' }}>
                <label className="pure-material-radio is-small" htmlFor="auto_approve_agreements_false">
                  <input type="radio"
                    name="auto_approve_agreements"
                    id="auto_approve_agreements_false"
                    value="false"
                    checked={!requireAgreement}
                    onChange={() => setRequireAgreement(false)}
                    disabled={disabledRequireAgreement || !planAllowsSelfServeDocuments}
                    style={{ opacity: (disabledRequireAgreement || !planAllowsSelfServeDocuments ? 0.5 : 1) }} />
                  <span style={{ opacity: (disabledRequireAgreement || !planAllowsSelfServeDocuments ? 0.5 : 1) }}>
                    Immediately send a watermarked copy.
                  </span>
                </label>

                <br />

                <label className="pure-material-radio is-small" htmlFor="auto_approve_agreements_true">
                  <input type="radio" name="auto_approve_agreements"
                    id="auto_approve_agreements_true"
                    value="true"
                    checked={requireAgreement}
                    onChange={() => setRequireAgreement(true)}
                    disabled={disabledRequireAgreement || options.length == 0 || !planAllowsSelfServeDocuments}
                    style={{ opacity: ((disabledRequireAgreement || options.length == 0 || !planAllowsSelfServeDocuments) ? 0.5 : 1) }} />
                  <span style={{ opacity: ((disabledRequireAgreement || options.length == 0 || !planAllowsSelfServeDocuments) ? 0.5 : 1) }}>
                    Require agreements to be signed before sending a watermarked copy.
                  </span>
                </label>

                <br />
                {!planAllowsSelfServeDocuments && <p style={{ background: '#F5F5F5', borderRadius: '3px', fontSize: '14px', marginTop: '10px', padding: '10px 15px' }}>
                  This feature requires a paid plan. <a href="https://pima.app/pricing/" target="_blank">See Pricing.</a>
                </p>}

                {planAllowsSelfServeDocuments && <div className="control" style={{ marginLeft: '29px' }}>
                  <input name="document_resource[agreement_resource_ids][]"
                    type="hidden"
                    value=""
                    readOnly />

                  {selectedAgreementResourceIds.map(selectedAgreementResourceId => {
                    return <input name="document_resource[agreement_resource_ids][]"
                      type="hidden"
                      readOnly
                      key={selectedAgreementResourceId.value}
                      value={selectedAgreementResourceId.value} />
                  })}

                  {!agreementResourceListValid() && formState.isSubmitted && <span style={{ color: '#d92022', fontSize: '12px', fontWeight: 600, position: 'absolute', top: '-18px', right: 0 }} className="error">Required</span>}
                  <MultiSelect
                    ClearSelectedIcon={<CustomClearIcon />}
                    className={`agreement-dropdown ${!agreementResourceListValid() && formState.isSubmitted ? 'error' : ''}`}
                    disabled={disabledAgreementResourceIds || options.length == 0}
                    options={options}
                    value={selectedAgreementResourceIds}
                    onChange={setSelectedAgreementResourceIds}
                    hasSelectAll={false}
                    disableSearch={true}
                    style={{ opacity: ((disabledAgreementResourceIds || options.length == 0) ? 0.5 : 1) }}
                    labelledBy={"Choose agreements…"} />
                </div>}
              </div>
            </div>
          </div>
        </div>
      </>}

      {canEditGroups && <>
        {selectedGroupIds.length == 0 && <input name="document_resource[group_ids][]" type="hidden" value="" readOnly />}
        {selectedGroupIds.map(selectedGroup => (
          <input name="document_resource[group_ids][]"
            type="hidden"
            readOnly
            key={selectedGroup.value}
            value={selectedGroup.value} />
        ))}

        <GroupSelector
          groupOptions={groupOptions}
          hasGroups={props.selectedGroupIds.length > 0}
          isSubmitted={formState.isSubmitted}
          onChange={groups => setSelectedGroupIds(groups)}
          onGroupChangeValid={valid => setGroupsValid(valid)}
          selectedGroupIds={selectedGroupIds}
        />
      </>}

      {planAllowsLabels && <>
        {selectedLabelIds.length == 0 && <input name="document_resource[label_ids][]" type="hidden" value="" readOnly />}
        {selectedLabelIds.map(selectedLabelId => {
          return <input name="document_resource[label_ids][]"
            type="hidden"
            readOnly
            key={selectedLabelId}
            value={selectedLabelId} />
        })}

        <LabelSelector
          isAdmin={isAdmin}
          labels={labels}
          onChange={labelIds => setSelectedLabelIds(labelIds)}
          selectedLabelIds={selectedLabelIds} />
      </>}

      {companyHasResourceRequestCenters && <>
        <br />
        <div style={{ backgroundColor: '#F4F5F5', borderRadius: '8px', fontSize: '14px', padding: '16px' }}>
          Documents will no longer be directly listed on a request center, please use <a href="/packages">Packages</a> and <a href="/request_forms">Request Forms</a> moving forward.<br /><br />To learn more about this change and how to migrate to packages and request forms, <a href="https://support.pima.app" target="_blank">read the FAQ.</a>
        </div>
      </>}
    </Modal>
  </form>
}

export default DocumentResourceForm
