import React, { useState } from "react"
import ReactDOM from 'react-dom'

import PlanModalForm from './PlanModalForm'
import UserPlanLimitReachModal from './UserPlanLimitReachModal'

const UserPlanLimitReachBtn = (props) => {
    const adminEmails = props.adminEmails
    const isAdmin = props.isAdmin
    const type = props.type

    const [showUserPlanLimitReachModal, setShowUserPlanLimitReachModal] = useState(false)
    const [showPlanModalForm, setShowPlanModalForm] = useState(false)

    let btnText
    switch (type) {
        case 'agreement':
            btnText = 'Add Agreement'
            break;
        case 'document':
            btnText = 'Add Document'
            break;
        case 'share':
            btnText = 'Send Envelope'
            break;
        case 'request':
            btnText = 'Review and Send Envelopes'
            break;
    }

    const refreshCurrentPage = () => {
        Turbolinks.visit(window.location.toString(), { action: 'replace' })
    }

    const planModalForm = showPlanModalForm && ReactDOM.createPortal(
        <PlanModalForm
            {...props}
            cancel={() => {
                setShowUserPlanLimitReachModal(false)
                setShowPlanModalForm(false)
            }}
            onSave={() => refreshCurrentPage()} />,
        document.getElementById('modal-container')
    )

    const userPlanLimitReachModal = showUserPlanLimitReachModal && ReactDOM.createPortal(
        <UserPlanLimitReachModal
            cancelAction={() => setShowUserPlanLimitReachModal(false)}
            confirmAction={() => {
                if (isAdmin) {
                    setShowUserPlanLimitReachModal(false)
                    setShowPlanModalForm(true)
                } else {
                    window.open(`mailto:${adminEmails.join(',')}?subject=Update%20Pima%20Plan&body=Hi%20all%2C%0A%0AOur%20team%20has%20reach%20our%20plan%20limit%20on%20Pima.%0A%0APlease%20update%20our%20plan%20so%20I%20can%20keep%20using%20Pima.%0A%0ABest%2C`)
                }
            }}
            confirmButtonText={isAdmin ? 'View Plans' : 'Contact Admins'}
            {...props} />,
        document.getElementById('modal-container')
    )

    return <React.Fragment>
        <a className="button is-primary"
            onClick={() => setShowUserPlanLimitReachModal(true)}>
            {btnText}
        </a>
        {userPlanLimitReachModal}
        {planModalForm}
    </React.Fragment>
}

export default UserPlanLimitReachBtn