import React, { useEffect, useState } from "react"
import Modal from './../Modal'
import utils from '../utils/utils'
import PreferenceSkipWarning from './PreferenceSkipWarning'

const EntityConfirmAccess = ({
  cancelOnly = false,
  checkEndpoint,
  delayBeforeNextReadyPull = 1000,
  entitySlug,
  entityType,
  loading,
  loadingSubtext,
  loadingTitle,
  partnerSlug,
}) => {
  const [ready, setReady] = useState(!loading)

  const checkReady = async () => {
    const url = `/${entityType}s/${entitySlug}/${checkEndpoint}`
    const response = await fetch(url).then(response => response.json())
    if (response.attached) {
      if (response.require_token) {
        setReady(true)
      } else {
        Turbolinks.visit(`/${entityType}s/${entitySlug}/view`)
      }
    } else {
      setTimeout(() => checkReady(), delayBeforeNextReadyPull)
    }
  }

  const viewFile = async () => {
    const response = await fetch(`/${entityType}s/${entitySlug}/create_${entityType}_access_log`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }, method: 'POST'
    }).then(response => response.json())

    Turbolinks.visit(`/${entityType}s/${entitySlug}/view?token=${response.token}`)
  }

  const confirmButton = !cancelOnly ? <a
    className={`button submit-form-on-enter-key-press ${ready ? 'is-info' : 'is-gray'}`}
    disabled={!ready}
    draggable="false"
    onClick={() => ready ? viewFile() : null}>View File</a> : true

  useEffect(() => {
    if (loading) {
      setTimeout(() => checkReady(), delayBeforeNextReadyPull)
    }
  }, [])

  const loadingIcon = <svg className="rotate" xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72">
    <path fill="#0075E0" d="M36,0 C55.882251,0 72,16.117749 72,36 C72,55.882251 55.882251,72 36,72 C16.117749,72 0,55.882251 0,36 C0,16.117749 16.117749,0 36,0 Z M36,4 C18.326888,4 4,18.326888 4,36 C4,53.673112 18.326888,68 36,68 C53.673112,68 68,53.673112 68,36 C68,18.326888 53.673112,4 36,4 Z M50,28 L58,36 L52,36 C52,44.84 44.84,52 36,52 C32.86,52 29.94,51.08 27.48,49.52 L27.48,49.52 L30.4,46.6 C32.06,47.5 33.98,48 36,48 C42.62,48 48,42.62 48,36 L48,36 L42,36 L50,28 Z M36,20 C39.14,20 42.06,20.92 44.52,22.48 L44.52,22.48 L41.6,25.4 C39.94,24.5 38.02,24 36,24 C29.38,24 24,29.38 24,36 L24,36 L30,36 L22,44 L14,36 L20,36 C20,27.16 27.16,20 36,20 Z" />
  </svg>

  const readyIcon = <svg height="72" viewBox="0 0 72 72" width="72" xmlns="http://www.w3.org/2000/svg">
    <path d="M36,0 C55.882251,0 72,16.117749 72,36 C72,55.882251 55.882251,72 36,72 C16.117749,72 0,55.882251 0,36 C0,16.117749 16.117749,0 36,0 Z M36,4 C18.326888,4 4,18.326888 4,36 C4,53.673112 18.326888,68 36,68 C53.673112,68 68,53.673112 68,36 C68,18.326888 53.673112,4 36,4 Z M53,44 L53,48 L27,48 L27,44 L53,44 Z M23,44 L23,48 L19,48 L19,44 L23,44 Z M53,34 L53,38 L27,38 L27,34 L53,34 Z M23,34 L23,38 L19,38 L19,34 L23,34 Z M53,24 L53,28 L27,28 L27,24 L53,24 Z M23,24 L23,28 L19,28 L19,24 L23,24 Z" fill="#0075E0"></path>
  </svg>
  const readySubtext = 'I understand that by viewing this file my activity will be logged.'
  const readyTitle = 'Your activity will be logged.'

  return <Modal cancelAction={() => Turbolinks.visit(`/companies/${partnerSlug}`)}
    confirmButton={confirmButton}
    deleteButton={!loading && <PreferenceSkipWarning style={{ display: 'flex', position: 'absolute', left: '40px' }} />}
    title="View File">
    <div className="content" style={{ minHeight: '280px' }}>
      <div className="columns">
        <div className="column">
          <div className="has-text-centered">
            {ready ? readyIcon : loadingIcon}
            <h2 style={{ fontSize: '26px', margin: '1rem 0 0 0' }}>{ready ? readyTitle : loadingTitle}</h2>
            <p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '12px 16px', maxWidth: '500px', marginTop: '40px' }}>
              {ready ? readySubtext : loadingSubtext}
            </p>
          </div>
        </div>
      </div>
    </div>
  </Modal>
}

export default EntityConfirmAccess
