import React, { useState } from "react"
import ReactDOM from 'react-dom'
import Turbolinks from "turbolinks"
import RequestCenterModalForm from './RequestCenterModalForm'

const RequestCenterAction = (props) => {
    const requestCenter = props.requestCenter
    const requestCenterBaseUrl = props.requestCenterBaseUrl
    const slug = props.slug
    const [requestCenterModalFormOpen, setRequestCenterModalFormOpen] = useState(false)

    const refreshCurrentPage = () => {
        Turbolinks.visit(window.location.toString(), { action: 'replace' })
    }

    const requestCenterModalForm = requestCenterModalFormOpen ? ReactDOM.createPortal(
        <RequestCenterModalForm
            {...props}
            onCancel={() => setRequestCenterModalFormOpen(false)}
            onSave={() => refreshCurrentPage()}></RequestCenterModalForm>,
        document.getElementById('modal-container')
    ) : null

    return <>
        <a className={`${!(requestCenter || slug) ? 'button is-primary' : ''}`} onClick={() => setRequestCenterModalFormOpen(true)}>
            {(requestCenter || slug) && 'Edit'}
            {!(requestCenter || slug) && 'New Request Center'}
        </a>
        {requestCenterModalForm}
    </>
}

export default RequestCenterAction