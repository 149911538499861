import React, { useState } from "react"


const MoreDetails = ({
    company
}) => {
  const [details, setDetails] = useState(false)

  const loadMoreDetails = async () => {
    const response = await fetch(`/admin/companies/${company.slug}/more_details`)
    const responseJson = await response.json()
    setDetails(responseJson)
  }

  return <>
    <a onClick={() => loadMoreDetails()}>more details</a>
    {details && <><br /><ul>
      <li>Automated shares: {details.automated_shares_count}</li>
      <li>Manual shares: {details.manual_shares_count}</li>
    </ul></>}
  </>
}

export default MoreDetails
