import React from "react"
import utils from "../../utils/utils"

import Modal from "../../Modal"

const RequestFormDeleteForm = ({
  onCancel = () => { },
  requestForm
}) => {
  const deleteRequestForm = () => {
    fetch(`/request_forms/${requestForm.slug}`, {
      method: "DELETE",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    })
      .then(() => {
        Turbolinks.visit('/request_forms')
      })
  }

  return <Modal
    cancelButton={false}
    cancelAction={() => onCancel()}
    confirmButton={
      <a
        className="button is-danger"
        draggable="false"
        onClick={() => deleteRequestForm()}>
        Delete
      </a>
    }
    title="Delete Request Form"
  >
    <div className="columns">
      <div className="column">
        Are you sure?
      </div>
    </div>
  </Modal >
}

export default RequestFormDeleteForm
