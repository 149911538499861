import React from "react"

const GetStartedForFree = ({children}) => {
  return (
        <p 
            className="has-text-centered"
            style={{
                backgroundColor: '#f4f5f5',
                borderRadius: '16px',
                margin: '30px auto 0 auto',
                padding: '20px 60px',
                maxWidth: '552px'
            }}>
                <b>Get Started for Free</b>
                <br />
                Create an account to start sharing your company's documents. The first 10 shares are free!
                {children && <><br />{children}</>}
        </p>
  )
}

export default GetStartedForFree
