import React, { useState } from "react"
import { useForm } from 'react-hook-form'

import utils from '../utils/utils'

import GroupDeleteModal from "./GroupDeleteModal"
import Modal from "../Modal"
import { MultiSelect } from "react-multi-select-component"
import TextField from "../inputs/TextField"

const GroupFrom = ({
  group = {},
  linkedDocumentResources,
  linkedPackages,
  onCancel,
  selectedUserIds: initialSelectedUserIds,
  users
}) => {
  const { handleSubmit, register, errors, formState } = useForm({
    defaultValues: {
      'group[name]': group ? group.name : null
    },
    mode: "onChange"
  })

  const selectedUserOptions = users.filter(option => initialSelectedUserIds.indexOf(option.value) > -1)
  const [selectedUserIds, setSelectedUserIds] = useState(selectedUserOptions)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const confirmButton = <input className={`button is-info ${formState.isValid ? '' : 'disabled'}`}
    draggable="false"
    type="submit"
    value='Save Changes' />

  const submitForm = () => {
    const form = document.querySelector("#group-form")
    form.setAttribute("method", "post")
    form.submit()
  }

  const deleteButton = group.id ? <a className="button is-danger is-inverted is-pulled-left" draggable="false" style={{ position: 'absolute', left: '40px' }} onClick={() => setShowDeleteModal(true)}>Delete</a> : false

  const deleteGroup = showDeleteModal && <GroupDeleteModal
    cancelButton={linkedDocumentResources.length > 0 || linkedPackages.length > 0}
    cancelAction={() => {
      setShowDeleteModal(false)
      onCancel()
    }}
    group={group}
    linkedDocumentResources={linkedDocumentResources}
    linkedPackages={linkedPackages}
    title={"Delete Group"}
    users={users}>
    {!linkedDocumentResources.length > 0 && !linkedPackages.length > 0 && <p>Are you sure you want to delete this group?</p>}
    {linkedDocumentResources.length > 0 && <><p>
      This group can not be deleted at this time because the following documents are currently linked to it:
    </p><br /><ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
        {linkedDocumentResources.map((linkedDocumentResource, index) => <li key={`document-${index}`}>{linkedDocumentResource}</li>)}
      </ul></>}
    {linkedPackages.length > 0 && <><p>
      This group can not be deleted at this time because the following packages are currently linked to it:
    </p><br /><ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
        {linkedPackages.map((linkedPackage, index) => <li key={`package-${index}`}>{linkedPackage}</li>)}
      </ul></>}
  </GroupDeleteModal>

  const CustomClearIcon = () => <div></div>

  return !showDeleteModal ? <form id="group-form" action={`/settings/users_and_groups/${group.id ? `${group.id}/` : ''}${group.id ? 'update' : 'create'}_group`} onSubmit={handleSubmit(submitForm)}>
    {group.id && <input type="hidden" name="_method" value="patch" />}
    <input type="hidden" name="authenticity_token" value={utils.csrfToken()} />

    <Modal
      cancelAction={onCancel}
      confirmButton={confirmButton}
      deleteButton={deleteButton}
      modalCardStyle={{ overflow: 'initial' }}
      modalCardBodyStyle={{ overflow: 'initial' }}
      title={group.id ? 'Edit Details' : 'New Group'}>

      <div className="columns">
        <div className="column">
          <TextField
            errors={errors}
            label="Name"
            name="group[name]"
            placeholder="E.g. Sales"
            reff={register({ required: true })} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <input name="group[user_ids][]" key="0" type="hidden" value="" readOnly />

          {selectedUserIds.map(selectedUserId => {
            return <input name="group[user_ids][]"
              type="hidden"
              readOnly
              key={selectedUserId.value}
              value={selectedUserId.value} />
          })}
          <label className="label">Users:</label>
          <MultiSelect
            ClearSelectedIcon={<CustomClearIcon />}
            className="agreement-dropdown"
            valueRenderer={(selected) => (
              selected.length ? selected.map(({ label }, index) => `${selected.length > 1 && index != 0 ? index == (selected.length - 1) ? ' & ' : ', ' : ''}${label}`) : `Select a user...`
            )}
            options={users}
            value={selectedUserIds}
            onChange={setSelectedUserIds}
            hasSelectAll={false}
            labelledBy="Select Users" />
        </div>
      </div>

    </Modal>
  </form> : deleteGroup
}

export default GroupFrom
