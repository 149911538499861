import React, { useMemo } from 'react'
import Envelope from './Envelope'

const Envelopes = ({
  companyName,
  currentCompany,
  currentUserId,
  emailDomain,
  envelopes,
  envelopeType,
  isNonLoggedInView,
  tosAccepted,
  partnerName,
  slug,
}) => {
  const envelopeList = useMemo(() => {
    const sortedEnvelopes = envelopes.sort((envelope_a, envelope_b) => {
      const dateA = new Date(envelope_a.created_at)
      const dateB = new Date(envelope_b.created_at)
      return dateB - dateA
    })

    const list = []
    for (const [index, envelope] of sortedEnvelopes.entries()) {
      list.push(<Envelope agreements={envelope.agreements}
        companyId={envelope.company_id}
        companyName={companyName}
        currentCompany={currentCompany}
        currentUserId={currentUserId}
        createdAt={envelope.created_at}
        emailDomain={emailDomain}
        envelopeType={envelopeType}
        documents={envelope.documents}
        key={index}
        partnerId={envelope.partner_id}
        partnerName={partnerName}
        slug={slug}
        tosAccepted={tosAccepted} />)
    }
    return list
  }, [envelopes])

  const emptyContent = useMemo(() => (
    isNonLoggedInView ? <p style={{ fontSize: '22px', marginTop: '240px' }} className="has-text-centered">
      Looks empty in there. If you're not seeing what you expect,
      <br />
      ask {partnerName} to send the files again.
    </p> : <p style={{ fontSize: '22px' }} className="has-text-centered">
      <br />
      There are no files shared with {partnerName}.
      <br />
      Click Send Envelope to share your first document or agreement.
      <br />
      <br />
    </p>
  ), [isNonLoggedInView])

  return <div className="content has-no-border-bottom">
    <div className="columns">
      <div className={`column ${envelopeList.length == 0 && !isNonLoggedInView ? 'has-border-top' : ''}`}>
        {envelopeList.length > 0 ? envelopeList : emptyContent}
      </div>
    </div>
  </div>
}

export default Envelopes
