import React from "react"
import Toggle from "../../inputs/Toggle"
import settingsUtils from '../settingsUtils'

const TogglePreference = ({ 
  url,
  method,
  initialValue,
  modelName,
  fieldName,
  label,
  options,
  subtitle,
}) => {
  return <Toggle 
      label={label}
      initialValue={initialValue}
      onChange={(value) => settingsUtils.saveValue(fieldName, method, modelName, url, value)}
      options={options}
      subtitle={subtitle} />
}

export default TogglePreference