import React from "react"

function ActionSelector(props) {
  const onChange = props.onChange
  const innerStyle = props.innerStyle

  return (<div style={innerStyle} className="action-selector">
    <div className="is-relative">
      <input type="radio" id="sign_agreement" name="action" value="sign_agreement" onChange={() => onChange('sign_agreement')} />
      <label className="button" htmlFor="sign_agreement"><strong>I'm authorized to sign</strong><br />Next, you will be prompted to sign the agreement.</label>
    </div>
    <div className="is-relative">
      <input type="radio" id="transfer_signature" name="action" value="transfer_signature" onChange={() => onChange('transfer_signature')} />
      <label className="button" htmlFor="transfer_signature"><strong>I need someone else to sign</strong><br />Next, you will be able to transfer this agreement to an authorized signer.</label>
    </div>
  </div>)
}

export default ActionSelector
