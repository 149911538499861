import React, { useMemo } from "react";
import Entity from './Entity'

import utils from 'components/utils/utils'

import SignAgreementButton from './../SignAgreement/SignAgreementButton'

const Agreement = ({
  agreement,
  agreementIsInternal,
  agreementIsToBeSigned,
  agreementType,
  available,
  companyName,
  currentUserId,
  emailDomain,
  partnerName,
  requester,
  requestSlug,
  tosAccepted,
}) => {
  const shouldSignAgreement = useMemo(() => agreementIsToBeSigned && !agreement.signed && currentUserId === agreement.signed_by.id, [agreementIsToBeSigned, agreement, currentUserId])

  const subTitle = useMemo(() => {
    if (requester) {
      return `Requested by ${requester.first_name} ${requester.last_name}`
    } else {
      if (agreement.acted_by) {
        return agreement.signed_by ? `From ${agreement.acted_by.first_name} ${agreement.acted_by.last_name}` : `Uploaded by ${agreement.acted_by.first_name} ${agreement.acted_by.last_name}`
      } else {
        return 'Automatically Sent'
      }
    }
  }, [agreement, requester])

  return (<div className="columns">
    <div className="column">
      <Entity entity={agreement}
        previewUrl={utils.usePreviewCDN(`/${agreementType}s/${agreement.slug}/preview?resize=60x80`, agreement)}
        subtitle={subTitle}
        url={`/agreements/${agreement.slug}${!agreementIsInternal ? '/view' : ''}${requestSlug ? `?request=${requestSlug}` : ''}`} />
    </div>

    {!requester && shouldSignAgreement &&
      <SignAgreementButton agreementSlug={agreement.slug}
        companyName={companyName}
        emailDomain={emailDomain}
        partnerName={partnerName}
        tosAccepted={tosAccepted} />
    }
    {!requester && !shouldSignAgreement &&
      <div className="column" style={{ position: 'relative' }}>
        <p className="has-text-right" style={{ fontSize: '14px', color: '#8b9297', position: 'absolute', top: '50%', right: '0.75rem', marginTop: '-21px' }}>
          {agreement.signed_by && (agreement.signed ? 'Signed by' : 'Pending Signature')}
          <br />
          {agreement.signed_by && `${agreement.signed_by.first_name} ${agreement.signed_by.last_name}${currentUserId === agreement.signed_by.id ? ' (You)' : ''}`}
        </p>
      </div>
    }
  </div>)
}

export default Agreement
