import React, { useEffect, useState } from "react"

const PackageList = ({
  disabledEmptyPackages = false,
  packages,
  onChange = (packageIds) => { },
  selectedPackages: initialSelectedPackages
}) => {
  const [selectedPackages, setSelectedPackages] = useState(initialSelectedPackages || [])

  useEffect(() => {
    onChange(selectedPackages)
  }, [selectedPackages])

  return <div className="action-selector" >
    {
      packages.map(packageData => (
        <div
          className="is-relative"
          key={`package-${packageData.id}`}>
          <input
            checked={selectedPackages.includes(packageData.id)}
            disabled={disabledEmptyPackages && packageData.agreement_resources.length == 0 && packageData.document_resources.length == 0}
            id={`package-${packageData.id}`}
            name={`package-${packageData.id}`}
            onChange={e => setSelectedPackages(e.target.checked ? [...selectedPackages, packageData.id] : selectedPackages.filter(id => packageData.id !== id))}
            style={{ left: '35px', top: '37px' }}
            type="checkbox" />
          <label
            className="button"
            htmlFor={`package-${packageData.id}`}
            style={{
              borderRadius: '8px',
              borderWidth: selectedPackages.includes(packageData.id) ? '2px' : '1px',
              borderColor: selectedPackages.includes(packageData.id) ? '#0075e0' : '#ced0d2',
              height: '88px',
              paddingLeft: selectedPackages.includes(packageData.id) ? "74px" : "75px",
              paddingTop: 0,
              marginBottom: '16px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
            <strong style={{ opacity: `${disabledEmptyPackages && packageData.agreement_resources.length == 0 && packageData.document_resources.length == 0 ? 0.5 : 1}` }}> {packageData.name}</strong>
            {packageData.description && <small
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                inlineSize: '480px',
                textOverflow: 'ellipsis',
                maxWidth: '70%',
                opacity: `${disabledEmptyPackages && packageData.agreement_resources.length == 0 && packageData.document_resources.length == 0 ? 0.5 : 1}`,
                overflow: 'hidden',
              }}>
              {packageData.description}
            </small>}
            {disabledEmptyPackages && packageData.agreement_resources.length == 0 && packageData.document_resources.length == 0
              && <span style={{ fontSize: '14px', fontWeight: 'normal' }}>Package is empty and cannot be selected</span>}
            <a
              href={`/packages/${packageData.slug}`}
              style={{ position: 'absolute', right: selectedPackages.includes(packageData.id) ? '19px' : '20px', top: 'calc(50% - 9px)' }}
              target="_blank">
              View Package
            </a>
          </label>
        </div>
      ))
    }
  </div >
}

export default PackageList
