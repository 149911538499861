import React from "react"
import settingsUtils from '../Settings/settingsUtils'

const PreferenceSkipWarning = ({
  style = {}
}) => {
  const fieldName = 'preference_skip_warning'
  const method = 'PATCH'
  const modelName = 'user'
  const url = "/settings/companies/update_current_user"

  return <div style={style}>
    <input
      type="checkbox"
      id="user_preference_skip_warning"
      name="user_preference_skip_warning"
      onChange={(e) => settingsUtils.saveValue(fieldName, method, modelName, url, e.target.checked)} />
    <label style={{ paddingLeft: '5px', fontSize: '0.9rem' }} htmlFor="user_preference_skip_warning">
      Do not show this again.
    </label>
  </div>
}

export default PreferenceSkipWarning
