import React, { useEffect, useMemo, useState } from "react"
import ReactDOM from 'react-dom'

import Modal from '../Modal'
import Toggle from "../inputs/Toggle"
import utils from '../utils/utils'

const UserNotification = ({
  currentUserId,
  isAdmin,
  partnerName,
  partnerSlug
}) => {
  const url = '/user_notifications'
  const notificationType = 'envelope_view'
  const notificationParams = {
    company_slug: partnerSlug,
    notification_type: notificationType,
  }

  const [loaded, setLoaded] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [showModal, setShowModal] = useState(false)

  const userNotificationParams = (method, data) => {
    const params = {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }

    if (data) {
      params['body'] = JSON.stringify({ user_notification: data })
    }

    return params
  }

  const onUserNotificationChange = (createUserNotification, userId, userNotificationId) => {
    if (createUserNotification) {
      fetch(`${url}?${new URLSearchParams(notificationParams)}`, userNotificationParams('POST', { ...notificationParams, user_id: userId }))
        .then(res => {
          if (!res.ok) {
            throw new Error("HTTP status " + res.status);
          }
          return res.json()
        })
        .then(notifications => setNotifications(notifications))
        .catch(e => console.error(e))
    } else {
      fetch(`${url}/${userNotificationId}?${new URLSearchParams(notificationParams)}`, userNotificationParams('DELETE'))
        .then(res => {
          if (!res.ok) {
            throw new Error("HTTP status " + res.status);
          }
          return res.json()
        })
        .then(notifications => setNotifications(notifications))
        .catch(e => console.error(e))
    }
  }

  useEffect(() => {
    fetch(`${url}?${new URLSearchParams(notificationParams)}`)
      .then(async (data) => {
        if (data.ok) {
          const notifications = await data.json()
          setNotifications(notifications)
        }
        setLoaded(true)
      }).catch(e => console.error(e))
  }, [])

  const notificationList = useMemo(() => {
    const currentUserNotificationIndex = notifications.findIndex(notification => notification.user_id === currentUserId)
    const currentUserNotification = notifications[currentUserNotificationIndex]
    notifications.splice(currentUserNotificationIndex, 1)
    notifications.sort((a, b) => {
      if (a.user_name < b.user_name) {
        return -1
      }
      if (a.user_name > b.user_name) {
        return 1
      }
      return 0
    })
    return [currentUserNotification, ...notifications]
  }, [currentUserId, notifications])

  const currentUserNotificationId = useMemo(() => {
    return notifications.find(notification => notification.user_id === currentUserId)?.user_notification_id
  }, [currentUserId, notifications])

  const content = useMemo(() => {
    if (isAdmin) {
      return <>
        <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center mb-2">
          <div style={{ fontWeigth: '400px' }}>
            <div style={{ fontSize: '14px' }}>Get email notifications for all new activity from {partnerName}</div>
            <div style={{ color: '#8C9296', fontSize: '12px' }}>Your are {!!currentUserNotificationId ? '' : 'not '} receiving email notifications</div>
          </div>
          <div>
            <a style={{ fontWeigth: '600px' }} onClick={() => setShowModal(true)}>Manage</a>
          </div>
        </div>
      </>
    } else {
      return <Toggle
        label={`Get email notifications for all new activity from ${partnerName}`}
        initialValue={!!currentUserNotificationId}
        onChange={(value) => onUserNotificationChange(value, currentUserId, currentUserNotificationId)}
        options={[{ label: 'On', value: true }, { label: 'Off', value: false }]} />
    }
  }, [currentUserId, currentUserNotificationId, isAdmin, loaded, notifications, partnerName, showModal])

  const modal = useMemo(() => {
    if (showModal) {
      return ReactDOM.createPortal(<Modal
        cancelAction={() => setShowModal(false)}
        cancelButton={<></>}
        confirmAction={() => setShowModal(false)}
        confirmButtonText="Close"
        title="Manage Email Notifications" >
        <div className="is-flex is-flex-direction-row is-justify-content-space-between has-text-weight-bold mb-2">
          <div>Users</div>
          <div>Notification</div>
        </div>
        <div className="separator"></div>
        {notificationList.map(notification => {
          return <div key={notification.user_id}>
            <Toggle
              initialValue={!!notification.user_notification_id}
              label={<div className="is-flex is-flex-direction-row mt-2">
                <div style={{ opacity: '0.5' }}>
                  <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,5 C13.66,5 15,6.34 15,8 C15,9.66 13.66,11 12,11 C10.34,11 9,9.66 9,8 C9,6.34 10.34,5 12,5 Z M12,19.2 C9.5,19.2 7.29,17.92 6,15.98 C6.03,13.99 10,12.9 12,12.9 C13.99,12.9 17.97,13.99 18,15.98 C16.71,17.92 14.5,19.2 12,19.2 Z" fill="#555" fillRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-2">{notification.user_name}{notification.user_id === currentUserId ? ' (You)' : ''}</div>
              </div>}
              onChange={(value) => onUserNotificationChange(value, notification.user_id, notification.user_notification_id)}
              options={[{ label: 'On', value: true }, { label: 'Off', value: false }]}
              size="small" />
            <div className="mb-2"></div>
          </div>
        })}
      </Modal>, document.getElementById('modal-container'))
    }
  }, [notifications, showModal])

  return <>
    {!loaded && <div style={{ overflow: 'hidden', marginBottom: '8px', position: 'relative', width: '100%' }}><div className="message loading-row" style={{ height: '39px' }}></div></div>}
    {loaded && <>{content}{modal}</>}
  </>
}

export default UserNotification
