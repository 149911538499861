import React, { useState } from "react"

import EmptyState from 'components/TrustCenters/TrustCenterEdit/EmptyState'

import TrustedReviewerStep from '../../../TrustCenterEdit/TrustedReviewerStep'
import TrustedReviewer from './TrustedReviewer'

import styles from "./style.module"

const TrustedReviewers = ({
  edit,
  trustCenter,
}) => {
  const [trustedReviewers, setTrustedReviewers] = useState(trustCenter.trustedReviewers)
  const [editTrustedReviewers, setEditTrustedReviewers] = useState(false)

  return (trustedReviewers.length > 0 || edit) && <div className={styles.TrustedReviewers}>
    {editTrustedReviewers && <TrustedReviewerStep
      onPanelClose={() => setEditTrustedReviewers(false)}
      onTrustCenterChange={trustCenter => setTrustedReviewers(trustCenter.trustedReviewers)}
      trustCenter={trustCenter} />}
    {edit && <a onClick={() => setEditTrustedReviewers(true)} className="is-pulled-right has-text-weight-bold">Edit</a>}
    <h2 className={styles.h2}>{trustCenter.title} is trusted and reviewed by</h2>
    {trustedReviewers.length > 0 && <div className={`is-flex is-flex-direction-row is-flex-wrap-wrap is-justify-content-center ${styles.TrustedReviewerWrapper}`}>
      {trustedReviewers.map(trustedReviewer => <TrustedReviewer key={trustedReviewer.slug} trustedReviewer={trustedReviewer} />)}
    </div>}
    {trustedReviewers.length == 0 && <EmptyState message="Select companies that have reviewed your security policies." />}
  </div >
}

export default TrustedReviewers
