import React from "react"

import Modal from "./../Modal"

import utils from '../utils/utils'

const GroupDeleteModal = (props) => {
  const cancelAction = props.cancelAction
  const group = props.group
  const linkedDocumentResources = props.linkedDocumentResources
  const linkedPackages = props.linkedPackages

  const confirmAction = () => {
    if (linkedDocumentResources.length > 0 || linkedPackages.length > 0) {
      cancelAction()
      return
    }

    fetch(`/settings/users_and_groups/${group.id}/delete_group`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }).then(() => Turbolinks.visit(window.location.pathname.toString()))
  }

  return <Modal
    {...props}
    confirmAction={confirmAction}
    confirmButtonText={(linkedDocumentResources.length > 0 || linkedPackages.length > 0) ? 'Close' : 'Delete'} />
}

export default GroupDeleteModal
