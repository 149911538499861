import React from "react"

import BaseToggleStep from "../BaseToggleStep"

const SubprocessorStep = ({
  trustCenter,
  onPanelClose = () => { },
  onTrustCenterChange = () => { }
}) => {
  return <BaseToggleStep
    labelAttr="name"
    onPanelClose={() => onPanelClose()}
    onTrustCenterChange={trustCenter => onTrustCenterChange(trustCenter)}
    paramSetUrl="/subprocessors.json"
    trustCenter={trustCenter}
    trustCenterAttr="subprocessors"
    trustCenterParam="subprocessor_ids"
    title="Sub-processors">
    <div className="separator mt-2"></div>
    <p><small>If you don’t see a specific subprocessor, please <a href="https://forms.gle/Pzycn83VsB7wbcot5" target="_blank">make a request</a>.</small></p>
  </BaseToggleStep>
}

export default SubprocessorStep
