import React from "react"
import ReactDOM from 'react-dom'
import { useForm } from 'react-hook-form'

import utils from '../../utils/utils'

import Modal from "../../Modal"
import TextField from "../../inputs/TextField"

const LabelModalForm = (props) => {
  const { deleteAction, label, onSave } = props

  const formOptions = {
    defaultValues: {
      name: label?.name
    },
    mode: "onChange"
  }

  const { register, handleSubmit, errors, formState } = useForm(formOptions)

  const submitLabel = (data) => {
    fetch(`/settings/labels/${label ? label.id : ''}`, {
      method: label?.id ? 'PUT' : 'POST',
      body: JSON.stringify({ label: data }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    })
    .then(res => res.json())
    .then(label => onSave(label))
  }

  const confirmButton = <input 
    className={`button is-info ${formState.isValid ? '' : 'disabled'}`}
    draggable="false"
    type="submit"
    value={label?.id ? 'Save' : 'Create'} />

  return ReactDOM.createPortal(
    <form onSubmit={handleSubmit(submitLabel)}>
      <Modal
        {...props}
        confirmButton={confirmButton}
        deleteAction={deleteAction}
        deleteButtonText="Delete">
          <div className="columns">
            <div className="column">
            <TextField
              errors={errors}
              label="Label Name"
              name="name"
              placeholder={`E.g., Policies, Reports`}
              reff={register({ required: true })} />
            </div>
          </div>
      </Modal>
    </form>,
    document.getElementById('modal-container')
  )
}

export default LabelModalForm