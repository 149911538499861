import React, { useState } from "react"
import utils from '../utils/utils'

const DetailsRow = (props) => {
  const partnerSlug = props.partnerSlug
  const reminderType = props.reminderType
  const user = props.user

  const [sendingStatus, setSendingStatus] = useState('unsent')

  const resendEnvelope = (e) => {
    e.preventDefault()
    setSendingStatus('sending')

    utils.sendAccessPass(user.id, undefined, partnerSlug, reminderType).then(json => {
      if (json.success) {
        setSendingStatus('sent')
      }
    })
  }

  return <li style={{ borderBottom: 'solid 1px silver', marginTop: '0px', padding: '11px' }}>
    <div className="columns">
      <div className="column" style={{ alignItems: 'center', display: 'flex', paddingLeft: 0 }}>
        <p style={{ fontSize: '14px' }}>
          <strong style={{ fontSize: '16px' }}>{user.first_name} {user.last_name}</strong>
          <br />
          {user.email}
        </p>
      </div>
      <div className="column" style={{ alignItems: 'center', display: 'flex', fontSize: '14px', flex: 'none', width: '105px' }}>
        {sendingStatus == 'unsent' && <p style={{ textAlign: 'right', width: '100%' }}><a onClick={(e) => resendEnvelope(e)} style={{ zIndex: 1000 }}>Resend Link</a></p>}
        {sendingStatus == 'sending' && <p style={{ textAlign: 'right', width: '100%' }}>Sending...</p>}
        {sendingStatus == 'sent' && <p style={{ textAlign: 'right', width: '100%' }}>Sent!</p>}
      </div>
    </div>
  </li>
}

export default DetailsRow
