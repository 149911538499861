import React, { useState } from "react"
import ReactDOM from 'react-dom'

import Modal from '../Modal'
import utils from '../utils/utils'

import StripeCreditCardForm from './StripeCreditCardForm'

const EditPaymentMethodBtn = ({
    expended = false,
    stripeCreditCard
}) => {
    const stripFormID = 'billing-details-edit-form'

    const [showCreditCardForm, setShowCreditCardForm] = useState(expended)
    const [showDeleteCreditCardModal, setShowDeleteCreditCardModal] = useState(false)
    const [stripeCreditCardFormValid, setStripeCreditCardFormValid] = useState(false)

    const refreshCurrentPage = () => {
        Turbolinks.visit(window.location.toString(), { action: 'replace' })
    }

    const deleteCreditCard = () => {
        fetch('/settings/billing/remove_card', {
            headers: { 'X-CSRF-Token': utils.csrfToken() }, method: 'DELETE'
        }).then((response) => {
            refreshCurrentPage()
        })
    }

    const deleteModal = showDeleteCreditCardModal && ReactDOM.createPortal(
        <Modal
            cancelAction={() => setShowDeleteCreditCardModal(false)}
            confirmAction={() => deleteCreditCard()}
            confirmButtonText="Remove"
            title="Remove Payment Info">
            <p>Are you sure you want to remove this payment info?</p>
        </Modal>,
        document.getElementById('modal-container'))

    return <div className="columns">
        <div className="column is-half">
            <p className="add-cc-info">Add and manage payment info for your Pima plan. </p>

            {!showCreditCardForm && <React.Fragment>
                {stripeCreditCard && <div className="add-cc-info">
                    <label className="label">Payment Info:</label>
                    <div className="input">
                        <svg height="24" viewBox="0 0 24 24" width="24">
                            <path d="M20,4 L4,4 C2.89,4 2.01,4.89 2.01,6 L2,18 C2,19.11 2.89,20 4,20 L20,20 C21.11,20 22,19.11 22,18 L22,6 C22,4.89 21.11,4 20,4 Z M20,18 L4,18 L4,12 L20,12 L20,18 Z M20,8 L4,8 L4,6 L20,6 L20,8 Z" fill="#424C53" fillRule="evenodd" />
                        </svg>
                        <span style={{ width: '8px' }}></span>
                        {stripeCreditCard['ach_credit_transfer'] && `ACH Credit Transfer with ${stripeCreditCard['ach_credit_transfer']['bank_name']}`}
                        {stripeCreditCard['brand'] && `${stripeCreditCard['brand']} ending with ${stripeCreditCard['last4']}`}
                    </div>
                    <br />
                    <br />
                </div>}
                <a className="button is-primary" onClick={() => setShowCreditCardForm(true)}>
                    {stripeCreditCard ? 'Edit' : 'Add'} Payment Info
                </a>
            </React.Fragment>}

            {showCreditCardForm && <React.Fragment>
                <StripeCreditCardForm
                    defaultValues={stripeCreditCard}
                    id={stripFormID}
                    onFormChange={(valid) => setStripeCreditCardFormValid(valid)}
                    onCreditCardUpdated={() => refreshCurrentPage()} />
                <br />
                {stripeCreditCard && <a className="button is-danger is-inverted"
                    onClick={() => setShowDeleteCreditCardModal(true)}>
                    Remove Payment Info
                </a>}
                <button className={`button is-primary is-pulled-right ${stripeCreditCardFormValid ? '' : 'disabled'}`}
                    form={stripFormID}>
                    Save Payment Info
                </button>
            </React.Fragment>}
        </div>
        {deleteModal}
    </div>
}

export default EditPaymentMethodBtn
