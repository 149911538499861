import React from "react"

import styles from "./style.module"

import ContactUs from './ContactUs'
import FAQs from './FAQs'
import Footer from "./Footer"
import TrustCenterContent from './TrustCenterContent'

const TrustCenter = ({
  companySlug,
  edit = false,
  isOwnTrustCenter = false,
  trustCenter,
}) => {

  return <div className={styles.trustCenter}>
    <TrustCenterContent
      companySlug={companySlug}
      edit={edit}
      isOwnTrustCenter={isOwnTrustCenter}
      trustCenter={trustCenter} />
    <FAQs edit={edit} trustCenter={trustCenter} />
    <ContactUs edit={edit} trustCenter={trustCenter} />
    <Footer />
  </div>
}

export default TrustCenter
