import React from "react"

const LoginShell = ({children}) => {
    return (   
        <div className="content is-onboarding-flow" style={{ minWidth: '601px' }}>
            <div className="columns">
                <div className="column has-text-centered">
                    <svg height="30px" version="1.1" viewBox="0 0 80 25" width="100px">
                    <g fill="none" fillRule="evenodd" id="Logos/pima-full-color-logo-dark-tiny" stroke="none" strokeWidth="1">
                        <g id="Logos-/-Pima---Henge-Gray-Aqua">
                        <circle cx="76.137931" cy="20.8965517" fill="#2BC9B4" id="Period" r="3.10344828"></circle>
                        <path d="M63.9103448,8.06896552 C66.3103568,8.06896552 68.084477,8.62240826 69.2327586,9.72931034 C70.3810402,10.8362124 70.9551724,12.558609 70.9551724,14.8965517 L70.9551724,23.7103448 L65.7724138,23.7103448 L65.7724138,21.5689655 C65.4827572,22.3137968 64.9965551,22.8982737 64.3137931,23.3224138 C63.6310311,23.7465538 62.8137979,23.9586207 61.862069,23.9586207 C60.8068913,23.9586207 59.8551767,23.7465538 59.0068966,23.3224138 C58.1586164,22.8982737 57.4913817,22.3086245 57.0051724,21.5534483 C56.5189631,20.7982721 56.2758621,19.9448323 56.2758621,18.9931034 C56.2758621,17.8965462 56.560342,17.0224171 57.1293103,16.3706897 C57.6982787,15.7189623 58.6034421,15.2482773 59.8448276,14.9586207 C61.0862131,14.6689641 62.7620584,14.5241379 64.8724138,14.5241379 L65.7103448,14.5241379 L65.7103448,14.1517241 C65.7103448,13.3862031 65.5344845,12.8534498 65.1827586,12.5534483 C64.8310327,12.2534468 64.2103493,12.1034483 63.3206897,12.1034483 C62.6172379,12.1034483 61.805177,12.2431021 60.8844828,12.5224138 C59.9637885,12.8017255 59.100004,13.1586185 58.2931034,13.5931034 L56.8965517,9.96206897 C57.7862113,9.42413524 58.8982692,8.97413974 60.2327586,8.61206897 C61.5672481,8.24999819 62.7930979,8.06896552 63.9103448,8.06896552 Z M65.7103448,17.0689655 L65.2137931,17.0689655 C63.8896486,17.0689655 62.9482787,17.1879298 62.3896552,17.4258621 C61.8310317,17.6637943 61.5517241,18.0620662 61.5517241,18.6206897 C61.5517241,19.0965541 61.7017226,19.4896536 62.0017241,19.8 C62.3017256,20.1103464 62.6896528,20.2655172 63.1655172,20.2655172 C63.9103486,20.2655172 64.5206873,20.0017268 64.9965517,19.4741379 C65.4724162,18.9465491 65.7103448,18.2689697 65.7103448,17.4413793 L65.7103448,17.0689655 Z" fill="#424C53" id="A"></path>
                        <path d="M48.8689655,8.06896552 C50.6482848,8.06896552 51.9672371,8.60689117 52.8258621,9.68275862 C53.6844871,10.7586261 54.1137931,12.4137819 54.1137931,14.6482759 L54.1137931,23.7103448 L48.5896552,23.7103448 L48.5896552,14.8034483 C48.5896552,13.9137887 48.4551738,13.2724158 48.1862069,12.8793103 C47.91724,12.4862049 47.4724169,12.2896552 46.8517241,12.2896552 C46.0862031,12.2896552 45.5017262,12.558618 45.0982759,13.0965517 C44.6948256,13.6344854 44.4931034,14.399995 44.4931034,15.3931034 L44.4931034,23.7103448 L38.9689655,23.7103448 L38.9689655,14.8034483 C38.9689655,13.9137887 38.8344841,13.2724158 38.5655172,12.8793103 C38.2965504,12.4862049 37.8517272,12.2896552 37.2310345,12.2896552 C36.4655134,12.2896552 35.8862089,12.558618 35.4931034,13.0965517 C35.099998,13.6344854 34.9034483,14.399995 34.9034483,15.3931034 L34.9034483,23.7103448 L29.3793103,23.7103448 L29.3793103,8.44137931 L34.7482759,8.44137931 L34.7482759,10.3655172 C35.762074,8.8344751 37.3137827,8.06896552 39.4034483,8.06896552 C41.5965627,8.06896552 43.0758582,8.96895652 43.8413793,10.7689655 C44.3793103,9.92068541 45.0879266,9.25862307 45.9672414,8.78275862 C46.8465561,8.30689417 47.8137878,8.06896552 48.8689655,8.06896552 Z" fill="#424C53" id="M"></path>
                        <polygon fill="#424C53" id="I" points="20.6896552 23.7517241 20.6896552 8.48275862 26.2137931 8.48275862 26.2137931 23.7517241"></polygon>
                        <circle cx="23.3793103" cy="3.10344828" fill="#424C53" id="Dot" r="3.10344828"></circle>
                        <path d="M10.5827586,1.86206897 C13.0034604,1.86206897 14.8862002,2.47758005 16.2310345,3.70862069 C17.5758688,4.93966133 18.2482759,6.65171317 18.2482759,8.84482759 C18.2482759,11.0172522 17.5706964,12.7241317 16.2155172,13.9655172 C14.8603381,15.2069028 12.9827706,15.8275862 10.5827586,15.8275862 L5.71034483,15.8275862 L5.71034483,23.7413793 L9.9475983e-14,23.7413793 L9.9475983e-14,1.86206897 L10.5827586,1.86206897 Z M9.80689655,6.17586207 L5.71034483,6.17586207 L5.71034483,11.5448276 L9.80689655,11.5448276 C10.8620742,11.5448276 11.6431009,11.3275884 12.15,10.8931034 C12.6568991,10.4586185 12.9103448,9.78621145 12.9103448,8.87586207 C12.9103448,7.96551269 12.6568991,7.28793326 12.15,6.84310345 C11.6431009,6.39827364 10.8620742,6.17586207 9.80689655,6.17586207 Z" fill="#424C53" id="P"></path>
                        </g>
                    </g>
                    </svg>
                    {children}
                </div>
            </div>
            <svg height="176" viewBox="0 0 600 176" width="600" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <rect height="176" id="sign-in-footer-a" width="600" x="0" y="0"></rect>
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask fill="#fff" id="sign-in-footer-b">
                <use xlinkHref="#sign-in-footer-a"></use>
                </mask>
                <g mask="url(#sign-in-footer-b)" opacity=".073">
                <path
                    d="M411.710037,655.527027 C575.633458,655.527027 656,450.737928 656,340.31705 C656,190.537687 627.685782,0 446.536385,0 C231.208486,1.61714534 1.28851812,158.199724 0,340.31705 C0,490.096413 247.786616,655.527027 411.710037,655.527027 Z"
                    fill="#18242D"
                    transform="matrix(-1 0 0 1 487 0)">
                </path>
                </g>
                <g mask="url(#sign-in-footer-b)" opacity=".288">
                <path
                    d="M226.566042,361.225225 C316.7739,361.225225 361,248.377112 361,187.530183 C361,104.994937 345.418548,0 245.731151,0 C127.235158,0.891120677 0.709077808,87.1752478 0,187.530183 C0,270.065428 136.358184,361.225225 226.566042,361.225225 Z"
                    fill="#2BC9B4"
                    transform="translate(337 39)">
                </path>
                </g>
            </g>
            </svg>
        </div>
    )
}

export default LoginShell
