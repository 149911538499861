import React, { useEffect, useState } from "react"

import LabelList from "../../LabelList"

const LabelFilter = ({ 
  onFilterUpdate = (selectedLabelIds) => {},
  labels,
  listName,
}) => {
  const [selectedLabelIds, setSelectedLabelIds] = useState(() => {
    const storedValue = localStorage.getItem(listName)
    const labelIds = JSON.parse(storedValue)
    return labelIds || ['all']
  })

  useEffect(() => {
    if (listName) {
      localStorage.setItem(listName, JSON.stringify(selectedLabelIds))
    }

    onFilterUpdate(selectedLabelIds)
  }, [selectedLabelIds])

  return <LabelList
    addAllOption={true}
    initialLabels={[{id: 'all', name: 'All' }, ...labels]}
    onLabelSelectionChange={labelIds => setSelectedLabelIds(labelIds)}
    selectedLabelIds={selectedLabelIds}
    withBorderBottom={false} />
}

export default LabelFilter
