import React from "react"

const ShareResult = ({
  agreements,
  documents,
  packageData,
}) => {
  const agreementText = agreements.length > 0 ? `${agreements.length} agreement${agreements.length > 1 ? 's' : ''}` : ''
  const documentText = documents.length > 0 ? `${documents.length} document${documents.length > 1 ? 's' : ''}` : ''
  const joinText = agreements.length > 0 && documents.length > 0 ? ' and ' : ''

  return (
    <div className="has-text-centered" style={{ paddingTop: '80px' }}>
      <div style={{ border: 'solid 4px #2bc9b4', borderRadius: '50%', padding: '9px', width: '72px', height: '72px', margin: 'auto' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
          <path fill="#2BC9B4" fillRule="evenodd" d="M18 32.34L9.66 24 6.82 26.82 18 38 42 14 39.18 11.18z" />
        </svg>
      </div>

      <h2 style={{ fontSize: '26px', margin: '1rem 0 0 0' }}>Envelope Added!</h2>

      <p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '20px 10px', maxWidth: '500px', marginTop: '40px' }}>
        You added {documentText}{joinText}{agreementText} to {packageData.name}.
      </p>
    </div>
  )
}

export default ShareResult
