import React from "react"

import BaseToggleStep from "../BaseToggleStep"

const BadgeStep = ({
  trustCenter,
  onPanelClose = () => { },
  onTrustCenterChange = () => { }
}) => {
  return <BaseToggleStep
    labelAttr="name"
    onPanelClose={() => onPanelClose()}
    onTrustCenterChange={trustCenter => onTrustCenterChange(trustCenter)}
    paramSetUrl="/badges.json"
    trustCenter={trustCenter}
    trustCenterAttr="badges"
    trustCenterParam="badge_ids"
    title="Badges" />
}

export default BadgeStep
