import React, { useState } from "react"

import WatermarkModalForm from './WatermarkModalForm'

const WatermarkLink = (props) => {
  const documentSlug = props.documentSlug
  const [showWatermarkModalForm, setShowWatermarkModalForm] = useState(false)

  let watermarkModalForm;
  if (showWatermarkModalForm) {
    watermarkModalForm = <WatermarkModalForm
      documentSlug={documentSlug}
      cancelAction={() => setShowWatermarkModalForm(false)} />
  }

  return (
    <React.Fragment>
      <a className="button is-link" onClick={() => setShowWatermarkModalForm(true)}>
        <span className="icon is-small">
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 3c-1.657 0-3 1.343-3 3 0 3 5 7-3 7-1.105 0-2 .895-2 2v2h16v-2c0-1.105-.895-2-2-2-8 0-3-4-3-7 0-2-1.34-3-3-3M6 19v2h12v-2H6z"
              fill="#8C9296"
              fillRule="evenodd">
            </path>
          </svg>
        </span>

        <b> Custom Watermark</b>
      </a>
      {watermarkModalForm}
    </React.Fragment>
  );
}

export default WatermarkLink
