import React, { useState } from "react"
import ReactDOM from 'react-dom'
import Turbolinks from "turbolinks"
import BlockedDomainModalForm from './BlockedDomainModalForm'

const BlockedDomainAction = ({
  blockedDomain,
  blockedDomains,
}) => {
  const [blockedDomainFormOpen, setBlockedDomainFormOpen] = useState(false)

  const refreshCurrentPage = () => {
    Turbolinks.visit(window.location.toString(), { action: 'replace' })
  }

  const blockedDomainForm = blockedDomainFormOpen ? ReactDOM.createPortal(
    <BlockedDomainModalForm
      blockedDomain={blockedDomain}
      blockedDomains={blockedDomains}
      onCancel={() => setBlockedDomainFormOpen(false)}
      onSave={() => refreshCurrentPage()} />,
    document.getElementById('modal-container')
  ) : null

  return <>
    <a className={`${!blockedDomain ? 'button is-primary' : ''}`} onClick={() => setBlockedDomainFormOpen(true)}>
      {blockedDomain && 'Edit'}
      {!blockedDomain && 'Add Domain'}
    </a>
    {blockedDomainForm}
  </>
}

export default BlockedDomainAction