import React from "react"

import EntityConfirmAccess from './../shared/EntityConfirmAccess'

const DocumentConfirmAccess = ({
  cancelOnly = false,
  documentSlug,
  generatingDocument,
  loadingSubtext = "This should take about 15 seconds.",
  partnerSlug,
}) => {
  return <EntityConfirmAccess
    cancelOnly={cancelOnly}
    checkEndpoint="water_marked_document_attached"
    entitySlug={documentSlug}
    entityType="document"
    loading={generatingDocument}
    loadingTitle="Processing Document"
    loadingSubtext={loadingSubtext}
    partnerSlug={partnerSlug} />
}

export default DocumentConfirmAccess
