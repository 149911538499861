import React, { useEffect, useState } from "react"

import { MultiSelect } from "react-multi-select-component"

const GroupSelector = ({
    groupOptions,
    isSubmitted,
    onChange = () => { },
    onGroupChangeValid = () => { },
    resourceName = 'document',
    selectedGroupIds: initialSelectedGroupIds,
}) => {
    const [hasGroups, setHasGroups] = useState(initialSelectedGroupIds.length > 0)
    const [selectedGroupIds, setSelectedGroupIds] = useState(initialSelectedGroupIds)

    const groupsValid = () => {
        if (!hasGroups) {
            return true
        }

        const hasSelectedGroups = selectedGroupIds.length != 0
        const valid = hasGroups && hasSelectedGroups
        onGroupChangeValid(valid)
        return valid
    }

    useEffect(() => {
        if (hasGroups) {
            return
        }

        onChange([])
        onGroupChangeValid(true)
    }, [hasGroups])

    const CustomClearIcon = () => <div></div>

    return <>
        <div className="columns">
            <div className="column">
                <span className="control">
                    <span className="is-primary pure-material-checkbox">
                        <label className="pure-material-checkbox">
                            <input type="checkbox"
                                disabled={groupOptions.length == 0}
                                checked={hasGroups}
                                onChange={() => setHasGroups(!hasGroups)}
                                style={{ opacity: (groupOptions.length == 0 ? 0.5 : 1) }} />
                            <span style={{ opacity: (groupOptions.length == 0 ? 0.5 : 1) }}>
                                Limit access of this {resourceName} to {hasGroups && 'the following '}groups{hasGroups && ':'}
                            </span>
                        </label>
                    </span>
                </span>
                <div className="control" style={{ marginLeft: '34px' }}>
                    {!groupsValid() && isSubmitted && <span style={{ color: '#d92022', fontSize: '12px', fontWeight: 600, position: 'absolute', top: '-18px', right: 0 }} className="error">Required</span>}
                    {hasGroups && <MultiSelect
                        ClearSelectedIcon={<CustomClearIcon />}
                        className={`agreement-dropdown ${!groupsValid() && isSubmitted ? 'error' : ''}`}
                        disabled={!hasGroups || groupOptions.length == 0}
                        options={groupOptions}
                        value={selectedGroupIds}
                        onChange={value => {
                            setSelectedGroupIds(value)
                            onChange(value)
                        }}
                        hasSelectAll={false}
                        disableSearch={true}
                        style={{ opacity: ((!hasGroups || groupOptions.length == 0) ? 0.5 : 1) }}
                        labelledBy={"Choose Groups…"} />}
                </div>
            </div>
        </div>
        {groupOptions.length == 0 && <div className="columns">
            <div className="column">
                <div style={{ fontSize: '12px', marginLeft: '36px', marginTop: '-20px' }}>
                    Create a <a href="/settings/users_and_groups">Group</a> to enable this feature
                </div>
            </div>
        </div>}
    </>
}


export default GroupSelector