import React, { useMemo } from "react"
import utils from 'components/utils/utils'
import Entity from './Entity'

const Document = ({
  available,
  document,
  documentIsInternal,
  documentType,
  requester,
  requestSlug,
}) => {
  const subTitle = useMemo(() => {
    if (requester) {
      return `Requested by ${requester.first_name} ${requester.last_name}`
    } else {
      const contactList = document.contacts.map((contact) => `${contact.first_name} ${contact.last_name}`)
      const contact1 = contactList.shift()
      const contact2 = contactList.shift()
      const otherContacts = contactList.length > 0 ? `, and ${contactList.length} other${contactList.length > 1 ? 's' : ''}` : false
      const contactText = `${contact1}${contact2 && otherContacts ? ', ' : ''}${contact2 && !otherContacts ? ' and ' : ''}${contact2 ? contact2 : ''}${otherContacts ? otherContacts : ''}`

      return available ? `Sent to ${contactText}` : 'Available after signing'
    }
  }, [requester])

  return (
    <Entity
      disabled={!available}
      entity={document}
      previewUrl={utils.usePreviewCDN(`/${documentType}s/${document.slug}/preview?resize=60x80`, document)}
      subtitle={subTitle}
      url={`/documents/${document.slug}${!documentIsInternal ? '/view' : ''}${requestSlug ? `?request=${requestSlug}` : ''}`} />
  )
}

export default Document
