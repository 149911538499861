import React, { useMemo, useState } from "react"
import { useForm } from 'react-hook-form'

import utils from '../utils/utils'

import Modal from "./../Modal"
import TextField from "./../inputs/TextField"
import blocklist from './blocklist'

const CompanyForm = ({
  companyName = "",
  firstName = "",
  lastName = "",
  email = "",
  onCancel = () => { },
  onSave = () => { },
}) => {
  const blocklistedEmailDomains = useMemo(() => blocklist, [])
  const { register, handleSubmit, errors, formState } = useForm({ mode: "onChange" })
  const [error, setError] = useState(false)

  const submitCompany = (data) => {
    fetch("/companies.json", {
      method: 'POST',
      body: JSON.stringify({ contact: data }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }).then(res => res.json())
      .then((company) => {
        setError(false)

        if (company.errors) {
          for (const e in company.errors) {
            for (const msg of company.errors[e]) {
              setError(msg)
            }
          }
          return
        }

        if (company.redirect_path) {
          window.location = company.redirect_path
          return
        }

        onSave(company)
      })
  }

  const confirmButton = <input
    className={`button is-info ${formState.isValid ? '' : 'disabled'} ${!!error ? 'disabled' : ''}`}
    draggable="false"
    type="submit"
    value="Next" />

  return <form onSubmit={handleSubmit(submitCompany)}>
    <Modal
      confirmButton={confirmButton}
      cancelAction={onCancel}
      title="Add a Company"
    >
      <div className="columns">
        <div className="column">
          <TextField
            defaultValue={companyName}
            disabled={!!error}
            errors={errors}
            label="Company Name"
            name="company_name"
            placeholder="E.g., Company Inc"
            reff={register({ required: true })} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <p>
            Next, add details for your contact at the company:
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-half">
          <TextField
            defaultValue={firstName}
            disabled={!!error}
            errors={errors}
            label="First Name"
            name="first_name"
            placeholder="E.g. Susan"
            reff={register({ required: true })} />
        </div>
        <div className="column is-half">
          <TextField
            defaultValue={lastName}
            disabled={!!error}
            errors={errors}
            label="Last Name"
            name="last_name"
            placeholder="E.g. Smith"
            reff={register({ required: true })} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <TextField
            defaultValue={email}
            disabled={!!error}
            errors={errors}
            label="Email"
            name="email"
            placeholder="E.g., email@company.com"
            reff={register({
              required: true,
              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              validate: {
                workEmail: value => {
                  if (typeof blocklistedEmailDomains === 'undefined') {
                    return true
                  }
                  const email_domain = value.split('@')[1]
                  return blocklistedEmailDomains.indexOf(email_domain) == -1
                }
              }
            })} />
          {error && <div className="error" style={{ color: '#d92022', fontWeight: 600 }}>
            {error}
          </div>}
        </div>
      </div>
    </Modal>
  </form>
}

export default CompanyForm
