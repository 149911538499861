import React, { useState } from "react"

import EmptyState from 'components/RequestForms/RequestFormEdit/EmptyState'
import Package from './Package'
import PackageStep from '../../RequestFormEdit/PackageStep'

import styles from "./style.module"

const Packages = ({
  edit,
  onRequestFormChange = (requestForm) => { },
  requestForm,
}) => {
  const [packages, setPackages] = useState(requestForm.packages)
  const [editPackages, setEditPackages] = useState(false)
  const [selectedPackages, setSelectedPackages] = useState([])

  const onChange = (packageData, checked) => {
    if (checked) {
      setSelectedPackages([...selectedPackages, packageData])
    } else {
      setSelectedPackages(selectedPackages.filter(selectedPackage => packageData.id !== selectedPackage.id))
    }
  }

  return <>
    {(edit || packages.length > 0) && <>
      {editPackages && <PackageStep
        onPanelClose={() => setEditPackages(false)}
        onRequestFormChange={requestForm => {
          onRequestFormChange(requestForm)
          setPackages(requestForm.packages)
        }}
        requestForm={requestForm}
      />}
      <div className="is-flex is-justify-content-space-between">
        <label className="label">Choose Packages</label>
        {edit && <a id="open-edit-package-side-bar" className="has-text-weight-bold" onClick={() => setEditPackages(true)}>Edit</a>}
      </div>
      <div className={styles.Packages}>
        {packages.length == 0 && <EmptyState message="Choose packages that should be available on your Request Form." />}
        {packages.length > 0 && <>
          <div className="columns">
            <div className="column">
              {packages.map(packageData => (
                <Package
                  key={packageData.id}
                  onChange={(packageData, checked) => onChange(packageData, checked)}
                  packageData={packageData} />
              ))}
            </div>
          </div>
        </>}
      </div>
    </>}
  </>
}

export default Packages
