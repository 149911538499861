import React, { useState } from "react"
import { useForm } from 'react-hook-form'

import resourceFormUtils from '../utils/ResourceFormUtils'
import utils from '../utils/utils'

import FileField from "./../inputs/FileField"
import Modal from "./../Modal"
import TextArea from "./../inputs/TextArea"
import TextField from "./../inputs/TextField"

const AgreementModalForm = (props) => {
  const onCancel = props.onCancel
  const partnerSlug = props.partnerSlug

  const [backendErrors, setBackendErrors] = useState(null)

  const { register, handleSubmit, errors, formState, getValues } = useForm({ mode: "onChange" })

  const submitAgreement = (data) => {
    const formData = new FormData()
    formData.append("agreement[signed_agreement]", data.signed_agreement[0])
    formData.append("agreement[name]", data.name)
    formData.append("agreement[description]", data.description)

    fetch(`/companies/${partnerSlug}/upload_existing_agreement`, {
      body: formData, headers: { 'X-CSRF-Token': utils.csrfToken() }, method: 'POST'
    })
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          Turbolinks.visit(`/companies/${partnerSlug}`)
        } else {
          setBackendErrors(res.errors)
        }
      })
  }

  const confirmButton = <input className={`button is-info ${formState.isValid ? '' : 'disabled'}`}
    draggable="false"
    type="submit"
    value="Save Changes" />

  return <form onSubmit={handleSubmit(submitAgreement)}>
    <Modal
      {...props}
      confirmButton={confirmButton}
      title="Add Agreement">
      <div className="columns">
        <div className="column">
          <FileField
            errors={errors}
            label="Upload Agreement"
            name="signed_agreement"
            reff={register({
              required: true,
              validate: {
                agreementResourceFileFormat: file => resourceFormUtils.fileFormatValid(file, resourceFormUtils.supportedAgreementResourceFormats),
                agreementResourceFileSize: file => resourceFormUtils.fileSizeValid(file)
              }
            })} />
          {backendErrors?.signed_agreement && <p className="error" style={{ color: '#d92022', fontWeight: 600 }}>{backendErrors.signed_agreement}</p>}
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <TextField
            errors={errors}
            label="Agreement Name"
            name="name"
            placeholder="E.g. SOC 2 Report"
            reff={register({ required: true })} />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <TextArea
            errors={errors}
            label="Description"
            name="description"
            placeholder="E.g. This reports contains..."
            reff={register()}
            style={{ height: '72px' }} />
        </div>
      </div>
    </Modal>
  </form>
}

export default AgreementModalForm
