import React, { useMemo, useState } from "react"
import ReactDOM from 'react-dom'

import ShareDocumentModalForm from './ShareDocumentModalForm'

const ShareDocuments = (props) => {
  const {
    buttonTitle,
    loaded,
    onEnvelopeAdded,
    title,
  } = props

  const [showShareDocumentModalFormOpen, setShareDocumentModalFormOpen] = useState(false)

  const shareDocumentModalForm = useMemo(() => {
    if (!showShareDocumentModalFormOpen) {
      return
    }

    return ReactDOM.createPortal(
      <ShareDocumentModalForm
        {...props}
        cancel={() => setShareDocumentModalFormOpen(false)}
        onDone={() => setShareDocumentModalFormOpen(false)}
        onSave={() => onEnvelopeAdded()} />,
      document.getElementById('modal-container')
    )

  }, [showShareDocumentModalFormOpen])

  return <div className="content">
    <div className="columns">
      <div className="column">
        <h3 style={{ color: '#16232f', fontSize: '26px', fontWeight: 'bold' }}>
          {title}
        </h3>
      </div>
      <div className="column has-text-right">
        {loaded && <a className="button is-primary"
          onClick={() => setShareDocumentModalFormOpen(true)}
          style={{ fontWeight: 'bold', lineHeight: '48px' }}>
          {buttonTitle}
        </a>}
        {shareDocumentModalForm}
      </div>
    </div>
  </div>
}

export default ShareDocuments
