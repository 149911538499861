import React from "react"

const LogoCloud = () => {
  return <React.Fragment>
        <div className="has-text-centered" style={{ margin: '0 20px' }}>
            <br />
            <b style={{ fontSize: '18px' }}>Trusted by industry experts:</b>
            <br />
            <br />
            <div className="columns">
                <div className="column">
                <img src="/customer_logos/circleci-logo-color.png" alt="CircleCi" style={{ width: '133px' }} />
                </div>
                <div className="column">
                <img src="/customer_logos/customerio-logo-color.png" alt="Customer.io" style={{ width: '133px' }} />
                </div>
                <div className="column">
                <img src="/customer_logos/returnly-logo-color.png" alt="Returnly" style={{ width: '133px' }} />
                </div>
            </div>
            <div className="columns">
                <div className="column">
                <img src="/customer_logos/front-logo-color.png" alt="Front" style={{ width: '40px' }} />
                </div>
                <div className="column">
                <img src="/customer_logos/pinpoint-logo-color.png" alt="Pinpoint" style={{ width: '133px' }} />
                </div>
                <div className="column">
                <img src="/customer_logos/bonusly-logo-color.png" alt="Bonusly" style={{ width: '133px' }} />
                </div>
            </div>
            <div className="columns">
                <div className="column">
                <img src="/customer_logos/hackerone-logo.color.png" alt="Hackerone" style={{ width: '133px' }} />
                </div>
            </div>
        </div>
        <br />
        <div style={{ maxWidth: '480px', height: '1px', margin: 'auto', backgroundColor: '#0075e0' }}></div>
        <p className="has-text-centered" style={{ fontSize: '15px' }}>
        <b>“We can have NDAs signed in less than 10 seconds.”</b>
        <br />
        <i style={{ fontSize: '13px' }}>- Tad, Security Engineering Manager @ CircleCI</i>
        </p>

        <p className="has-text-centered" style={{ fontSize: '15px' }}>
        <b>“Without Pima, the process was very manual<br />and things were falling through the cracks.”</b>
        <br />
        <i style={{ fontSize: '13px' }}>— Nelson, Security Operation Lead @ Front</i>
        </p>
        <div style={{ maxWidth: '480px', height: '1px', margin: 'auto', backgroundColor: '#0075e0' }}></div>
    </React.Fragment>
}

export default LogoCloud
