import React, { useMemo, useState } from "react"
import ReactDOM from 'react-dom'
import Modal from 'components/Modal'

import utils from 'components/utils/utils'

import styles from "./style.module"

const ImageManager = ({
  fieldName,
  fieldValue,
  helpText,
  layout = 'inline',
  onError,
  onFieldUpdate,
  trustCenter,
}) => {
  const checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  }

  const onFileSelected = (event) => {
    const file = event.target.files[0]
    const formData = new FormData()
    formData.append(`trust_center[${fieldName}]`, file)

    fetch(`/trust_centers/${trustCenter.slug}.json`, {
      body: formData,
      headers: { 'X-CSRF-Token': utils.csrfToken() },
      method: 'PATCH'
    }).then(checkStatus)
      .then(response => response.json())
      .then(response => {
        try {
          document.getElementById(fieldName).value = ""
        } catch (error) {
          console.error(error)
        }
        onFieldUpdate(response[fieldName])
      })
      .catch(response => {
        try {
          return response.json()
        } catch (error) {
          return response
        }
      })
      .then(error => onError(error))
  }

  const purgeAttachment = () => {
    const formData = new FormData()
    formData.append('field', fieldName)

    fetch(`/trust_centers/${trustCenter.slug}/purge_attachment.json`, {
      body: formData,
      headers: { 'X-CSRF-Token': utils.csrfToken() },
      method: 'PATCH'
    }).then(response => (
      response.json()
    )).then(response => {
      onFieldUpdate(response[fieldName])
    }).catch((error) => {
      console.error("Error uploading file: ", error)
      onError(error);
    })
  }

  return <>
    <div className={`is-flex ${layout == 'inline' ? 'is-flex-direction-row' : 'is-flex-direction-column'} is-justify-content-center`}>
      <input type="file" id={fieldName} style={{ display: 'none' }} onChange={(event) => onFileSelected(event)} />
      <a onClick={() => document.getElementById(fieldName).click()} className="has-text-weight-bold" style={{ padding: '6px 15px', background: 'rgba(24, 36, 45, 0.70)', borderRadius: '44px', textAlign: 'center', width: '90px', border: '1px rgba(255, 255, 255, 0.50) solid', marginRight: (layout == 'inline' ? '8px' : 0) }}>
        <span style={{ color: 'white', fontSize: '16px', fontWeight: 700 }}>{fieldValue?.length > 0 ? 'Edit' : 'Add'}</span>
      </a>
      {fieldValue?.length > 0 && <a onClick={() => {
        if (confirm('Are you sure?')) {
          purgeAttachment()
        }
      }} className="has-text-weight-bold" style={{ padding: '6px 15px', background: 'rgba(24, 36, 45, 0.70)', borderRadius: '44px', textAlign: 'center', width: '90px', border: '1px rgba(255, 255, 255, 0.50) solid', marginTop: (layout == 'column' ? '8px' : 0) }}>
        <span style={{ color: 'white', fontSize: '16px', fontWeight: 700 }}>Delete</span>
      </a >}
    </div>
    {helpText && <p className="has-text-centered" style={{ background: 'rgba(24, 36, 45, 0.45)', borderRadius: '4px', padding: '4px 8px', marginTop: '10px', color: '#FFFFFF', fontSize: '12px', fontWeight: 600, width: '300px', margin: '10px auto' }}>{helpText}</p>}
  </>
}

const Banner = ({
  edit,
  trustCenter,
}) => {
  const [banner, setBanner] = useState(trustCenter.banner)
  const [error, setError] = useState(null)
  const [logo, setLogo] = useState(trustCenter.logo)

  const [showBannerEdit, setShowBannerEdit] = useState(false)
  const [showLogoEdit, setShowLogoEdit] = useState(false)

  const errorModal = useMemo(() => {
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    if (error) {
      const fileKey = Object.keys(error)[0]
      const errorMsg = `${capitalizeFirstLetter(fileKey)} ${error[fileKey][0]}`

      return ReactDOM.createPortal(<Modal
        cancelAction={() => setError(null)}
        cancelButton={<></>}
        confirmAction={() => setError(null)}
        confirmButtonText="Done"
        title="">
        <div className="has-text-centered">
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Alert" clipPath="url(#clip0_1861_11769)">
              <g id="Shape">
                <path d="M36 0C55.8823 0 72 16.1177 72 36C72 55.8823 55.8823 72 36 72C16.1177 72 0 55.8823 0 36C0 16.1177 16.1177 0 36 0ZM36 4C18.3269 4 4 18.3269 4 36C4 53.6731 18.3269 68 36 68C53.6731 68 68 53.6731 68 36C68 18.3269 53.6731 4 36 4ZM38 49V53H34V49H38ZM38 19V45H34V19H38Z" fill="#FADB47" />
              </g>
            </g>
          </svg>
          <h3 style={{ fontSize: '26px', fontWeight: 700 }}>Incorrect image size</h3>
          <br />
          <p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '12px 16px', maxWidth: '500px' }}>
            {errorMsg}
          </p>
          <br />
          <p>Adjust your image and upload it again.</p>
        </div>
      </Modal>,
        document.getElementById('modal-container')
      )
    }
  }, [error])

  return <div className={styles.Banner} style={{ position: 'relative' }}>
    <div
      style={{ position: 'relative' }}
      onMouseOver={() => edit && setShowBannerEdit(true)}
      onMouseOut={() => edit && setShowBannerEdit(false)}
    >
      <div className={styles.defaultBackground}>
        {banner && <div className={styles.bannerImage} style={{
          backgroundImage: `url("${banner}")`,
        }}></div>}
      </div>
      {edit && <div style={{ position: 'absolute', top: 'calc(50% - 25px)', left: 'calc(50% - 150px)', opacity: showBannerEdit ? 1 : 0 }}>
        <ImageManager
          fieldName="banner"
          fieldValue={banner}
          helpText="Image must be exactly 2000 x 400 pixels."
          onError={e => setError(e)}
          onFieldUpdate={value => setBanner(value)}
          trustCenter={trustCenter} />
      </div>}
    </div>

    <div
      className={`${styles.logoImg} is-flex`}
      onMouseOver={() => edit && setShowLogoEdit(true)}
      onMouseOut={() => edit && setShowLogoEdit(false)}
    >
      {logo && <img src={logo} alt="Logo" />}
      {!logo && <div
        className="is-flex is-justify-content-center is-align-items-center"
        style={{ opacity: 0.4, width: '100%' }}>
        <svg height="72" viewBox="0 0 23 23" width="72" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z" fill="#555" fillRule="evenodd" />
        </svg>
      </div>}
      {edit && <div
        className="is-flex is-justify-content-center is-align-items-center"
        style={{ height: '100%', width: '100%', opacity: showLogoEdit ? 1 : 0, position: 'absolute' }}>
        <ImageManager
          fieldName="logo"
          fieldValue={logo}
          layout="column"
          onError={e => setError(e)}
          onFieldUpdate={value => setLogo(value)}
          trustCenter={trustCenter} />
      </div>}
    </div>

    {errorModal}
  </div >
}

export default Banner
