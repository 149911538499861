import React, { useState } from "react";

import ContactDropdown from "./ContactDropdown"

const Contact = (props) => {
  const agreementSlug = props.agreementSlug
  const alreadyExistingEmails = props.alreadyExistingEmails
  const company = props.company
  const contact = props.contact
  const editionDisabled = props.editionDisabled
  const onDelete = props.onDelete
  const onSave = props.onSave
  const partnerName = props.partnerName
  const partnerSlug = props.partnerSlug

  const [showContactDropdown, setContactDropdown] = useState(0)

  return (<li className="contact hover-gray">
    {!editionDisabled && <div
      onMouseLeave={() => setContactDropdown(false)}
      style={{ float: 'right', marginTop: '10px', width: '192px' }}>
      <div
        className="dropdown-trigger"
        onMouseEnter={() => setContactDropdown(true)}
        style={{ cursor: 'pointer', marginLeft: '155px', textAlign: 'right', width: '25px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="#424C53" fillRule="evenodd" d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
        </svg>
      </div>
      <ContactDropdown
        agreementSlug={agreementSlug}
        alreadyExistingEmails={alreadyExistingEmails}
        company={company}
        clicked={() => setContactDropdown(false)}
        contact={contact}
        onDelete={onDelete}
        onSave={onSave}
        partnerName={partnerName}
        partnerSlug={partnerSlug}
        show={showContactDropdown}></ContactDropdown>
    </div>}

    <strong>{contact.first_name} {contact.last_name}</strong>
    <br />
    {contact.email}
  </li>)
}

export default Contact
