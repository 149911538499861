import React from "react"
import Textarea from './Textarea'
import settingsUtils from '../settingsUtils'

const TextareaPreference = ({ 
  initialValue,
  label,
  helpText,
  fieldName,
  inputStyle,
  method,
  modelName,
  placeholder,
  url
}) => {
  const onChange = (value) => {
    settingsUtils.saveValue(fieldName, method, modelName, url, value)
  }

  return <><Textarea
    fieldId={`${modelName}_${fieldName}`}
    initialValue={initialValue}
    inputStyle={inputStyle}
    label={label}
    onChange={(value) => onChange(value)}
    placeholder={placeholder} />
    {helpText && <div style={{ marginTop: '-10px' }}>
      <small>{helpText}</small>
    </div>}
  </>
}

export default TextareaPreference