import React, { useEffect, useMemo, useState } from "react"

import { TailSpin } from "react-loader-spinner"

import Toggle from "components/inputs/Toggle"
import utils from 'components/utils/utils'

const BaseToggleStep = ({
  children,
  disabledSubtitle = null,
  labelAttr,
  onPanelClose = () => { },
  onRequestFormChange = () => { },
  paramSetUrl,
  subtitle,
  title,
  requestForm: initialRequestForm,
  requestFormAttr,
  requestFormParam,
}) => {
  const [availableEntities, setAvailableEntities] = useState([])
  const [loading, setLoading] = useState(true)
  const [requestForm, setRequestForm] = useState(initialRequestForm)
  const [searchValue, setSearchValue] = useState(null)

  const toggleEntity = (selectedEntity, checked) => {
    let newRequestFormEntities
    if (checked) {
      newRequestFormEntities = [...requestForm[requestFormAttr], selectedEntity]
    } else {
      newRequestFormEntities = requestForm[requestFormAttr].filter(entity => entity.id !== selectedEntity.id)
    }
    requestForm[requestFormAttr] = newRequestFormEntities
    setRequestForm(requestForm)

    const params = {}
    params[requestFormParam] = newRequestFormEntities.map(entity => entity.id)

    fetch(`/request_forms/${requestForm.slug}`, {
      method: 'PATCH',
      body: JSON.stringify({
        request_form: params
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    })
      .then(_res => onRequestFormChange(requestForm))
      .catch(errors => console.error(errors))
  }

  const filteredEntities = useMemo(() => {
    return availableEntities.filter((availableEntity) => {
      if (searchValue == '' || !searchValue) {
        return true
      }

      return availableEntity[labelAttr].toUpperCase().includes(searchValue.toUpperCase())
    })
  }, [availableEntities, searchValue])

  useEffect(() => {
    utils.getJson(paramSetUrl).then(entities => {
      setAvailableEntities(entities.sort((a, b) => {
        const nameA = a[labelAttr].toUpperCase()
        const nameB = b[labelAttr].toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      }))
      setLoading(false)
    })
  }, [])

  return <>
    <a className="side-panel-background" onClick={() => onPanelClose()} style={{ zIndex: 100 }}></a>
    <div className="side-panel" style={{ zIndex: 100 }}>
      <div className="content is-title-block">
        <a className="delete" onClick={() => onPanelClose()}></a>
        {title}
      </div>
      <div className="content">
        {!loading && availableEntities.length > 0 && <div className="is-flex is-relative" style={{ marginBottom: '16px' }}>
          <svg style={{ position: 'absolute', zIndex: 10, top: '7px', left: '9px' }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path fill="#424C53" fillRule="evenodd" d="M15.5,14 L14.71,14 L14.43,13.73 C15.41,12.59 16,11.11 16,9.5 C16,5.91 13.09,3 9.5,3 C5.91,3 3,5.91 3,9.5 C3,13.09 5.91,16 9.5,16 C11.11,16 12.59,15.41 13.73,14.43 L14,14.71 L14,15.5 L19,20.49 L20.49,19 L15.5,14 Z M9.5,14 C7.01,14 5,11.99 5,9.5 C5,7.01 7.01,5 9.5,5 C11.99,5 14,7.01 14,9.5 C14,11.99 11.99,14 9.5,14 Z" />
          </svg>
          <input
            autoComplete="off"
            className="input"
            type="text"
            name="search-entities"
            placeholder="Search..."
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ paddingLeft: '38px', height: '36px' }}
            autoFocus="1" />
          {searchValue && <a style={{ paddingTop: '5px', paddingLeft: '14px', color: '#18242d' }} onClick={() => {
            document.querySelector('input[name="search-entities"]').value = ''
            setSearchValue(null)
          }}>Clear</a>}
        </div>}
        {!loading && subtitle && <>
          <p style={{ fontSize: '12px' }}>{subtitle}</p>
          <div className="separator mt-2 mb-2"></div>
        </>}
        {!loading && filteredEntities.map(filterEntity => <Toggle
          disabled={!!filterEntity.disabled && !requestForm[requestFormAttr].map(entity => entity.id).includes(filterEntity.id)}
          key={filterEntity.slug}
          label={`${filterEntity[labelAttr]}${!!filterEntity.disabled ? ` - ${disabledSubtitle}` : ''}`}
          initialValue={requestForm[requestFormAttr].map(entity => entity.id).includes(filterEntity.id)}
          onChange={checked => toggleEntity(filterEntity, checked)}
          options={[{ label: 'On', value: true }, { label: 'Off', value: false }]}
          size="small" />)}
        {searchValue?.length > 0 && filteredEntities.length == 0 && <p>"{searchValue}" did not match any results...</p>}
        {loading && <div className="is-flex is-align-items-center is-justify-content-center" style={{ width: '100%', marginTop: '130px', marginBottom: '130px' }}>
          <TailSpin color="#18242d" height={24} width={24} />
        </div>}
        {children}
      </div>
    </div>
  </>
}

export default BaseToggleStep
