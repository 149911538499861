import React, { useMemo, useState } from "react"

import EmptyState from 'components/TrustCenters/TrustCenterEdit/EmptyState'

import SubprocessorStep from '../../../TrustCenterEdit/SubprocessorStep'
import Subprocessor from './Subprocessor'

import styles from "./style.module"

const Subprocessors = ({
  edit,
  trustCenter,
}) => {
  const [subprocessors, setSubprocessors] = useState(trustCenter.subprocessors)
  const [editSubprocessors, setEditSubprocessors] = useState(false)

  const sortedSubprocessors = useMemo(() => (
    subprocessors.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
  ), [subprocessors])

  return (subprocessors.length > 0 || edit) && <div className={styles.Subprocessors}>
    {editSubprocessors && <SubprocessorStep
      onPanelClose={() => setEditSubprocessors(false)}
      onTrustCenterChange={trustCenter => setSubprocessors(trustCenter.subprocessors)}
      trustCenter={trustCenter} />}
    {edit && <a onClick={() => setEditSubprocessors(true)} className="is-pulled-right has-text-weight-bold">Edit</a>}
    <h2 className={styles.h2}>{trustCenter.title}'{trustCenter.title[trustCenter.title.length - 1] == 's' ? '' : 's'} sub-processors</h2>
    {subprocessors.length > 0 && <div className={styles.SubprocessorsOverflow}>
      <table>
        <thead>
          <tr>
            <th>Sub-processor</th>
            <th>Purpose</th>
            <th>More information</th>
          </tr>
        </thead>
        <tbody>
          {sortedSubprocessors.map(subprocessor => <Subprocessor key={subprocessor.slug} subprocessor={subprocessor} />)}
        </tbody>
      </table>
    </div>}
    {subprocessors.length == 0 && <EmptyState message="Select your company’s subrocessors." />}
  </div >
}

export default Subprocessors
