import React from "react"

import EntityConfirmAccess from './../shared/EntityConfirmAccess'

const AgreementConfirmAccess = (props) => {
  const agreementDownloading = props.agreementDownloading
  const agreementSlug = props.agreementSlug
  const partnerSlug = props.partnerSlug

  return <EntityConfirmAccess
    checkEndpoint="signed_agreement_attached"
    entitySlug={agreementSlug}
    entityType="agreement"
    loading={agreementDownloading}
    loadingTitle="Processing Signed Agreement"
    loadingSubtext="This should take about 15 seconds."
    partnerSlug={partnerSlug} />
}

export default AgreementConfirmAccess
