import React from "react"

import styles from "./style.module"

import Badges from './Badges'
import Banner from './Banner'
import Description from './Description'
import Packages from './Packages'
import SecuritySummary from './SecuritySummary'
import Title from './Title'
import Subprocessors from "./Subprocessors"
import TrustedReviewers from "./TrustedReviewers"

const TrustCenter = ({
  companySlug,
  edit,
  isOwnTrustCenter,
  trustCenter,
}) => {
  return <div className={styles.trustCenter}>
    <Banner edit={edit} trustCenter={trustCenter} />
    <Title edit={edit} trustCenter={trustCenter} />
    <Description edit={edit} trustCenter={trustCenter} />
    <Badges edit={edit} trustCenter={trustCenter} />
    <SecuritySummary edit={edit} trustCenter={trustCenter} />
    <Subprocessors edit={edit} trustCenter={trustCenter} />
    <TrustedReviewers edit={edit} trustCenter={trustCenter} />
    <Packages
      companyName={trustCenter.title}
      companySlug={companySlug}
      edit={edit}
      isOwnTrustCenter={isOwnTrustCenter}
      trustCenter={trustCenter} />
  </div>
}

export default TrustCenter
