import React from "react"
import ReactDOM from 'react-dom'

import utils from '../../utils/utils'

import Modal from "../../Modal"

const DeleteLabelFrom = (props) => {
  const { label, onDelete } = props

  const deleteAction = () => {
    fetch(`/settings/labels/${label.id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }).then(() => onDelete(label))
  }
  
  return ReactDOM.createPortal(
    <Modal
      {...props}
      confirmAction={deleteAction}
      confirmButtonText="Delete"
      title="Delete Label">
        {!label.document_resources.length > 0 && <p>Are you sure you want to delete this label?</p>}
        {label.document_resources.length > 0 && <React.Fragment>
          <p>Are you sure you want to delete the label "{label.name}"?</p>
          <br />
          <p>This label will be deleted and removed from the following documents:</p>
          <br />
          <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
            {label.document_resources.map((document_resource, index) => <li key={index}>{document_resource.name}</li>)}
          </ul>
        </React.Fragment>}
    </Modal>,
    document.getElementById('modal-container')
  )
}

export default DeleteLabelFrom