import React from 'react'
import Moment from 'react-moment'
import 'moment-timezone'

import styles from './style.module'

const HistoryEntry = ({
  currentCompanyId,
  currentUserId,
  entity,
  highlighted,
  onEdit,
  tz
}) => {
  const entityType = (entity) => {
    if (entity.type) {
      return entity.type
    }

    return 'envelope'
  }

  const uniqArrayById = (array) => {
    const result = []
    const map = new Map()
    for (const item of array) {
      if (!map.has(item.id)) {
        map.set(item.id, true)
        result.push(item)
      }
    }
    return result
  }

  const envelopeUser = (entity) => {
    let users = []

    entity.agreements.forEach((agreement) => {
      if (agreement.acted_by) {
        users.push(agreement.acted_by)
      }
    })
    entity.documents.forEach((document) => {
      if (document.user) {
        users.push(document.user)
      }
    })

    users = uniqArrayById(users)
    return (users.length > 0) ? users[0] : false
  }

  const entityIsUploadedAgreement = (entity) => {
    return entityType(entity) === 'signed_agreement' && entity.acted_by && !entity.signed_by
  }

  const entityUserId = (entity) => {
    let user

    if (entityType(entity) === 'envelope') {
      user = envelopeUser(entity)
    } else if (entityType(entity) === 'archived_request') {
      user = entity.acted_by
    } else if (entityType(entity) === 'sent_access_pass') {
      user = entity.sent_by
    } else if (entityType(entity) === 'note') {
      user = null
    } else if (entityIsUploadedAgreement(entity)) {
      user = entity.acted_by
    } else {
      user = entity.user
    }

    return (user && user.id) ? user.id : -1
  }

  const userNameAtCompany = (user, company) => {
    return `${user.first_name} ${user.last_name}${currentUserId == user.id ? ' (you)' : ''} ${company ? ` at ${company.name}` : ''}`
  }

  const entityDate = (entity) => {
    if (entity.type) {
      if (entity.type == 'signed_agreement') {
        return entity.signed_on
      }
      if (entity.type == 'archived_request') {
        return entity.acted_on
      }
    }
    return entity.created_at
  }

  const content = (entity) => {
    switch (entityType(entity)) {
      case 'access_log':
        const action = entity.content_type && entity.content_type != "application/pdf" ? 'downloaded' : 'viewed'
        return <p>{userNameAtCompany(entity.user, entity.company)} {action} <a href={`/documents/${entity.slug}/view`}>{entity.name}</a>.</p>
      case 'access_pass':
        return <p>{userNameAtCompany(entity.user, entity.company)} has generated a new access pass.</p>
      case 'sent_access_pass':
        return <p>{userNameAtCompany(entity.sent_by, entity.partner)} sent a new access pass to {userNameAtCompany(entity.user, entity.company)}.</p>
      case 'note':
        return <p>{entity.html_notes}</p>
      case 'signed_agreement':
        if (entity.signed_by && entity.signed_by_company) {
          return <p>{userNameAtCompany(entity.signed_by, entity.signed_by_company)} signed <a href={`/agreements/${entity.slug}/view`}>{entity.name}</a>.</p>
        } else {
          return <p>{entity.acted_by.first_name} {entity.acted_by.last_name}{currentUserId == entity.acted_by.id ? ' (you)' : ''}  uploaded <a href={`/agreements/${entity.slug}/view`}>{entity.name}</a>.</p>
        }
      case 'archived_request':
        const originalRequest = JSON.parse(JSON.stringify(entity))
        originalRequest.type = 'request'
        const originalRequestContent = content(originalRequest)

        return <>
          <p>{userNameAtCompany(entity.acted_by, entity.acted_by_company)} archived:</p>
          <div className="is-indented">
            <b><Moment format="MMMM DD, YYYY - hh:mm A zz" date={entity.created_at} tz={tz} /></b>
            {originalRequestContent}
          </div>
        </>
      case 'request':
        return <>
          <p>{userNameAtCompany(entity.user, entity.company)} requested:</p>
          <ul>
            {entity.request_agreement_resources.map((request_agreement_resource, key) => {
              return <li key={key}>{request_agreement_resource.slug ? <a href={`/agreements/${request_agreement_resource.slug}`}>{request_agreement_resource.name}</a> : request_agreement_resource.name}</li>
            })}
            {entity.request_document_resources.map((request_document_resource, key) => {
              return <li key={key}>{request_document_resource.slug ? <a href={`/documents/${request_document_resource.slug}`}>{request_document_resource.name}</a> : request_document_resource.name}</li>
            })}
            {entity.request_packages.map((packageData, key) => {
              return <li key={key}>{packageData.slug ? <a href={`/packages/${packageData.slug}`}>{packageData.name}</a> : packageData.name}</li>
            })}
          </ul>
        </>
      case 'envelope':
        const user = envelopeUser(entity)

        let userContent
        if (user) {
          userContent = <p>{userNameAtCompany(user, entity.company)}:</p>
        } else {
          if (currentCompanyId == entity.company_id) {
            userContent = <p>Pima automatically:</p>
          } else {
            userContent = <p>{entity.company.name}:</p>
          }
        }

        return <>
          {userContent}
          <ul>
            {entity.agreements.map((agreement, key) => {
              return <li key={key}>Sent the <a href={`/agreements/${agreement.slug}/view`}>{agreement.name}</a></li>
            })}
            {entity.documents.map((document, key) => {
              return <li key={key}>Sent the <a href={`/documents/${document.slug}/view`}>{document.name}</a></li>
            })}
          </ul>
        </>
    }
  }

  return (
    <div
      className={`message ${entityUserId(entity) == currentUserId ? 'is-own-action' : ''} ${entityType(entity)} ${highlighted ? styles.isHighlighted : ''}`}
      id={entity.request_slug} >
      {entity.type == 'note' && <a style={{ float: 'right', fontSize: '14px' }} onClick={(e) => onEdit(entity)}>Edit</a>}
      <b><Moment format="MMMM DD, YYYY - hh:mm A zz" date={entityDate(entity)} tz={tz} /></b>
      {content(entity)}
    </div>
  )
}

export default HistoryEntry
