import React, { useMemo, useState } from "react"
import ReactDOM from 'react-dom'
import { useForm } from "react-hook-form"

import Modal from "components/Modal"
import TextField from "components/inputs/TextField"
import EmptyState from 'components/TrustCenters/TrustCenterEdit/EmptyState'
import utils from 'components/utils/utils'

import Package from './Package'
import RequestResult from './RequestResult'

import PackageStep from '../../../TrustCenterEdit/PackageStep'

import styles from "./style.module"

const Packages = ({
  companyName,
  companySlug,
  edit,
  isOwnTrustCenter,
  trustCenter,
}) => {
  const [packages, setPackages] = useState(trustCenter.packages)
  const [editPackages, setEditPackages] = useState(false)
  const { register, handleSubmit, errors, formState, reset } = useForm({ mode: "onChange" })
  const [selectedPackages, setSelectedPackages] = useState([])
  const [showRequestPackageForm, setShowRequestPackageForm] = useState(false)
  const [response, setResponse] = useState(null)
  const [responseErrors, setResponseErrors] = useState(null)

  const closeAndResetModal = () => {
    setShowRequestPackageForm(false)
    reset()
    setResponse(null)
    setResponseErrors(null)
    setSelectedPackages([])

    const packageCheckboxes = document.querySelectorAll('.request_package')
    packageCheckboxes.forEach(packageCheckbox => {
      packageCheckbox.checked = false
    })
  }

  const onChange = (packageData, checked) => {
    if (checked) {
      setSelectedPackages([...selectedPackages, packageData])
    } else {
      setSelectedPackages(selectedPackages.filter(selectedPackage => packageData.id !== selectedPackage.id))
    }
  }

  const submitRequest = (data) => {
    fetch(`/p/${companySlug}`, {
      method: 'POST',
      body: JSON.stringify({
        request:
          { ...data, package_ids: selectedPackages.map(selectedPackage => selectedPackage.id) }
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }).then(async (response) => {
      if (response.status >= 200 && response.status < 300)
        return await response.json()

      throw await response.json()
    })
      .then(response => setResponse(response))
      .catch(errors => setResponseErrors(errors))
  }

  const requestResult = useMemo(() => (
    <Modal cancelAction={() => closeAndResetModal()}
      cancelButton={<></>}
      confirmButton={<a className={`button is-info`} onClick={() => closeAndResetModal()}>
        Done
      </a>}
      title="Request packages">
      <RequestResult response={response} responseErrors={responseErrors} />
    </Modal>
  ), [response, responseErrors])

  const requestPackageModalForm = <form onSubmit={handleSubmit(submitRequest)} className={styles.requestPackageModalForm}>
    <Modal cancelAction={() => closeAndResetModal()}
      cancelButton={true}
      confirmButton={<input className={`button is-info ${formState.isValid ? '' : 'disabled'}`}
        draggable="false"
        type="submit"
        value="Request packages" />}
      modalCardClassName={styles.modalCard}
      modalCardHeaderClassName={styles.modalCardHeader}
      modalCardFooterClassName={styles.modalCardFooter}
      title="Request packages">
      <div className={styles.requestPackageFormWrapper}>
        <div className="columns">
          <div className="column">
            <TextField
              errors={errors}
              label="First Name"
              name="first_name"
              placeholder="E.g., Susan"
              reff={register({ required: true })} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <TextField
              errors={errors}
              label="Last Name"
              name="last_name"
              placeholder="E.g., Smith"
              reff={register({ required: true })} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <TextField
              errors={errors}
              label="Email"
              name="email"
              placeholder="E.g., email@company.com"
              reff={register({
                required: true,
                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <TextField
              errors={errors}
              label="Company Name"
              name="company_name"
              placeholder="E.g., Company Inc"
              reff={register({ required: true })} />
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <label className="pure-material-checkbox is-flex" htmlFor="tos_accepted">
              <input name="tos_accepted" type="hidden" value="0" />
              <input type="checkbox"
                value="1"
                name="tos_accepted"
                ref={register({ required: true })}
                id="tos_accepted" />
              <span>
                {companyName} is using <a href="https://pima.app" target="_blank">Pima</a> to share documents securely.
                <span className={styles.requestPackageTosSecondLine}>
                  I accept Pima’s <a href="https://pima.app/terms-of-service/" target="_blank">Terms of Service</a> and <a href="https://pima.app/security/privacy-policy/" target="_blank">Privacy Policy</a>.
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </Modal>
  </form>

  const modal = showRequestPackageForm ? ReactDOM.createPortal((response || responseErrors) ? requestResult : requestPackageModalForm, document.getElementById('modal-container')) : null

  return <>
    {(edit || packages.length > 0) && <>
      {editPackages && <PackageStep trustCenter={trustCenter} onTrustCenterChange={trustCenter => setPackages(trustCenter.packages)} onPanelClose={() => setEditPackages(false)} />}
      <div className={styles.Packages}>
        {edit && <a onClick={() => setEditPackages(true)} style={{ float: 'right', fontWeight: 'bold' }}>Edit</a>}
        <h2 className={styles.h2}>Request packages</h2>

        {isOwnTrustCenter && packages.length > 0 && <p style={{
          backgroundColor: '#f4f5f5',
          borderRadius: '6px',
          marginBottom: '10px',
          padding: '8px 24px',
          width: '100%'
        }}>
          You can't request your own packages
        </p>}

        {packages.length > 0 && <>
          <div className="columns">
            <div className="column is-half" style={{ paddingBottom: 0, marginBotton: '-2px' }}>
              {packages.filter((_element, index) => index % 2 === 0).map(packageData => (
                <Package
                  key={packageData.id}
                  onChange={(packageData, checked) => onChange(packageData, checked)}
                  packageData={packageData} />
              ))}
            </div>
            <div className="column is-half">
              {packages.filter((_element, index) => index % 2 === 1).map(packageData => (
                <Package
                  key={packageData.id}
                  onChange={(packageData, checked) => onChange(packageData, checked)}
                  packageData={packageData} />
              ))}
            </div>
          </div>
          <br />
          <a
            className={`button is-primary ${(selectedPackages.length == 0 || isOwnTrustCenter) ? 'disabled' : ''}`}
            disabled={isOwnTrustCenter}
            onClick={() => !isOwnTrustCenter && setShowRequestPackageForm(selectedPackages.length > 0)}
            style={{ display: 'block', margin: 'auto', maxWidth: '400px' }}>
            Request packages
          </a>
          <br />
          {modal}
        </>}
        {packages.length == 0 && <EmptyState message="Choose packages that should be available on your Trust Page." />}
      </div>
    </>}
  </>
}

export default Packages
