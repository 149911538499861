import React from "react"

import styles from "./style.module"

const Package = ({
  onChange,
  packageData,
}) => {
  return <div className={`is-relative is-flex is-align-items-center ${styles.Package}`}>
    <input type="checkbox" onChange={e => onChange(packageData, e.target.checked)} id={`request_package_ids_${packageData.id}`} className="request_package" />
    <label className="button is-flex is-align-items-center" htmlFor={`request_package_ids_${packageData.id}`}>
      <span>
        <b>{packageData.name}</b>
        <br />
        <small>{packageData.description}</small>
      </span>
    </label>
  </div>
}

export default Package
