import React, { useEffect, useState } from "react"
import utils from 'components/utils/utils'
import { TailSpin } from "react-loader-spinner"

const ProcessRequest = ({
  tokenId
}) => {
  const [responseStatus, setResponseStatus] = useState(false)

  const navigateToAccessPass = token => {
    Turbolinks.visit(`/access_passes/${token}`)
  }

  useEffect(() => {
    setTimeout(() => {
      fetch(`/tokens/${tokenId}/process_request`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': utils.csrfToken()
        }
      })
        .then(res => res.json())
        .then(res => {
          if (res.accessPass) {
            const token = res.accessPass.token

            setResponseStatus(<>
              <h3>Your email address is confirmed.</h3>
              <br />
              <p>The request has been sent. Expect to hear back soon!</p>
              <p>{' '}</p>
              <p>Click <a onClick={() => navigateToAccessPass(token)}>here</a> if you are not redirected within a few seconds.</p>
            </>)

            setTimeout(() => {
              navigateToAccessPass(token)
            }, 5000)

          } else {
            setResponseStatus(<>
              <h3>Your email address is confirmed.</h3>
              <br />
              <p>Your request has been sent, we will get back to you shortly.</p>
            </>)
          }
        })
    }, 1)
  }, [])

  return <>
    {!responseStatus && <div className="is-flex is-align-items-center is-justify-content-center">
      <TailSpin color="#4a4a4a" height={24} width={24} />
    </div>}
    {responseStatus}
  </>
}

export default ProcessRequest
