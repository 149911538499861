import React, { useState } from "react"
import ReactDOM from 'react-dom'

import ContactAccessLinkModal from './ContactAccessLinkModal'
import ContactDeleteModal from './ContactDeleteModal'
import ContactModalForm from './ContactModalForm'

const ContactDropdown = (props) => {
  const alreadyExistingEmails = props.alreadyExistingEmails
  const agreementSlug = props.agreementSlug
  const company = props.company
  const contact = props.contact
  const clicked = props.clicked
  const partnerName = props.partnerName
  const partnerSlug = props.partnerSlug

  const onDeleteClick = props.onDeleteClick
  const onEditClick = props.onEditClick
  const onSave = props.onSave
  const onDelete = props.onDelete

  const show = props.show

  const [showContactAccessLinkModal, setContactAccessLinkModal] = useState(false)
  const [showContactDeleteModal, setContactDeleteModal] = useState(false)
  const [showContactEditFormModal, setContactEditFormModal] = useState(false)

  const onContactDelete = (contact) => {
    setContactDeleteModal(false)

    if (!contact) {
      return
    }

    onDelete(contact)
  }

  const onContactUpdate = (contact) => {
    setContactEditFormModal(false)
    onSave(contact)
  }

  let deleteContact
  if (showContactDeleteModal) {
    deleteContact = <ContactDeleteModal
      agreementSlug={agreementSlug}
      cancelButton={contact.contact_has_received_documents}
      cancelAction={() => setContactDeleteModal(false)}
      contact={contact}
      onDelete={onContactDelete}
      title={"Delete Contact"} >
      {!contact.contact_has_received_documents && <React.Fragment>
        <p>Are you sure you want to delete the contact information for {contact.first_name} {contact.last_name} ({contact.email})?</p>
        <p>This does not prevent {contact.first_name} from accessing previously shared envelopes.</p>
      </React.Fragment>}
      {contact.contact_has_received_documents && <p>
        {contact.first_name} {contact.last_name} ({contact.email}) can not be deleted at this time because they have previously received envelopes.
      </p>}
    </ContactDeleteModal>
  }

  let editContact
  if (showContactEditFormModal) {
    editContact = <ContactModalForm
      alreadyExistingEmails={alreadyExistingEmails}
      agreementSlug={agreementSlug}
      cancelAction={() => setContactEditFormModal(false)}
      company={company}
      contact={contact}
      deleteAction={openDeleteContactModal}
      onSave={onContactUpdate}
      title={"Edit Contact"} />
  }

  let accessLink
  if (showContactAccessLinkModal) {
    accessLink = <ContactAccessLinkModal
      agreementSlug={agreementSlug}
      cancelAction={() => setContactAccessLinkModal(false)}
      contact={contact}
      onSent={() => setContactAccessLinkModal(false)}
      partnerSlug={partnerSlug}
      title="Send Access Pass">
      By sending an access pass, {contact.first_name} {contact.last_name} will be able to access all envelopes shared with {partnerName}.
    </ContactAccessLinkModal>
  }

  const openEditContactModal = () => {
    clicked()
    setContactEditFormModal(true)
  }

  const openDeleteContactModal = () => {
    clicked()
    setContactEditFormModal(false)
    setContactDeleteModal(true)
  }

  const openSendAccessLinkModal = () => {
    clicked()
    setContactAccessLinkModal(true)
  }

  // <a onClick={openEditContactModal} className="dropdown-item">Edit Contact</a>

  return (
    <div className={`dropdown ${show ? 'is-active' : ''}`}>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content" style={{ backgroundColor: '#FFFFFF' }}>
          <a onClick={openSendAccessLinkModal} className="dropdown-item">Send Access Pass</a>
          <a onClick={openDeleteContactModal} className="dropdown-item is-danger">Delete Contact</a>
        </div>
      </div>
      {accessLink}
      {editContact}
      {deleteContact}
    </div>
  )
}

export default ContactDropdown
