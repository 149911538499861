const sortByReversedKey = ({
    object,
    reversed = false
}) => {
    let sortedObject = Object.keys(object).sort()

    if (reversed) {
        sortedObject = sortedObject.reverse()
    }

    return sortedObject.reduce(
        (obj, key) => {
            obj[key] = object[key]
            return obj
        },
        {}
    )
}

export default {
    sortByReversedKey
}