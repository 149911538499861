import Cookies from 'js-cookie'

export const storeTopScroll = domId => {
  const position = { 'top': {} }
  position['top'][`#${domId}`] = $(`#${domId}`).scrollTop()
  Cookies.set('scroll', JSON.stringify(position))
}

export const restoreTopScroll = (domId) => {
  let didScroll = false
  let scroll;
  try {
    scroll = JSON.parse(Cookies.get('scroll'))
  } catch (error) {
    scroll = {}
  }
  if (scroll['top']) {
    Object.entries(scroll['top']).forEach(position => {
      let container = position[0];
      let scroll = position[1];
      if (container == `#${domId}`) {
        didScroll = true
        const dom = container == 'document' ? $(document) : $(container);
        dom.scrollTop(scroll);
        setTimeout(function () {
          dom.scrollTop(scroll);
        }, 1)
      }
    })
  }
  Cookies.set('scroll', JSON.stringify({ 'top': null }));
  return didScroll
}

