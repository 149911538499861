import React from "react"

const Radio = ({
    label,
    name,
    options,
    onChange = (value) => { },
}) => {
    return <div className="field">
        <div className="control">
            <label className="label">{label}</label>

            {options.map((option) => (<React.Fragment
                key={`${name}_${option.value}`}>
                <label
                    className="pure-material-radio is-small"
                    htmlFor={`${name}_${option.value}`}>
                    <input type="radio"
                        defaultChecked={option.checked}
                        disabled={option.disabled}
                        id={`${name}_${option.value}`}
                        name={name}
                        onChange={() => onChange(option.value)}
                        style={{ opacity: (option.disabled ? 0.5 : 1) }}
                        value={option.value} />
                    <span style={{ opacity: (option.disabled ? 0.5 : 1) }}>
                        {option.label}
                    </span>
                </label>
                <br />
            </React.Fragment>))}
        </div>
    </div>
}

export default Radio
