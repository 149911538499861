import React, { useEffect, useState } from "react"

import Company from "./Company"
import CompanyFooter from "./CompanyFooter"
import CompanyList from "../CompanyList"
import CompanyListTips from "../CompanyList/CompanyListTips"

const CompanyManager = ({
  currentCompanySlug: initialCurrentCompanySlug,
  userIsAnonymous
}) => {
  const [currentCompanySlug, setCurrentCompanySlug] = useState(initialCurrentCompanySlug)

  const onCompanySelect = (companySlug) => {
    const url = `/companies/${companySlug}${document.location.search}`
    window.history.pushState({ companySlug: companySlug }, '', url)
    setCurrentCompanySlug(companySlug)
  }

  useEffect(() => {
    const setWindowLocation = (e) => {
      if (e.state) {
        setCurrentCompanySlug(e.state.companySlug)
      }
    }

    window.addEventListener('popstate', setWindowLocation)

    return () => {
      window.removeEventListener('popstate', setWindowLocation)
    }
  }, [])

  return <>
    {!userIsAnonymous && <CompanyList
      currentCompanySlug={currentCompanySlug}
      onCompanySelect={companySlug => onCompanySelect(companySlug)} />}

    {currentCompanySlug && <Company
      currentCompanySlug={currentCompanySlug}
      userIsAnonymous={userIsAnonymous} />}
    {!currentCompanySlug && <CompanyListTips />}

    {userIsAnonymous && <CompanyFooter />}
    {userIsAnonymous && <div id="modal-container" style={{ position: "absolute", zIndex: 100000 }}></div>}
  </>
}

export default CompanyManager
