import React, { useMemo, useState } from "react"
import ReactDOM from 'react-dom'
import Turbolinks from "turbolinks"

import SharePackageModalForm from './SharePackageModalForm'
import UserPlanLimitReachBtn from '../Plans/UserPlanLimitReachBtn'
import utils from '../utils/utils'

const Request = ({
    admins,
    adminEmails,
    buttonTitle,
    isAdmin,
    packageCount,
    partner,
    planStatus,
    requestedPackageIds,
    requestSlug,
    title,
    type,
}) => {
    const [showSharePackageModalForm, setShowSharePackageModalForm] = useState(false)

    const refreshCurrentPage = () => {
        Turbolinks.visit(window.location.toString(), { action: 'replace' })
    }

    const sharePackageModalForm = useMemo(() => {
        if (!showSharePackageModalForm) {
            return
        }

        return ReactDOM.createPortal(
            <SharePackageModalForm
                onCancel={() => setShowSharePackageModalForm(false)}
                onSave={() => {
                    fetch(`/requests/${requestSlug}`, {
                        method: 'PUT',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': utils.csrfToken()
                        }
                    }).then(() => refreshCurrentPage())
                }}
                filterPackageByIds={requestedPackageIds}
                packageCount={packageCount}
                partner={partner} />,
            document.getElementById('modal-container')
        )
    }, [showSharePackageModalForm])

    return (
        <div className="content">
            <div className="columns">
                <div className="column">
                    <h3 style={{ color: '#16232f', fontSize: '26px', fontWeight: 'bold' }}>
                        {title}
                    </h3>
                </div>
                {buttonTitle && <div className="column has-text-right">
                    {planStatus.reached && <UserPlanLimitReachBtn
                        type={type}
                        limit={planStatus.limit}
                        admins={admins}
                        adminEmails={adminEmails}
                        isAdmin={isAdmin} />}
                    {!planStatus.reached && <a className="button is-primary"
                        onClick={() => {
                            setShowSharePackageModalForm(!showSharePackageModalForm)
                        }}
                        style={{ fontWeight: 'bold', lineHeight: '48px' }}>
                        {buttonTitle}
                    </a>}
                    {sharePackageModalForm}
                </div>}
            </div>
        </div>)
}

export default Request
