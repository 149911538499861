import React, { useEffect, useState } from "react"
import ReactDOM from 'react-dom'
import { TailSpin } from "react-loader-spinner"

import utils from '../utils/utils'

import Modal from './../Modal'
import PlanDetails from './PlanDetails'
import PlanRow from './PlanRow'
import ShareResult from './ShareResult'
import StripeCreditCardForm from './StripeCreditCardForm'

const PlanModalForm = (props) => {
  const cancel = props.cancel
  const onSave = props.onSave

  const [currentPlan, setCurrentPlan] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [stripeCreditCardFormValid, setStripeCreditCardFormValid] = useState(false)
  const [companyHasValidCreditCard, setCompanyHasValidCreditCard] = useState(false)

  const [plans, setPlans] = useState([])
  const [usage, setUsage] = useState(null)

  const fetchData = () => {
    fetch('/settings/billing/plan_data')
      .then((response) => response.json())
      .then((responseData) => {
        let defaultSelectedPlan = responseData.plans.find((plan) => {
          return (plan.id === responseData.currentPlan.id || plan.annual_plan?.id === responseData.currentPlan.id) && plan.billing == "monthly"
        })

        if (!defaultSelectedPlan) {
          defaultSelectedPlan = responseData.plans.filter(plan => planAvailable(plan, responseData.usage) && plan.billing == "monthly")[0]
        }

        // order matters
        setSelectedPlan(defaultSelectedPlan)
        setCurrentPlan(responseData.currentPlan)
        setUsage(responseData.usage)
        setPlans(responseData.plans)
        setCompanyHasValidCreditCard(responseData.companyHasValidCreditCard)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const planAvailable = (plan, usage) => (
    (plan.agreement_count == 0 || plan.agreement_count >= usage.agreement_resources_count) &&
    (plan.document_count == 0 || plan.document_count >= usage.document_resources_count) &&
    (plan.share_count == 0 || plan.share_count >= usage.share_count)
  )

  const [isSending, setIsSending] = useState(false)
  const [step, setStep] = useState('plans')
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [electedPlan, setElectedPlan] = useState(null)

  let cancelButton
  let saveButton

  if (selectedPlan && (!electedPlan || [selectedPlan.id, selectedPlan.annual_plan?.id].indexOf(electedPlan.id) == -1)) {
    setElectedPlan(selectedPlan)
  }

  const planList = !isLoading ? plans.filter((plan) => plan.billing === 'monthly').sort((plan1, plan2) => {
    return plan1.price > plan2.price ? 1 : -1
  }).map((plan, index) => {
    const isCurrentPlan = plan.id === currentPlan.id || plan.annual_plan?.id === currentPlan.id
    return <PlanRow available={planAvailable(plan, usage)}
      checked={selectedPlan && selectedPlan.id === plan.id}
      isCurrentPlan={isCurrentPlan}
      key={index}
      plan={plan}
      onPlanSelected={(plan) => setSelectedPlan(plan)} />
  }) : <div>
    <PlanRow available={false} checked={false} isCurrentPlan={false} key={0} />
    <PlanRow available={false} checked={false} isCurrentPlan={false} key={1} />
    <PlanRow available={false} checked={false} isCurrentPlan={false} key={2} />
    <PlanRow available={false} checked={false} isCurrentPlan={false} key={3} />
    <PlanRow available={false} checked={false} isCurrentPlan={false} key={4} />
  </div>

  const planDetails = step === 'plan_details' && <PlanDetails currentPlan={currentPlan}
    selectedPlan={selectedPlan}
    electedPlan={electedPlan}
    setElectedPlan={setElectedPlan} />

  if (step === 'confirm') {
    saveButton = <button className="button is-info"
      disabled={isSending}
      draggable="false"
      style={{ width: '126px' }}
      onClick={() => applyPlan(electedPlan)}>
      {isSending ? <TailSpin color="#FFFFFF" height={24} width={24} /> : 'Update Now'}
    </button>
  }

  if (step === 'plan_details') {
    if (currentPlan.id != electedPlan.id) {
      saveButton = <button
        className="button is-info"
        disabled={isSending}
        draggable="false"
        onClick={() => {
          companyHasValidCreditCard ? setStep('confirm') : setStep('billing_details')
        }}>Next</button>
    } else {
      saveButton = <button className="button disabled" disabled={true} draggable="false">Next</button>
    }
  }

  if (step === 'plans') {
    saveButton = <a className={`button is-info`}
      draggable="false"
      onClick={() => setStep('plan_details')}>Next</a>
  }

  const stripeFormID = "billing-details-modal-form"
  if (step === 'billing_details') {
    saveButton = <input className={`button is-info ${stripeCreditCardFormValid ? '' : 'disabled'}`}
      form={stripeFormID}
      type="submit"
      value="Next" />
  }

  if (step === 'done') {
    cancelButton = true
    saveButton = <a className="button is-info"
      draggable="false"
      onClick={() => onSave()}>Done</a>
  }

  const applyPlan = (electedPlan) => {
    setIsSending(true)

    fetch('/settings/billing/apply_plan', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      },
      body: JSON.stringify({ plan_id: electedPlan.id })
    }).then(() => {
      setIsSending(false)
      setStep('done')
    })
  }

  const content = () => {
    if (step === 'billing_details') {
      return <>Enter billing details<br /></>
    }
    if (step === 'plan_details') {
      return <>Review the {selectedPlan.name} plan and confirm your details:<br /><br /></>
    }
    if (step === 'plans') {
      return <>Choose your plan. Next, review plan details and confirm your update.<br /><br /></>
    }
  }

  const popupTitle = () => {
    if (step === 'billing_details') {
      return 'Add Billing Details'
    }

    if (step === 'done') {
      return 'Update Complete'
    }

    if (step === 'confirm') {
      return `Review Changes`
    }

    if (step === 'plan_details') {
      return `${selectedPlan.name} Plan`
    }

    return 'Select Plan'
  }

  const backButton = (step === 'plan_details' || step === 'confirm') && <a
    className="button"
    draggable="false"
    onClick={() => setStep(step === 'confirm' ? 'plan_details' : 'plans')}
    style={{ paddingLeft: '12px', position: 'absolute', left: '40px' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g fill="none" fillRule="evenodd">
        <path fill="#F4F5F5" d="M-633-21H807v1470H-633z" />
        <path fill="#FFF" d="M-32-21h839v60H-32z" />
        <g transform="translate(-12 -9)">
          <rect width="89" height="35" x=".5" y=".5" stroke="#CED0D2" rx="6" />
          <path fill="#424C53" d="M27 17.25h-9.128l4.193-4.192L21 12l-6 6 6 6 1.057-1.057-4.185-4.193H27z" />
        </g>
      </g>
    </svg>
    <span style={{ paddingLeft: '10px' }}>Back</span>
  </a>

  return ReactDOM.createPortal(
    <Modal cancelAction={step === 'done' ? onSave : cancel}
      cancelButton={cancelButton}
      confirmButton={saveButton}
      deleteButton={backButton}
      disableBackgroundCancelAction={step === 'billing_details'}
      title={popupTitle()}
      modalCardStyle={{ minHeight: '620px', minWidth: '700px' }}
      modalCardBodyStyle={{ padding: 0 }}>
      <div className="content" style={{ overflow: 'hidden' }}>
        <div className="columns">
          <div className="column document-list">
            {content()}
            {step === 'loading' && <div className="action-selector">{planList}</div>}
            {step === 'plans' && <div className="action-selector">{planList}</div>}
            {step === 'plan_details' && planDetails}
            {step === 'billing_details' && <>
              <h3 className="is-column-title">Billing Details:</h3>
              <StripeCreditCardForm
                id={stripeFormID}
                onCreditCardUpdated={() => setStep('confirm')}
                onFormChange={(valid) => setStripeCreditCardFormValid(valid)} />
            </>}
            {step === 'confirm' && <ShareResult electedPlan={electedPlan} />}
            {step === 'done' && <ShareResult />}
          </div>
        </div>
      </div>
    </Modal>,
    document.getElementById('modal-container')
  )
}

export default PlanModalForm
