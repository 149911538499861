import React from "react"

import Modal from './../Modal'

const UserPlanLimitReachModal = (props) => {
    const admins = props.admins
    const contentOverride = props.contentOverride
    const isAdmin = props.isAdmin
    const limit = props.limit
    const logoOverride = props.logoOverride

    let action
    let title
    let type = props.type

    switch (type) {
        case 'agreement':
            action = 'add an agreement'
            title = 'Add Agreement'
            break;
        case 'document':
            action = 'add a document'
            title = 'Add Document'
            break;
        case 'share':
            action = 'send an envelope'
            title = 'Send Envelope'
            break;
        case 'request':
            action = 'approve and send'
            title = 'Review & Send'
            type = 'share'
            break;
        default:
            action = null
            title = 'Action Needed'
            type = 'share'
            break;
    }

    return <Modal {...props} title={title}>
        <div className="content">
            <div className="columns">
                <div className="column">
                    <div className="has-text-centered" style={{ paddingTop: (!isAdmin && admins.length) ? '80px' : 0 }}>
                        {logoOverride}
                        {!logoOverride && <svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1">
                            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Modal-Graphics-/-Upgrade" fill="#2BC9B4" fillRule="nonzero">
                                    <path d="M36,0 C55.882251,0 72,16.117749 72,36 C72,55.882251 55.882251,72 36,72 C16.117749,72 0,55.882251 0,36 C0,16.117749 16.117749,0 36,0 Z M36,4 C18.326888,4 4,18.326888 4,36 C4,53.673112 18.326888,68 36,68 C53.673112,68 68,53.673112 68,36 C68,18.326888 53.673112,4 36,4 Z M46.57,42.714 L50.8,54 L43.852,51.21 C45.112,48.456 46.012,45.612 46.57,42.714 Z M26.23,42.714 C26.788,45.612 27.688,48.456 28.948,51.21 L22,54 L26.23,42.714 Z M36.4,18 C36.4,18 45.4,21.6 45.4,36 C45.4,41.58 44.05,46.35 42.394,50.094 C41.8,51.39 40.522,52.2 39.1,52.2 L33.7,52.2 C32.278,52.2 31,51.39 30.406,50.094 C28.768,46.35 27.4,41.58 27.4,36 C27.4,21.6 36.4,18 36.4,18 Z M36.4,28.8 C34.42,28.8 32.8,30.42 32.8,32.4 C32.8,34.38 34.42,36 36.4,36 C38.38,36 40,34.38 40,32.4 C40,30.42 38.38,28.8 36.4,28.8 Z" id="Shape"></path>
                                </g>
                            </g>
                        </svg>}
                        <h2 style={{ fontSize: '26px', margin: '1rem 0 0 0' }}>Upgrade Plan</h2>
                        <p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '19px 0', marginTop: '40px' }}>
                            {contentOverride}
                            {!contentOverride && <>
                                Your team has reached its {type} limit ({limit}).
                                <br />
                                {isAdmin && `To ${action}, please upgrade your plan.`}
                                {!isAdmin && 'Contact your team admins to upgrade.'}
                            </>}
                        </p>
                        {(!isAdmin && admins.length > 0) && <p><br />Admins: {admins.join(', ')}</p>}
                    </div>
                </div>
            </div>
        </div>
    </Modal>
}

export default UserPlanLimitReachModal