import React, { useState } from "react"
import { useForm } from 'react-hook-form'

import SuccessContent from "components/ShareDocuments/SuccessContent"
import utils from 'components/utils/utils'

import Modal from "./../../Modal"
import TextField from "./../../inputs/TextField"


const WatermarkModalForm = ({
  cancelAction,
  selectedDocumentResourceIds,
}) => {
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)

  const { register, handleSubmit, errors, formState } = useForm({ mode: "onChange" })

  const bulkDownload = (data) => {
    setSending(true)

    data['document_resource_ids'] = selectedDocumentResourceIds

    fetch(`/bulk_watermarked_document_resources`, {
      method: 'post',
      body: JSON.stringify({ bulk_watermarked_document_resource: data }),
      headers: { "X-CSRF-Token": utils.csrfToken(), 'Content-type': 'application/json' }
    }).finally(() => setSent(true))
  }

  return (<form onSubmit={handleSubmit(bulkDownload)}>
    <Modal
      cancelAction={cancelAction}
      cancelButton={sent || <a className="button is-white" draggable="false" onClick={cancelAction}>Cancel</a>}
      confirmButton={!sent ? <input
        className={`button is-info submit-form-on-enter-key-press ${formState.isValid && !sending ? '' : 'disabled'}`}
        disabled={sending}
        draggable="false"
        type="submit"
        value="Watermark" /> : <a className="button is-info" draggable="false" onClick={cancelAction}>Close</a>}
      title="Watermark">
      
      {!sent && <>
        <p>Enter the details below to download a watermarked version that can be manually shared outside of Pima.</p>
        <br />
        <div className="columns">
          <div className="column">
            <TextField
              errors={errors}
              label="Company Name"
              name="company_name"
              placeholder="E.g., Acme Co"
              reff={register({ required: true })} />
          </div>
        </div>
        <div className="columns">
          <div className="column is-half">
            <TextField
              errors={errors}
              label="First Name"
              name="first_name"
              placeholder="E.g. Susan"
              reff={register({ required: true })} />
          </div>
          <div className="column is-half">
            <TextField
              errors={errors}
              label="Last Name"
              name="last_name"
              placeholder="E.g. Smith"
              reff={register({ required: true })} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <TextField
              errors={errors}
              label="Recipient Email"
              name="email"
              placeholder="E.g., name@company.com"
              reff={register({
                required: true,
                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })} />
          </div>
        </div>
      </>}
      {sent && <SuccessContent    
        content={`${selectedDocumentResourceIds.length} documents are being processed. A download link will been sent to your email when they are ready to download.`}
        title="Check your email!"
      />}
    </Modal>
  </form>)
}

export default WatermarkModalForm
