import React from "react"

import styles from "./style.module"

const Badge = ({
  badge,
}) => {
  return <div className={styles.Badge}>
    <div className={styles.icon} dangerouslySetInnerHTML={{ __html: badge.icon }}></div>
    {badge.name}
  </div>
}

export default Badge
