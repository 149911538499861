import React, { useEffect, useMemo, useState } from "react"
import ReactDOM from 'react-dom'

import { storeTopScroll, restoreTopScroll } from 'components/shared/scrollUtils'

import PackageForm from '../PackageForm'

const PackageList = ({
  isAdmin,
  canEditGroups,
  groupOptions,
  packages,
  intialPackageSlug,
  requestCenters,
}) => {
  const packageListId = 'package-name-list'
  const [currentPackageSlug, setCurrentPackageSlug] = useState(intialPackageSlug)
  const [searchValue, setSearchValue] = useState(null)
  const [showSearch, setShowSearch] = useState(false)

  const [showPackageForm, setShowPackageForm] = useState(() => {
    return window.location.href.indexOf('action=create') > -1
  })

  const navigateToPackage = (link, slug) => {
    setCurrentPackageSlug(slug)
    storeTopScroll(packageListId)
    Turbolinks.visit(link)
  }

  useEffect(() => {
    restoreTopScroll(packageListId)
  }, [])

  const packageForm = useMemo(() => {
    if (!showPackageForm) {
      return
    }

    return ReactDOM.createPortal(
      <PackageForm
        canEditGroups={canEditGroups}
        groupOptions={groupOptions}
        onCancel={() => setShowPackageForm(false)}
        requestCenters={requestCenters} />,
      document.getElementById('modal-container')
    )
  }, [showPackageForm])

  const packageList = useMemo(() => {
    return packages.filter(packageData => {
      if (searchValue == '' || !searchValue) {
        return true
      }

      return packageData.name.toUpperCase().includes(searchValue.toUpperCase())
    })
      .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
      .map(packageData => {
        return <a className={`button is-cell has-text-weight-bold ${currentPackageSlug === packageData.slug ? 'is-active' : ''}`}
          draggable="false"
          key={`package-${packageData.slug}`}
          onClick={() => navigateToPackage(`/packages/${packageData.slug}`, packageData.slug)}>
          {packageData.name}
        </a>
      })
  }, [packages, searchValue])

  return <>
    <div className="content is-title-block" style={{ padding: showSearch ? '11px' : '18px 32px' }}>
      {!showSearch && <a style={{ position: 'absolute', left: '17px' }} onClick={() => setShowSearch(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="#424C53" fillRule="evenodd" d="M15.5,14 L14.71,14 L14.43,13.73 C15.41,12.59 16,11.11 16,9.5 C16,5.91 13.09,3 9.5,3 C5.91,3 3,5.91 3,9.5 C3,13.09 5.91,16 9.5,16 C11.11,16 12.59,15.41 13.73,14.43 L14,14.71 L14,15.5 L19,20.49 L20.49,19 L15.5,14 Z M9.5,14 C7.01,14 5,11.99 5,9.5 C5,7.01 7.01,5 9.5,5 C11.99,5 14,7.01 14,9.5 C14,11.99 11.99,14 9.5,14 Z" />
        </svg>
      </a>}

      {showSearch && <div style={{ display: 'flex' }}>
        <svg style={{ position: 'absolute', zIndex: 10, top: '18px', left: '17px' }}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24">
          <path fill="#424C53" fillRule="evenodd" d="M15.5,14 L14.71,14 L14.43,13.73 C15.41,12.59 16,11.11 16,9.5 C16,5.91 13.09,3 9.5,3 C5.91,3 3,5.91 3,9.5 C3,13.09 5.91,16 9.5,16 C11.11,16 12.59,15.41 13.73,14.43 L14,14.71 L14,15.5 L19,20.49 L20.49,19 L15.5,14 Z M9.5,14 C7.01,14 5,11.99 5,9.5 C5,7.01 7.01,5 9.5,5 C11.99,5 14,7.01 14,9.5 C14,11.99 11.99,14 9.5,14 Z" />
        </svg>
        <input
          autoComplete="off"
          className="input"
          type="text"
          name="search-package"
          placeholder="Search packages..."
          onChange={(e) => setSearchValue(e.target.value)}
          style={{ paddingLeft: '38px', height: '36px', marginLeft: 0 }}
          autoFocus="1" />
        <a style={{ paddingTop: '5px', paddingLeft: '14px', color: '#18242d' }} onClick={() => {
          setShowSearch(false)
          setSearchValue(null)
        }}>Cancel</a>
      </div>}

      {!showSearch && 'Packages'}
      {isAdmin && !showSearch && <a className="button is-primary" style={{ top: 'calc(50% - 20px)', right: '8px' }} onClick={() => setShowPackageForm(true)}>Add</a>}
    </div>

    <div className="content is-cell-list" id={packageListId}>
      {packageList}
      {(!packageList || packageList.length == 0) && showSearch && <p className="has-text-centered is-empty-list-text">No results</p>}
      {(!packageList || packageList.length == 0) && isAdmin && !showSearch && <p className="has-text-centered is-empty-list-text">Nothing here... yet.</p>}
      {!isAdmin && !showSearch && <p className="has-text-centered is-empty-list-text">Only admins can create packages.</p>}
    </div>

    {packageForm}
  </ >
}

export default PackageList
