import React from "react"

import Modal from "./../Modal"
import utils from '../utils/utils'

const ContactDeleteModal = (props) => {
  const agreementSlug = props.agreementSlug
  const contact = props.contact
  const onDelete = props.onDelete

  function confirmAction() {
    if (contact.contact_has_received_documents) {
      onDelete(false)
      return
    }

    fetch(`/companies/${agreementSlug}/contacts/${contact.id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }).then(response => onDelete(contact))
  }

  return <Modal
    {...props}
    confirmAction={confirmAction}
    confirmButtonText={contact.contact_has_received_documents ? 'Close' : 'Delete'} />
}

export default ContactDeleteModal
