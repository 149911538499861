import React from "react"
import NumberFormat from 'react-number-format'

const PlanDetails = (props) => {
  const currentPlan = props.currentPlan
  const electedPlan = props.electedPlan
  const selectedPlan = props.selectedPlan
  const setElectedPlan = props.setElectedPlan

  return <div>
    <div className="columns">
      <div className="column">
        <div className="message">
          <div className="columns">
            <div className="column" style={{ paddingTop: '24px', maxWidth: '40px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="#8C9296" fillRule="evenodd" d="M14 9V5l7 7-7 7v-4.1c-5 0-8.5 1.6-11 5.1 1-5 4-10 11-11z" />
              </svg>
            </div>
            <div className="column">
              <b>Share</b>
              <br />
              {selectedPlan.share_count}
            </div>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="message">
          <div className="columns">
            <div className="column" style={{ paddingTop: '24px', maxWidth: '40px' }}>
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path d="M6,2 L14,2 L20,8 L20,20 C20,21.1045695 19.1045695,22 18,22 L6,22 C4.89,22 4,21.1 4,20 L4,4 C4,2.89 4.89,2 6,2 L6,2 Z M15,18 L15,16 L6,16 L6,18 L15,18 L15,18 Z M18,14 L18,12 L6,12 L6,14 L18,14 Z M16,10 L16,8 L6,8 L6,10 L16,10 Z" fill="#8C9296" fillRule="evenodd" />
              </svg>
            </div>
            <div className="column">
              <b>Documents</b>
              <br />
              {selectedPlan.document_count}
            </div>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="message">
          <div className="columns">
            <div className="column" style={{ paddingTop: '24px', maxWidth: '40px' }}>
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path d="M22,19 L22,21 L2,21 L2,19 L22,19 Z M13.6010276,5.48271073 L16.5172893,8.39897236 L7.91626163,17 L5,17 L5,14.0837384 L13.6010276,5.48271073 Z M15.85627,3.22746841 C16.1595612,2.9241772 16.6502708,2.9241772 16.9527843,3.22746841 L16.9527843,3.22746841 L18.7725316,5.04721566 C19.0758228,5.35050687 19.0758228,5.84043883 18.7725316,6.14373004 L18.7725316,6.14373004 L17.3493959,7.56686571 L14.4331343,4.65060408 Z" fill="#8C9296" fillRule="evenodd" />
              </svg>
            </div>
            <div className="column">
              <b>Agreements</b>
              <br />
              {selectedPlan.agreement_count}
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div className="columns">
      <div className="column">
        <div className="action-selector">
          <div className="is-relative">
            <input style={{ left: '33px', top: '33px' }} type="radio" checked={electedPlan.id === selectedPlan.id} id={`plan_${selectedPlan.name}_monthly`} name="action" value={`plan_${selectedPlan.name}`} onChange={() => setElectedPlan(selectedPlan)} />
            <label style={{ borderWidth: electedPlan.id === selectedPlan.id ? '2px' : '1px', borderColor: electedPlan.id === selectedPlan.id ? '#0075e0' : '#ced0d2', padding: electedPlan.id === selectedPlan.id ? "14px 0 0 69px" : "15px 0 0 70px", borderRadius: '8px', height: '80px', marginBottom: '10px' }} className="button" htmlFor={`plan_${selectedPlan.name}_monthly`}>
              <strong>
                {selectedPlan.price ? <NumberFormat value={selectedPlan.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : 'Free'}
                {currentPlan.id == selectedPlan.id ? ' (Current)' : ''}
              </strong>
              <br />
              <small style={{ fontSize: '14px', fontWeight: 'normal' }}>
                {selectedPlan.price ? 'Billed Monthly' : 'No monthly charge'}
              </small>
            </label>
          </div>
        </div>
      </div>
      <div className="column">
        {selectedPlan.annual_plan && <div className="action-selector">
          <div className="is-relative">
            <input style={{ left: '33px', top: '33px' }} type="radio" checked={electedPlan.id === selectedPlan.annual_plan.id} id={`plan_${selectedPlan.annual_plan.name}_yearly`} name="action" value={`plan_${selectedPlan.annual_plan.name}`} onChange={() => setElectedPlan(selectedPlan.annual_plan)} />
            <label style={{ borderWidth: electedPlan.id === selectedPlan.annual_plan.id ? '2px' : '1px', borderColor: electedPlan.id === selectedPlan.annual_plan.id ? '#0075e0' : '#ced0d2', padding: electedPlan.id === selectedPlan.annual_plan.id ? "14px 0 0 69px" : "15px 0 0 70px", borderRadius: '8px', height: '80px', marginBottom: '10px' }} className="button" htmlFor={`plan_${selectedPlan.annual_plan.name}_yearly`}>
              <strong>
                <NumberFormat value={selectedPlan.annual_plan.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                {currentPlan.id == selectedPlan.annual_plan.id ? ' (Current)' : ''}
              </strong>
              <br />
              <small style={{ fontSize: '14px', fontWeight: 'normal' }}>
                Billed Annually (10% discount)
              </small>
            </label>
          </div>
        </div>}
      </div>
    </div>
    <p className="has-text-centered" style={{ fontSize: '14px' }}>Have questions? <a href="mailto:sales@pima.app" target="_blank">Contact sales</a></p>
  </div>
}

export default PlanDetails
