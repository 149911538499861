import React, { useEffect, useMemo, useState } from "react"
import ReactDOM from 'react-dom'

import CompanyForm from '../CompanyForm'

const CompanyList = ({
  currentCompanySlug: intialCompanySlug = false,
  onCompanySelect = () => { }
}) => {
  const companyListId = 'company-name-list'
  const archivedCompanyList = useMemo(() => new URLSearchParams(window.location.search).has('archived'), [window.location.search])
  const [loadingCompanies, setLoadingCompanies] = useState(true)
  const [companies, setCompanies] = useState([])
  const [currentCompanySlug, setCurrentCompanySlug] = useState(intialCompanySlug)
  const [searchValue, setSearchValue] = useState(null)
  const [showSearch, setShowSearch] = useState(false)

  const [showCompanyForm, setShowCompanyForm] = useState(() => {
    return window.location.href.indexOf('action=create') > -1
  })

  const navigateToCompany = (link, companySlug) => {
    setCurrentCompanySlug(companySlug)
    onCompanySelect(companySlug)
  }

  useEffect(() => {
    setCurrentCompanySlug(intialCompanySlug)
  }, [intialCompanySlug])

  const urlParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const urlParams = Object.fromEntries(urlSearchParams.entries())
    if (urlParams['full_name'] && !urlParams['first_name'] && !urlParams['last_name']) {
      const fullName = urlParams['full_name'].split(' ')
      if (fullName.length > 1) {
        urlParams['first_name'] = fullName[0]
        urlParams['last_name'] = fullName[1]
      } else {
        urlParams['first_name'] = urlParams['full_name']
      }
    }
    return urlParams
  }, [])

  const companyForm = useMemo(() => {
    if (!showCompanyForm) {
      return
    }

    return ReactDOM.createPortal(
      <CompanyForm
        companyName={urlParams['company_name']}
        firstName={urlParams['first_name']}
        lastName={urlParams['last_name']}
        email={urlParams['email']}
        onCancel={() => setShowCompanyForm(false)} />,
      document.getElementById('modal-container')
    )
  }, [showCompanyForm])

  useEffect(() => {
    fetch(`/companies.json${document.location.search}`)
      .then(response => response.json())
      .then(companies => {
        setCompanies(companies)
        setLoadingCompanies(false)
        setTimeout(() => {
          scrollToActive()
        }, 200)
      })
  }, [])

  const scrollToActive = () => {
    const companyList = $(`#${companyListId}`)
    const activeCompany = companyList.find('.is-active')
    const topPosition = activeCompany?.position()?.top

    if (topPosition) {
      companyList.scrollTop(topPosition);
    }
  }

  const companyList = useMemo(() => (
    companies.filter((company) => {
      if (searchValue == '' || !searchValue) {
        return true
      }

      return company.name.toUpperCase().includes(searchValue.toUpperCase()) || company.domain.toUpperCase().includes(searchValue.toUpperCase())
    })
      .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
      .map(company => {
        return <a className={`button is-cell has-text-weight-bold ${currentCompanySlug === company.slug ? 'is-active' : ''}`}
          draggable="false"
          key={`company-${company.id}`}
          onClick={() => navigateToCompany(`/companies/${company.slug}${archivedCompanyList ? '?archived=1' : ''}`, company.slug)}>
          {company.name}
        </a>
      })
  ), [archivedCompanyList, currentCompanySlug, companies, searchValue])

  return <>
    <div className="column is-narrow is-cell-list-column" style={{ minHeight: '100vh' }}>
      <div className="content is-title-block" style={{ padding: showSearch ? '11px' : '18px 32px' }}>
        {archivedCompanyList && <a href="/companies" style={{ position: 'absolute', left: '5px', top: '16px' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="#555" fillRule="evenodd" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6z" />
          </svg>
        </a>}
        {!showSearch && <a style={{ position: 'absolute', left: archivedCompanyList ? '37px' : '17px' }} onClick={() => setShowSearch(true)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path fill="#424C53" fillRule="evenodd" d="M15.5,14 L14.71,14 L14.43,13.73 C15.41,12.59 16,11.11 16,9.5 C16,5.91 13.09,3 9.5,3 C5.91,3 3,5.91 3,9.5 C3,13.09 5.91,16 9.5,16 C11.11,16 12.59,15.41 13.73,14.43 L14,14.71 L14,15.5 L19,20.49 L20.49,19 L15.5,14 Z M9.5,14 C7.01,14 5,11.99 5,9.5 C5,7.01 7.01,5 9.5,5 C11.99,5 14,7.01 14,9.5 C14,11.99 11.99,14 9.5,14 Z" />
          </svg>
        </a>}

        {showSearch && <div style={{ display: 'flex' }}>
          <svg style={{ position: 'absolute', zIndex: 10, top: '18px', left: archivedCompanyList ? '37px' : '17px' }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path fill="#424C53" fillRule="evenodd" d="M15.5,14 L14.71,14 L14.43,13.73 C15.41,12.59 16,11.11 16,9.5 C16,5.91 13.09,3 9.5,3 C5.91,3 3,5.91 3,9.5 C3,13.09 5.91,16 9.5,16 C11.11,16 12.59,15.41 13.73,14.43 L14,14.71 L14,15.5 L19,20.49 L20.49,19 L15.5,14 Z M9.5,14 C7.01,14 5,11.99 5,9.5 C5,7.01 7.01,5 9.5,5 C11.99,5 14,7.01 14,9.5 C14,11.99 11.99,14 9.5,14 Z" />
          </svg>
          <input
            autoComplete="off"
            className="input"
            type="text"
            name="search-company"
            placeholder="Search companies..."
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ paddingLeft: '38px', height: '36px', marginLeft: archivedCompanyList ? '16px' : 0 }}
            autoFocus="1" />
          <a style={{ paddingTop: '5px', paddingLeft: '14px', color: '#18242d' }} onClick={() => {
            setShowSearch(false)
            setSearchValue(null)
          }}>Cancel</a>
        </div>}

        {!showSearch && (archivedCompanyList ? 'Archived' : 'Companies')}
        {!showSearch && !archivedCompanyList && <a className="button is-primary" style={{ top: 'calc(50% - 20px)', right: '8px' }} onClick={() => setShowCompanyForm(true)}>Add</a>}
      </div>

      {loadingCompanies && <div className={`content is-cell-list ${!archivedCompanyList ? 'with-archive-cell' : ''}`}>
        {Array(4).fill().map((packageIndex, index) => (
          <div key={index} style={{ position: 'relative' }}>
            <div className="message loading-row loading-row-light" style={{ border: 'solid 1px #ced0d2', borderRadius: '8px', height: '72px', marginBottom: '8px' }}></div>
          </div>
        ))}
        <p className="has-text-centered is-empty-list-text">We are loading your data.</p>
      </div>}

      {!loadingCompanies && <div className={`content is-cell-list ${!archivedCompanyList ? 'with-archive-cell' : ''}`} id={companyListId}>
        {companyList}
        {(!companyList || companyList.length == 0) && <p className="has-text-centered is-empty-list-text">No {showSearch ? 'results' : 'companies'}</p>}
      </div>}

      {!archivedCompanyList && <p className="has-text-centered"
        style={{ padding: '30px 0px', position: 'absolute', bottom: 0, width: '100%', backgroundColor: '#f4f5f5' }}>
        <a className="button is-primary is-outlined is-gray"
          href="/companies?archived=1">
          View Archived Companies
        </a>
      </p>}
      {companyForm}
    </div>
  </>
}

export default CompanyList
