import React, { useMemo, useState } from "react"
import ReactDOM from 'react-dom'

import Modal from "components/Modal"

import RequestFormEditForm from '../RequestFormEditForm'
import RequestFormEdit from '../RequestFormEdit'
import RequestForm from '../RequestForm'

const RequestFormShow = ({
  companyHasRequestCenters = false,
  companyLogo,
  companyName,
  companySlug,
  isAdmin,
  isOwnRequestForm,
  requestForm: initialRequestForm,
}) => {
  const [displayUpdatedRequestFormModal, setDisplayUpdatedRequestFormModal] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.has('slug_updated')
  })
  const [displayUpdatedFromRequestCenterToRequestFormModal, setDisplayUpdatedFromRequestCenterToRequestFormModal] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.has('request_center_updated')
  })
  const [requestForm, setRequestForm] = useState(initialRequestForm)

  const closeModal = () => {
    window.history.pushState({}, document.title, window.location.pathname);
    setDisplayUpdatedRequestFormModal(false)
    setDisplayUpdatedFromRequestCenterToRequestFormModal(false)
  }

  const updatedRequestFormModal = useMemo(() => {
    if (!displayUpdatedRequestFormModal) return

    return ReactDOM.createPortal(
      <Modal
        cancelAction={closeModal}
        cancelButton={true}
        confirmAction={closeModal}
        modalCardStyle={{ minWidth: '750px' }}
        title={'Done!'} >
        <div className="content">
          <div className="columns">
            <div className="column document-list">
              <div className="has-text-centered" style={{ paddingTop: '80px' }}>
                <div style={{ border: 'solid 4px #2bc9b4', borderRadius: '50%', padding: '9px', width: '72px', height: '72px', margin: 'auto' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                    <path fill="#2BC9B4" fillRule="evenodd" d="M18 32.34L9.66 24 6.82 26.82 18 38 42 14 39.18 11.18z" />
                  </svg>
                </div>
                <h2 style={{ fontSize: '26px', margin: '1rem 0 0 0' }}>Request Form Updated</h2>
                <p>{requestForm.name}</p>
                <p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '12px 16px', maxWidth: '500px', marginTop: '40px' }}>
                  {`${window.location.protocol}//${window.location.hostname}/p/${companySlug}/${requestForm.slug}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      , document.getElementById('modal-container')
    )
  }, [displayUpdatedRequestFormModal])

  const updatedFromRequestCenterToRequestFormModal = useMemo(() => {
    if (!displayUpdatedFromRequestCenterToRequestFormModal) return

    const upgradeAnotherButton = companyHasRequestCenters && <a className="button is-outline is-pulled-left" draggable="false" style={{ position: 'absolute', left: '40px' }} href="/settings/request_centers">
      Upgrade another
    </a>

    return ReactDOM.createPortal(
      <Modal
        cancelAction={closeModal}
        cancelButton={true}
        deleteButton={upgradeAnotherButton}
        confirmButton={<a className="button is-info" onClick={() => closeModal()}>Done</a>}
        modalCardStyle={{ minWidth: '750px' }}
        title={'Upgraded'} >
        <div className="content">
          <div className="columns">
            <div className="column document-list">
              <div className="has-text-centered" style={{ paddingTop: '80px' }}>
                <div style={{ border: 'solid 4px #2bc9b4', borderRadius: '50%', padding: '9px', width: '72px', height: '72px', margin: 'auto' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                    <path fill="#2BC9B4" fillRule="evenodd" d="M18 32.34L9.66 24 6.82 26.82 18 38 42 14 39.18 11.18z" />
                  </svg>
                </div>
                <h2 style={{ fontSize: '26px', margin: '1rem 0 0 0' }}>Request center upgraded!</h2>
                <p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '12px 16px', maxWidth: '500px', marginTop: '40px' }}>
                  {`${window.location.protocol}//${window.location.hostname}/p/${companySlug}/${requestForm.slug}`}
                </p>
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      , document.getElementById('modal-container')
    )
  }, [displayUpdatedFromRequestCenterToRequestFormModal])

  return <>
    <div className="content is-title-block">
      Request Form
      {isAdmin && <RequestFormEditForm requestForm={requestForm} />}
    </div>

    <RequestFormEdit
      companySlug={companySlug}
      edit={isAdmin}
      requestForm={requestForm} />
    <RequestForm
      companyLogo={companyLogo}
      companyName={companyName}
      companySlug={companySlug}
      edit={isAdmin}
      isOwnRequestForm={isOwnRequestForm}
      onRequestFormChange={data => setRequestForm({ ...requestForm, packages: data.packages })}
      requestForm={requestForm}
    />
    {updatedRequestFormModal}
    {updatedFromRequestCenterToRequestFormModal}
  </>
}

export default RequestFormShow
