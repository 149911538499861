import React from "react"

import styles from './style.module'

const Label = ({ label, onSelect, selected }) => {
    const onLabelClick = () => {
        onSelect(label, !selected)
    }

    return (  
      <div 
        className={`${styles.label} ${selected ? styles.selected : ''}`} 
        onClick={onLabelClick}>
          {label}
      </div>
    )
}

export default Label