import React, { useState } from "react"
import { useForm } from 'react-hook-form'

import utils from '../../utils/utils'

import Modal from "../../Modal"
import TextField from "../../inputs/TextField"

const RequestFormForm = ({
  onCancel = () => { },
  onDelete = () => { },
  requestForm = null,
}) => {
  const [cachedData, setCachedData] = useState(null)
  const [step, setStep] = useState('form')
  const { register, handleSubmit, errors, formState } = useForm({ mode: "onChange" })

  const submitRequestForm = (data) => {
    fetch(`/request_forms${requestForm ? `/${requestForm.slug}` : ''}`, {
      method: requestForm ? 'PUT' : 'POST',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }).then(res => res.json())
      .then((requestFormData) => {
        Turbolinks.visit(`/request_forms/${requestFormData['request_form']['slug']}?slug_updated=1`)
      })
  }

  const confirmButton = <input
    className={`button is-info ${formState.isValid ? '' : 'disabled'}`}
    draggable="false"
    type="submit"
    value={requestForm ? (step == 'form' ? 'Continue' : 'Save') : 'Next'} />

  const deleteButton = <a
    className="button is-danger is-inverted is-pulled-left"
    draggable="false"
    onClick={() => onDelete()}
    style={{ position: 'absolute', left: '40px' }}>Delete</a>

  const confirmRequestFormEdit = (data) => {
    if (step == 'form') {
      setCachedData(data)
      setStep('confirm')
    } else {
      submitRequestForm(cachedData)
    }
  }

  return <form onSubmit={handleSubmit(requestForm ? confirmRequestFormEdit : submitRequestForm)}>
    <Modal
      confirmButton={confirmButton}
      cancelAction={onCancel}
      deleteButton={deleteButton}
      title={`${requestForm ? 'Edit' : 'Add'} Request Form`}
    >
      {step == 'form' && <div className="columns">
        <div className="column">
          <TextField
            defaultValue={`${requestForm ? requestForm.name : ''}`}
            errors={errors}
            label="Name"
            name="request_form[name]"
            placeholder="E.g., Security"
            reff={register({ required: true })} />
        </div>
      </div>}
      {step == 'confirm' && <p>Renaming this request form will change all access URLs and break any integrations.</p>}
    </Modal>
  </form>
}

export default RequestFormForm
