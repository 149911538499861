import React, { useMemo, useState } from "react"
import { useForm } from 'react-hook-form'

import Modal from "./../../Modal"
import TextField from "./../../inputs/TextField"

const WatermarkModalForm = ({
  cancelAction,
  documentSlug,
}) => {
  const [downloadPhase, setDownloadPhase] = useState(0)
  const [error, setError] = useState(false)

  const { register, handleSubmit, errors, formState } = useForm({ mode: "onChange" })

  const downloadPdf = (data) => {
    setDownloadPhase(1)

    const tokenDom = document.querySelector("meta[name=csrf-token]")
    const csrfToken = tokenDom ? tokenDom.content : ''

    fetch(`/documents/${documentSlug}/watermarked_document_resources`, {
      method: 'post',
      body: JSON.stringify({ watermarked_document_resource: data }),
      headers: { "X-CSRF-Token": csrfToken, 'Content-type': 'application/json' }
    }).then(response => {
      if (response.status == 422) {
        setError('PDF could not be generated, please try again.')
        return
      }
      const disposition = response.headers.get('Content-Disposition')
      const matches = /"([^"]*)"/.exec(disposition)
      const filename = (matches != null && matches[1] ? matches[1] : 'file.pdf')

      response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        a.remove()
        setDownloadPhase(2)
      })
    }).catch(error => setError(error))
  }

  let downloadButton = !error ? <input
    className={`button is-info submit-form-on-enter-key-press ${formState.isValid && downloadPhase == 0 ? '' : 'disabled'}`}
    disabled={downloadPhase == 1}
    draggable="false"
    type="submit"
    value="Download" /> : <a
      className="button is-info submit-form-on-enter-key-press"
      onClick={() => {
        setError(false)
        setDownloadPhase(0)
      }}>Try Again</a>

  if (downloadPhase == 2) {
    downloadButton = <a className="button is-info submit-form-on-enter-key-press" draggable="false" onClick={cancelAction}>Done</a>
  }

  let cancelButton = !error ? <a className="button is-white" draggable="false" onClick={cancelAction}>Cancel</a> : true

  if (downloadPhase == 2) {
    cancelButton = true
  }

  let modalContent = <p className="has-text-centered">
    Download Complete!
    <br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br />
  </p>

  if (downloadPhase == 0) {
    modalContent = <React.Fragment>
      <p>Enter the information to download a custom watermarked copy of this document to share manually.</p>
      <br />
      <div className="columns">
        <div className="column">
          <TextField
            errors={errors}
            label="Company Name"
            name="company_name"
            placeholder="E.g., Acme Co"
            reff={register({ required: true })} />
        </div>
      </div>
      <div className="columns">
        <div className="column is-half">
          <TextField
            errors={errors}
            label="First Name"
            name="first_name"
            placeholder="E.g. Susan"
            reff={register({ required: true })} />
        </div>
        <div className="column is-half">
          <TextField
            errors={errors}
            label="Last Name"
            name="last_name"
            placeholder="E.g. Smith"
            reff={register({ required: true })} />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <TextField
            errors={errors}
            label="Email"
            name="email"
            placeholder="E.g., name@company.com"
            reff={register({
              required: true,
              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })} />
        </div>
      </div>
    </React.Fragment>
  }

  if (downloadPhase == 1) {
    modalContent = !error ? <p className="has-text-centered">
      Preparing file...
      <br />
      Download will start automatically.
      <br /><br /><br /><br />
      <br /><br /><br /><br />
    </p> : <p className="has-text-centered" style={{ color: '#d92022' }}>
      {error}
      <br /><br /><br /><br />
      <br /><br /><br /><br />
    </p>
  }

  return (<form onSubmit={handleSubmit(downloadPdf)}>
    <Modal
      cancelAction={cancelAction}
      cancelButton={cancelButton}
      confirmButton={downloadButton}
      title="Watermark">
      {modalContent}
    </Modal>
  </form>)
}

export default WatermarkModalForm
