import React, { useMemo, useState } from "react"
import ReactDOM from 'react-dom'

import PackageForm from '../../PackageForm'
import PackageDeleteForm from '../../PackageDeleteForm'

const PackageEdit = ({
  canEditGroups,
  groupOptions,
  onSave,
  packageData,
  requestCenters
}) => {
  const [showEditForm, setShowEditForm] = useState(false)
  const [showDeleteForm, setShowDeleteForm] = useState(false)

  const packageForm = useMemo(() => {
    if (!showEditForm) {
      return
    }

    return ReactDOM.createPortal(
      <PackageForm 
        canEditGroups={canEditGroups}
        groupOptions={groupOptions}
        packageData={packageData} 
        onCancel={() => setShowEditForm(false)}
        onDelete={() => {
          setShowEditForm(false)
          setShowDeleteForm(true)
        }}
        onSave={(packageData) => {
          setShowEditForm(false)
          onSave(packageData)
        }}
        requestCenters={requestCenters} />,
      document.getElementById('modal-container')
    )
  }, [showEditForm])

  const packageDeleteForm = useMemo(() => {
    if (!showDeleteForm) {
      return
    }

    return ReactDOM.createPortal(
      <PackageDeleteForm 
        packageData={packageData} 
        onCancel={() => setShowDeleteForm(false)}/>,
      document.getElementById('modal-container')
    )
  }, [showEditForm, showDeleteForm])

  return <>
    <a 
      style={{ float: 'right', fontWeight: 700 }}
      onClick={(e) => setShowEditForm(true)}>
        Edit
    </a>
    {packageForm}
    {packageDeleteForm}
  </>
}

export default PackageEdit
