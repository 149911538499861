import React from "react"

import BaseToggleStep from "../BaseToggleStep"

const PackageStep = ({
  requestForm,
  onPanelClose = () => { },
  onRequestFormChange = () => { }
}) => {
  return <BaseToggleStep
    disabledSubtitle="No agreements or documents"
    labelAttr="name"
    onPanelClose={() => onPanelClose()}
    onRequestFormChange={requestForm => onRequestFormChange(requestForm)}
    paramSetUrl="/packages.json"
    requestForm={requestForm}
    requestFormAttr="packages"
    requestFormParam="package_ids"
    title="Packages" />
}

export default PackageStep
