import React, { useState } from "react"

import BulkWatermarkModalForm from './BulkWatermarkModalForm'

const BulkWatermark = ({
  selectedDocumentResourceIds
}) => {
  const [showWartermarkModal, setShowWartermarkModal] = useState(false)

  return <>
      <a
        onClick={() => setShowWartermarkModal(!showWartermarkModal)}
        style={{ fontSize: '14px', fontWeight: '600' }}>
        Watermark
      </a>
      {showWartermarkModal && <BulkWatermarkModalForm
        selectedDocumentResourceIds={selectedDocumentResourceIds}
        cancelAction={() => setShowWartermarkModal(false)} />}
  </>
}

export default BulkWatermark
