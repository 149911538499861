import React from "react"

import styles from "./style.module"

const SecuritySummaryEntry = ({
  securitySummary,
}) => {
  return <div className={`is-flex is-align-items-top ${styles.SecuritySummaryEntry}`}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ minWidth: '24px' }}>
      <polygon fill="#0075E0" fillRule="evenodd" points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59" />
    </svg>
    {securitySummary.text}
  </div>
}

export default SecuritySummaryEntry
