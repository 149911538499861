import React from "react"

import styles from "./style.module"

const EmptyState = ({
  message,
}) => {
  return <div className={styles.emptyState}>
    {message}
  </div>
}

export default EmptyState
