import React, { useMemo, useState } from "react"
import ReactDOM from 'react-dom'
import LabelForm from "../../LabelList/LabelForm"

import styles from "./style.module"

import utils from '../../utils/utils'

const EditLabelDropdown = ({
  labels: initialLabels,
  selectedDocumentResourceIds,
  selectedLabelIds: initialSelectedLabelIds,
  show
}) => {
  const [labels, setLabels] = useState(initialLabels)
  const [selectedLabelIds, setSelectedLabelIds] = useState(initialSelectedLabelIds)
  const [showAddLabelModal, setShowAddLabelModal] = useState(false)

  const updateLabelUrl = '/document_resources/update_labels'

  const updateLabelFetchParams = (method, data) => {
    const params = {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }

    if (data) {
      params['body'] = JSON.stringify({ "document_resource": data })
    }

    return params
  }

  const updateLabelPost = () => {
    const data = {
      "document_resource_ids": selectedDocumentResourceIds,
      "label_ids": selectedLabelIds,
    }

    fetch(updateLabelUrl, updateLabelFetchParams('POST', data))
      .then(_response => refreshCurrentPage())
  }

  const refreshCurrentPage = () => {
    Turbolinks.visit(window.location.toString(), { action: 'replace' })
  }

  const labelList = useMemo(() => {
    if (labels.length === 0) {
      return <p style={{ color: '#8C9296' }}>No labels yet.</p>
    }
    return labels.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    }).map(label =>
      <label
        key={label.id}
        style={{
          display: 'block',
          height: '20px',
          margin: '12px 0',
          overflow: 'hidden'
        }}>
        <input
          defaultChecked={initialSelectedLabelIds.includes(label.id)}
          onChange={e => {
            if (e.target.checked) {
              setSelectedLabelIds([label.id, ...selectedLabelIds])
            } else {
              setSelectedLabelIds(selectedLabelIds.filter(selectedLabelId => selectedLabelId != label.id))
            }
          }}
          type="checkbox" />{label.name}
      </label>)
  }, [labels, selectedLabelIds])

  const selectedLabelChanged = useMemo(() => {
    return JSON.stringify(initialSelectedLabelIds.sort()) != JSON.stringify(selectedLabelIds.sort())
  }, [initialSelectedLabelIds, selectedLabelIds])

  const addLabelModalForm = useMemo(() => {
    if (!showAddLabelModal) {
      return
    }

    return ReactDOM.createPortal(
      <LabelForm
        cancelAction={() => setShowAddLabelModal(false)}
        onSave={label => {
          setLabels([label, ...labels].sort((a, b) => {
            const nameA = a.name.toUpperCase()
            const nameB = b.name.toUpperCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          }))
          setShowAddLabelModal(false)
        }}
        title={"Add Label"} />,
      document.getElementById('modal-container')
    )
  }, [showAddLabelModal])

  return (
    <div className={`dropdown ${show ? 'is-active' : ''} ${styles.dropdown}`}>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className={`dropdown-content ${styles.dropdownContent}`}>
          <div style={{ padding: '10px 20px' }}>{labelList}</div>
          <div className="separator"></div>
          <div style={{ padding: '0 20px 10px' }}>
            {selectedLabelChanged && <a style={{ fontWeight: '600' }} onClick={() => updateLabelPost()}>Apply changes</a>}
            {!selectedLabelChanged && <>
              <a onClick={() => setShowAddLabelModal(true)} style={{ color: '#4a4a4a', display: 'block', fontWeight: '600', marginBottom: '10px' }}>Create new</a>
              <a href="/settings/labels" style={{ color: '#4a4a4a', fontWeight: '600' }}>Manage labels</a>
            </>}
            {addLabelModalForm}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditLabelDropdown
