import React, { useState } from "react"
import { useForm } from 'react-hook-form'

import utils from '../../utils/utils'

import Modal from "../../Modal"
import Radio from "../../inputs/Radio"
import TextField from "../../inputs/TextField"

const RequestCenterModalForm = ({
	onCancel,
	requestCenter: initialRequestCenter,
	requestCenterBaseUrl,
	slugs
}) => {
	const [completitionStatus, setCompletitionStatus] = useState(false)
	const [name, setName] = useState(null)
	const [requestCenterType, setRequestCenterType] = useState(() => initialRequestCenter?.request_center_type || 'packages')
	const [step, setStep] = useState('form')
	const [requestCenter, setRequestCenter] = useState(initialRequestCenter)

	const formOptions = requestCenter ? {
		defaultValues: {
			name: requestCenter.name,
			request_center_type: requestCenter.request_center_type,
		},
		mode: "onChange"
	} : {
		mode: "onChange"
	}

	const { register, handleSubmit, errors, formState, getValues } = useForm(formOptions)

	const requestCenterURL = () => {
		return `/request_centers${(requestCenter && requestCenter.id) ? `/${requestCenter.id}` : ''}`
	}

	const requestCenterParams = (method, data) => {
		const params = {
			method: method,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'X-CSRF-Token': utils.csrfToken()
			}
		}

		if (data) {
			params['body'] = JSON.stringify({ request_center: data })
		}

		return params
	}

	const submitRequestCenter = (data) => {
		fetch(requestCenterURL(), requestCenterParams('POST', { ...data, request_center_type: requestCenterType }))
			.then(response => response.json())
			.then(json => {
				setRequestCenter(json)
				setStep('done')
				setCompletitionStatus('added')
			})
	}

	const updateRequestCenter = () => {
		fetch(requestCenterURL(), requestCenterParams('PATCH', { name: name, request_center_type: requestCenterType }))
			.then(response => response.json())
			.then(json => {
				setRequestCenter(json)
				setCompletitionStatus('updated')
				setStep('done')
			})
	}

	const refreshCurrentPage = () => {
		Turbolinks.visit(window.location.toString(), { action: 'replace' })
	}

	const cancelAction = () => {
		if (step == 'done') {
			refreshCurrentPage()
			return
		}

		onCancel()
	}

	const doneContent = step == 'done' ? <div className="content">
		<div className="columns">
			<div className="column document-list">
				<div className="has-text-centered" style={{ paddingTop: '80px' }}>
					<div style={{ border: 'solid 4px #2bc9b4', borderRadius: '50%', padding: '9px', width: '72px', height: '72px', margin: 'auto' }}>
						<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
							<path fill="#2BC9B4" fillRule="evenodd" d="M18 32.34L9.66 24 6.82 26.82 18 38 42 14 39.18 11.18z" />
						</svg>
					</div>
					<h2 style={{ fontSize: '26px', margin: '1rem 0 0 0' }}>Request Center {completitionStatus == 'updated' ? 'Updated' : 'Added'}</h2>
					<p>{completitionStatus == 'updated' ? requestCenter.name : 'You can now add files to this request center.'}</p>
					<p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '12px 16px', maxWidth: '500px', marginTop: '40px' }}>
						{requestCenterBaseUrl}/{requestCenter.slug}
					</p>
				</div>
			</div>
		</div>
	</div> : null

	const confirmUpdate = () => {
		const values = getValues()

		if (!values.name) {
			return
		}

		setName(values.name)
		setStep('confirm')
	}

	const confirmButton = () => {
		if (step == 'done') {
			return <a className="button" onClick={cancelAction}>Done</a>
		}

		if (step == 'confirm') {
			return <a className="button is-info	" onClick={updateRequestCenter}>Save</a>
		}

		if (step == 'delete') {
			return <a className="button is-info	" onClick={deleteRequestCenter}>Delete</a>
		}

		if (requestCenter && requestCenter.id) {
			return <a onClick={confirmUpdate} className={`button is-info ${formState.isValid ? '' : 'disabled'}`}>Next</a>
		} else {
			return <input className={`button is-info ${formState.isValid ? '' : 'disabled'}`} draggable="false" type="submit" value="Next" />
		}
	}

	const deleteAction = requestCenter && requestCenter.id && step == 'form' ? () => {
		setStep('delete')
	} : false

	const deleteRequestCenter = () => {
		fetch(requestCenterURL(), requestCenterParams('DELETE'))
			.then(() => {
				refreshCurrentPage()
			})
	}

	const modalTitle = () => {
		if (completitionStatus) {
			return 'Done!'
		} else {
			return `${requestCenter ? 'Edit' : 'Add'} Request Center`
		}
	}

	return <form onSubmit={handleSubmit(submitRequestCenter)}>
		<Modal cancelAction={cancelAction}
			cancelButton={step == 'done'}
			confirmButton={confirmButton()}
			deleteAction={deleteAction}
			deleteButtonText="Delete"
			modalCardStyle={{ minWidth: '750px' }}
			title={modalTitle()} >

			{step == 'form' && <>
				<div className="columns">
					<div className="column">
						<TextField
							errors={errors}
							label={<b>Request Center Name</b>}
							name="name"
							placeholder="E.g., Security Documents"
							reff={register({
								required: true,
								validate: {
									requestCenterNameTaken: value => (slugs.indexOf(value) === -1 || (requestCenter !== undefined ? requestCenter.name === value : false))
								}
							})} />
					</div>
				</div>
				{!requestCenter && <div className="columns mt-1">
					<div className="column">
						<label><b>Choose Request Center type</b></label>
						<div className="action-selector">
							<div className="is-relative">
								<input key="actionSelectPackage" type="radio" defaultChecked={requestCenterType == 'packages'} id="actionSelectPackage" name="request_center_type" value="packages" onChange={() => setRequestCenterType('packages')} />
								<label className="button is-flex is-justify-content-space-between" htmlFor="actionSelectPackage" style={{ padding: "0 15px 0 80px" }}>
									<span>
										<strong>Packages</strong>
										<br />
										Only Packages can be listed.
									</span>
									<span>
										<a href="https://pima.app/pricing/" target="_blank">Learn More</a>
									</span>
								</label>
							</div>
							<div className="is-relative">
								<input key="actionSelectResources" type="radio" defaultChecked={requestCenterType == 'resources'} id="actionSelectResources" name="request_center_type" value="resources" onChange={() => setRequestCenterType('resources')} />
								<label className="button is-flex is-justify-content-space-between" htmlFor="actionSelectResources" style={{ padding: "0 15px 0 80px" }}>
									<span>
										<strong>Documents &amp; Agreements</strong>
										<br />
										Only Documents and Agreements can be listed.
									</span>
									<span>
										<a href="https://pima.app/pricing/" target="_blank">Learn More</a>
									</span>
								</label>
							</div>
						</div>
					</div>
				</div>}
			</>}

			{step == 'confirm' && <p>Renaming this request center change all access URLs and break any integrations.</p>}
			{step == 'delete' && <p>Are you sure you want to delete this Request Center?<br /><br />The access URL and any integrations will no longer work.</p>}
			{doneContent}
		</Modal>
	</form>
}

export default RequestCenterModalForm
