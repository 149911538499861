import React, { useState } from "react"
import ReactDOM from 'react-dom'
import Turbolinks from "turbolinks"

import GroupForm from "./GroupForm"

const ManageGroup = (props) => {
  const group = props.group

  const [showGroupForm, setShowGroupForm] = useState(false)

  const refreshCurrentPage = () => {
    Turbolinks.visit(window.location.toString(), { action: 'replace' })
  }

  const groupForm = showGroupForm ? ReactDOM.createPortal(
    <GroupForm
      {...props}
      onCancel={() => setShowGroupForm(false)}
      onSave={() => refreshCurrentPage()} />,
    document.getElementById('modal-container')
  ) : null

  return <>
    <a className={`${props.className ? props.className : ''} ${group ? '' : `button is-primary`}`}
      onClick={() => setShowGroupForm(true)}>{group ? group.name : 'Add Group'}</a>
    {groupForm}
  </>
}

export default ManageGroup
