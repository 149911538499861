import React, { useState } from "react"

import EmptyState from 'components/TrustCenters/TrustCenterEdit/EmptyState'

import BadgeStep from '../../../TrustCenterEdit/BadgeStep'
import Badge from './Badge'

import styles from "./style.module"

const Badges = ({
  edit,
  trustCenter,
}) => {
  const [badges, setBadges] = useState(trustCenter.badges)
  const [editBadges, setEditBadges] = useState(false)

  return (badges.length > 0 || edit) && <div className={styles.Badges}>
    {editBadges && <BadgeStep
      onPanelClose={() => setEditBadges(false)}
      onTrustCenterChange={trustCenter => setBadges(trustCenter.badges)}
      trustCenter={trustCenter} />}
    {edit && <a onClick={() => setEditBadges(true)} className="is-pulled-right has-text-weight-bold">Edit</a>}
    <h2 className={styles.h2}>Badges</h2>
    <div className="is-flex is-flex-direction-row is-flex-wrap-wrap">
      {badges.map(badge => <Badge key={badge.slug} badge={badge} />)}
    </div>
    {badges.length == 0 && <EmptyState message="Select badges to show your compliance status." />}
  </div >
}

export default Badges
