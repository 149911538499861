import React from "react"

const ActionSelector = ({
  agreements,
  documents,
  innerStyle,
  onChange,
  partner,
  availableAgreements,
}) => {
  if (agreements.length == 0) {
    onChange('send_document')
  }

  if (documents.length == 0) {
    onChange('send_agreement')
  }

  return <div style={innerStyle} className="action-selector">
    <div className="is-relative" style={{ opacity: ((agreements.length == 0 || documents.length == 0) ? '0.5' : '1') }}>
      <input key="actionSelectDocumentAndAgreement" type="radio" defaultChecked={(agreements.length == 0 ? '' : 'checked')} id="bind_document_with_agreement" name="action" value="bind_document_with_agreement" onChange={() => onChange('bind_document_with_agreement')} disabled={(agreements.length == 0 || documents.length == 0)} />
      <label className="button" htmlFor="bind_document_with_agreement"><strong>Send Documents with Agreements</strong><br />Require {partner.name} to sign agreements before viewing documents.</label>
    </div>
    <div className="is-relative" style={{ opacity: (availableAgreements.length == 0 ? '0.5' : '1') }}>
      <input key="actionSelectAgreement" type="radio" defaultChecked={(documents.length == 0 ? 'checked' : '')} id="send_agreement" name="action" value="send_agreement" onChange={() => onChange('send_agreement')} disabled={availableAgreements.length == 0} />
      <label className="button" htmlFor="send_agreement"><strong>Send Agreements</strong><br />Choose agreements to sign and who to notify at {partner.name}.</label>
    </div>
    <div className="is-relative" style={{ opacity: (documents.length == 0 ? '0.5' : '1') }}>
      <input key="actionSelectDocument" type="radio" defaultChecked={(agreements.length == 0 ? 'checked' : '')} id="send_document" name="action" value="send_document" onChange={() => onChange('send_document')} disabled={documents.length == 0} />
      <label className="button" htmlFor="send_document"><strong>Send Documents</strong><br />Choose documents and who to notify at {partner.name}.</label>
    </div>
  </div>
}

export default ActionSelector
