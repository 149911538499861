import React, { useState } from "react"
import { useForm } from 'react-hook-form'

import ActionSelector from './ActionSelector'
import Modal from './../Modal'

function ShareDocumentModalForm(props) {
  const agreementSlug = props.agreementSlug
  const cancel = props.cancel
  const companyName = props.companyName
  const emailDomain = props.emailDomain
  const onSave = props.onSave
  const onTosAccepted = props.onTosAccepted
  const partnerName = props.partnerName
  const tosAcceptedInital = props.tosAcceptedInital

  const [action, setAction] = useState(false)
  const [sentData, setSentData] = useState(false)
  const [step, setStep] = useState('actionSelect')
  const [tosAccepted, setTosAccepted] = useState(tosAcceptedInital)

  const { register, handleSubmit, errors, formState, getValues } = useForm({ reValidateMode: 'onChange' })

  const actionSelect = <ActionSelector onChange={(action) => setAction(action)}
    innerStyle={{ visibility: step === 'actionSelect' ? 'visible' : 'hidden', position: step === 'actionSelect' ? 'relative' : 'absolute', top: step === 'actionSelect' ? 'auto' : '-1000px' }} />

  function handleTos() {
    if (!action) {
      return
    }

    if (tosAccepted) {
      stepForward()
      return
    }

    const values = getValues()

    if (values['tos_accepted'] === '1') {
      signTos(values, function () {
        setTosAccepted(true)
        onTosAccepted(true)
        stepForward()
      })
    }
  }

  function visitAgreementUrl() {
    Turbolinks.visit(`/agreements/${agreementSlug}/sign`, { action: 'replace' })
  }

  function signTos(values, callback) {
    fetch('/update_account', {
      method: 'PATCH',
      body: JSON.stringify({ user: values }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          callback()
        }
      })
  }

  function csrfToken() {
    const tokenDom = document.querySelector("meta[name=csrf-token]")
    return tokenDom ? tokenDom.content : ''
  }

  function transferAgreementSignature() {
    const values = getValues()

    fetch(`/agreements/${agreementSlug}/transfer_signature`, {
      method: 'POST',
      body: JSON.stringify({ contact: values }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          setSentData(values)
          setStep('agreement_signature_transferred')
        }
      })
  }

  const stepForward = () => {
    if (step === 'actionSelect' && action === 'transfer_signature') {
      setStep('transfer_signature')
    }
    if (step === 'actionSelect' && action === 'sign_agreement') {
      Turbolinks.visit(`/agreements/${agreementSlug}/sign`, { action: 'replace' })
    }
  }

  const cancelButton = step !== 'agreement_signature_transferred' ? <a className="button is-white" onClick={() => cancel(step === 'agreement_signature_transferred')}>Cancel</a> : true

  let saveButton

  if (step === 'agreement_signature_transferred') {
    saveButton = <a className="button is-info submit-form-on-enter-key-press" onClick={() => cancel(true)}>Done</a>
  } else {
    saveButton = <input className={`button is-info submit-form-on-enter-key-press ${true ? '' : 'disabled'}`}
      draggable="false"
      type="submit"
      value={step === 'actionSelect' ? 'Next' : 'Transfer'} />
  }

  const formAction = step === 'actionSelect' ? handleTos : transferAgreementSignature

  const shareResult = sentData && <div style={{ visibility: step === 'agreement_signature_transferred' ? 'visible' : 'hidden', position: step === 'agreement_signature_transferred' ? 'relative' : 'absolute', top: step === 'agreement_signature_transferred' ? 'auto' : '-1000px' }}>
    <div className="has-text-centered" style={{ paddingTop: '80px' }}>
      <div style={{ border: 'solid 4px #2bc9b4', borderRadius: '50%', padding: '9px', width: '72px', height: '72px', margin: 'auto' }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
          <path fill="#2BC9B4" fillRule="evenodd" d="M18 32.34L9.66 24 6.82 26.82 18 38 42 14 39.18 11.18z" />
        </svg>
      </div>

      <h2 style={{ fontSize: '26px', margin: '1rem 0 0 0' }}>Agreement Transferred</h2>
      <p style={{ fontSize: '22px' }}>{sentData.email}</p>

      <p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '19px 0', maxWidth: '400px', marginTop: '40px' }}>
        You will be notified when your documents are ready to view.
      </p>
    </div>
  </div>

  return (
    <form onSubmit={handleSubmit(formAction)}>
      <Modal
        cancelAction={() => cancel(step === 'agreement_signature_transferred')}
        cancelButton={cancelButton}
        confirmButton={saveButton}
        title={sentData ? 'Sent!' : (step === 'actionSelect' ? 'Sign Agreement' : 'Transfer Agreement')}
        modalCardStyle={{ minHeight: '600px', minWidth: '750px' }}
        modalCardBodyStyle={{ padding: 0 }}>
        <div className="content">
          <div className="columns">
            <div className="column">

              {actionSelect}

              {!tosAccepted && <span className="control">
                <span className="is-primary pure-material-checkbox">
                  <label className="pure-material-checkbox" htmlFor="tos_accepted">
                    <input type="checkbox" name="tos_accepted" id="tos_accepted" value="1" required="required" ref={register({ required: true })} />
                    <span>I accept Pima's <a target="_blank" href="https://pima.app/terms-of-service/">Terms of Service</a> and <a target="_blank" href="https://pima.app/security/privacy-policy/">Privacy Policy</a></span>
                  </label>
                </span>
              </span>}

              {step === 'transfer_signature' &&
                <React.Fragment>
                  <div className="field">
                    <div className="control">
                      <p>If you are not authorized to sign an Agreement on behalf of {companyName}, use this form to transfer the Agreement to someone else.</p>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-half">
                      <div className="field">
                        <div className="control">
                          <label className="label" htmlFor="request_first_name">First Name:</label>
                          <input autoComplete="off" placeholder="E.g., Susan" className="input" ref={register({ required: "Required", })} type="text" name="first_name" id="request_first_name" />
                          <span className="error">{errors.first_name && errors.first_name.message}</span>
                        </div>
                      </div>
                    </div>
                    <div className="column is-half">
                      <div className="field">
                        <div className="control">
                          <label className="label" htmlFor="request_last_name">Last Name:</label>
                          <input autoComplete="off" placeholder="E.g., Smith" className="input" ref={register({ required: "Required", })} type="text" name="last_name" id="request_last_name" />
                          <span className="error">{errors.last_name && errors.last_name.message}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <div className="field">
                        <div className="control">
                          <label className="label" htmlFor="request_email">Email:</label>
                          <input className="input"
                            id="request_email"
                            name="email"
                            placeholder={`E.g., email@${emailDomain}`}
                            ref={register({
                              required: "Required",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                                message: "Invalid email address"
                              },
                              validate: value => value.split('@')[1].indexOf(emailDomain) > -1 || `Email must match @${emailDomain}`
                            })
                            }
                            type="email" />
                          <span className="error">{errors.email && errors.email.message}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>}

              {shareResult}

            </div>
          </div>
        </div>
      </Modal>
    </form>)
}

export default ShareDocumentModalForm
