import React, { useMemo, useState } from "react"
import Switch from "react-switch"

import Label from "../Label"

import styles from './style.module'

const Toggle = ({ 
  disabled = false,
  initialValue,
  label,
  options,
  onChange,
  size = "normal",
  subtitle,
}) => {
  const [value, setValue] = useState(initialValue)
  
  const isSwitch = useMemo(() => {
    if (options.length != 2) {
      return false
    }
    const labels = options.map(option => option.label.toLowerCase()).sort()
    const values = options.map(option => option.value).sort()
    return labels[0] == 'off' && labels[1] == 'on' && values[0] == false && values[1] == true
  })  

  const onSelect = (_value) => {
    setValue(_value)
    onChange(_value)
  }

  return <div className={styles.option}>
    {!subtitle && <div style={{ opacity: disabled ? 0.7 : 1 }}>{label}</div>}
    {subtitle && <div style={{ opacity: disabled ? 0.7 : 1 }}>
      <div>
        <strong>{label}</strong>
      </div>
      <div><small>{subtitle}</small></div>
    </div>}
    <div>
      {!isSwitch && options.map((option) => {
        return <Label 
          key={option.value} 
          label={option.label}
          selected={value == option.value}
          onSelect={() => onSelect(option.value)}/>
      })}
      {isSwitch && <Switch
        disabled={disabled}
        onChange={onSelect}
        checked={value}
        className={styles.switch}
        onColor="#0075E0"
        offColor="#ced0d2"
        onHandleColor="#FFFFFF"
        handleDiameter={26}
        activeBoxShadow="0 0 0 0 #0075E0"
        uncheckedIcon={<div style={{ 
            color: '#424c53',
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontFamily: "Nunito Sans",
            fontSize: '16px',
            fontWeight: 600,
            paddingRight: 8
          }}>Off</div>
        }
        checkedIcon={<div style={{
            color: '#FFFFFF',
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontFamily: "Nunito Sans",
            fontSize: '16px',
            fontWeight: 600,
            paddingLeft: 8
        }}>On</div>}
        height={size == "small" ? 32 : 36}
        width={size == "small" ? 68 : 72}
      />}
    </div>
  </div>
}

export default Toggle