import React, { useEffect } from "react"

const AllRowSelect = ({
  onChange = () => { },
  resourceList
}) => {
  const id = 'all-row-select-document-resource-checkbox'
  const inputFieldName = 'document_resource_ids'

  const onSelectedRowChange = () => {
    const selectAll = document.querySelector(`#${id}`)
    const checkboxes = selectAll.closest('.main-content').querySelectorAll(`input[name="${inputFieldName}"]`)

    let oneChecked = false
    let allChecked = true
    const selected = []

    for (const checkbox of checkboxes) {
      if (checkbox.checked) {
        selected.push(parseInt(checkbox.value))
        oneChecked = true
      } else {
        allChecked = false
      }
    }

    onChange(selected)

    if (oneChecked) {
      if (allChecked) {
        selectAll.indeterminate = false
        selectAll.checked = true
      } else {
        selectAll.indeterminate = true
      }
    } else {
      selectAll.indeterminate = false
      selectAll.checked = false
    }
  }

  useEffect(() => {
    const selectAll = document.querySelector(`#${id}`)
    const checkboxes = selectAll.closest('.main-content').querySelectorAll(`input[name="${inputFieldName}"]`)

    checkboxes.forEach(checkbox => {
      checkbox.addEventListener('change', (e) => onSelectedRowChange())
    })

    onSelectedRowChange()
  }, [resourceList])

  return <input
    id={id}
    onChange={e => {
      const selected = []
      const selectAll = document.querySelector(`#${id}`)
      const checkboxes = selectAll.closest('.main-content').querySelectorAll(`input[name="${inputFieldName}"]`)

      for (const checkbox of checkboxes) {
        checkbox.checked = e.target.checked
        if (checkbox.checked) {
          selected.push(parseInt(checkbox.value))
        }
      }

      onChange(selected)
    }}
    type="checkbox" />
}

export default AllRowSelect
