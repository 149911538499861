import React from "react"
import Modal from './../../Modal'

import styles from './style.module'

const ShareActionSelector = ({
  canSharePackages = true,
  onShareOptionSelect,
  onCancel,
}) => {
  return <Modal
    cancelAction={() => onCancel()}
    cancelButton={true}
    confirmButton={<a className="button" draggable="false" onClick={() => onCancel()}>Cancel</a>}
    title="Send"
    modalCardStyle={{ height: '600px', minWidth: '750px', overflowX: 'hidden' }}
    modalCardBodyStyle={{ padding: 0, overflowX: 'hidden' }}>
    <div className="content p-6">
      <div className="columns">
        <div className={`column is-two-fifth has-text-centered ${styles.card}`}>
          <div className={styles.cardInfo}>
            <svg fill="none" viewBox="0 0 22 22" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 16.5C21 16.88 20.79 17.21 20.47 17.38L12.57 21.82C12.41 21.94 12.21 22 12 22C11.79 22 11.59 21.94 11.43 21.82L3.53 17.38C3.21 17.21 3 16.88 3 16.5V7.5C3 7.12 3.21 6.79 3.53 6.62L11.43 2.18C11.59 2.06 11.79 2 12 2C12.21 2 12.41 2.06 12.57 2.18L20.47 6.62C20.79 6.79 21 7.12 21 7.5V16.5ZM12 4.15L10.11 5.22L16 8.61L17.96 7.5L12 4.15ZM6.04 7.5L12 10.85L13.96 9.75L8.08 6.35L6.04 7.5ZM5 15.91L11 19.29V12.58L5 9.21V15.91ZM19 15.91V9.21L13 12.58V19.29L19 15.91Z" fill="#0075E0" />
            </svg>
            <h3>Package</h3>
            <p>Send a preset collection of<br />envelopes.</p>
          </div>
          {canSharePackages && <a
            className="button is-primary"
            onClick={() => onShareOptionSelect('packages')}>
            Send a Package
          </a>}
          {!canSharePackages && <a
            className="button is-primary"
            href="/packages">
            Create Your First Package
          </a>}
        </div>
        <div className="column is-one-fifth" style={{ width: '12px' }}></div>
        <div className={`column is-two-fifth has-text-centered ${styles.card}`}>
          <div className={styles.cardInfo}>
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M20,4 L4,4 C2.9,4 2.01,4.9 2.01,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,6 C22,4.9 21.1,4 20,4 Z M20,8 L12,13 L4,8 L4,6 L12,11 L20,6 L20,8 Z" fill="#0075E0" fillRule="evenodd" />
            </svg>
            <h3>Envelope</h3>
            <p>Manually select documents and<br />agreements to send.</p>
          </div>
          <a
            className="button is-primary"
            onClick={() => onShareOptionSelect('envelopes')}>
            Send an Envelope
          </a>
        </div>
      </div>
    </div>
  </Modal>
}

export default ShareActionSelector
