import React, { useState } from "react"
import { getDomain } from 'tldts'
import { useForm } from 'react-hook-form'

import utils from '../../utils/utils'

import Modal from "../../Modal"
import TextField from "../../inputs/TextField"

const BlockedDomainModalForm = ({
  blockedDomain,
  blockedDomains,
  onCancel,
  onSave,
}) => {
  const [step, setStep] = useState('form')

  const formOptions = blockedDomain ? {
    defaultValues: {
      domain: blockedDomain.domain,
    },
    mode: "onChange"
  } : {
    mode: "onChange"
  }

  const { register, handleSubmit, errors, formState, getValues } = useForm(formOptions)

  const blockedDomainURL = () => {
    return `/blocked_domains${blockedDomain?.id ? `/${blockedDomain.id}` : ''}`
  }

  const checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(new Error(response))
    }
  }

  const blockedDomainParams = (method, data) => {
    const params = {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }

    if (data) {
      params['body'] = JSON.stringify({ blocked_domain: data })
    }

    return params
  }

  const submitBlockedDomain = (data) => {
    fetch(blockedDomainURL(), blockedDomainParams('POST', data))
      .then(checkStatus)
      .then(response => response.json())
      .then(json => onSave(json))
      .catch(err => console.error(err))
  }

  const updateBlockedDomain = () => {
    const values = getValues()
    fetch(blockedDomainURL(), blockedDomainParams('PATCH', { domain: values.domain }))
      .then(checkStatus)
      .then(response => response.json())
      .then(json => onSave(json))
      .catch(err => console.error(err))
  }

  const deleteBlockedDomain = () => {
    fetch(blockedDomainURL(), blockedDomainParams('DELETE'))
      .then(checkStatus)
      .then(() => onSave())
      .catch(err => console.error(err))
  }

  const confirmButton = () => {
    if (step == 'delete') {
      return <a className="button is-info	" onClick={deleteBlockedDomain}>Delete</a>
    }

    return <input className={`button is-info ${formState.isValid ? '' : 'disabled'}`} draggable="false" type="submit" value="Save" />
  }

  const deleteAction = blockedDomain?.id && step == 'form' ? () => {
    setStep('delete')
  } : false


  const modalTitle = () => {
    return `${blockedDomain?.id ? 'Edit' : 'Add'} Blocked Domain`
  }

  return <form onSubmit={handleSubmit(blockedDomain?.id ? updateBlockedDomain : submitBlockedDomain)}>
    <Modal cancelAction={onCancel}
      cancelButton={step == 'done'}
      confirmButton={confirmButton()}
      deleteAction={deleteAction}
      deleteButtonText="Delete"
      title={modalTitle()} >

      {step == 'form' && <>
        <div className="columns">
          <div className="column">
            <TextField
              customError="Not a valid domain name"
              errors={errors}
              label="Domain"
              name="domain"
              placeholder="E.g., domain.com"
              reff={register({
                required: true,
                validate: {
                  blockedDomainTaken: value => (blockedDomains.indexOf(value) === -1 || (blockedDomain !== undefined ? blockedDomain.domain === value : false)),
                  mustBeDomainName: value => value == getDomain(value)
                },
                pattern: /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/
              })} />
          </div>
        </div>
      </>}

      {step == 'delete' && <p>Are you sure you want to delete this Blocked domain?</p>}
    </Modal>
  </form>
}

export default BlockedDomainModalForm
