import React, { useMemo, useState } from "react"
import utils from 'components/utils/utils'

import Entity from './Entity'
import EnvelopeRemoveModal from './EnvelopeRemoveModal'

const Envelope = ({
  agreementResources,
  canRemove,
  envelopeIndex,
  documentResources,
  onRemove,
  origin,
  packageData,
}) => {
  const [showRemovePopup, setShowRemovePopup] = useState(false)
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

  const removeModal = useMemo(() => {
    if (!showRemovePopup) {
      return
    }

    return <EnvelopeRemoveModal
      agreementResources={agreementResources}
      documentResources={documentResources}
      onCancel={() => setShowRemovePopup(false)}
      onRemove={() => {
        setShowRemovePopup(false)
        onRemove(envelopeIndex)
      }}
      packageData={packageData} />
  }, [showRemovePopup])

  const agreementResourceList = useMemo(() => {
    if (!agreementResources) {
      return []
    }

    return agreementResources.map(agreementResource => (
      <li key={`agreement-resource-${agreementResource.slug}`}>
        <Entity
          disabled={!agreementResource.slug}
          entity={agreementResource}
          previewUrl={utils.usePreviewCDN(`/agreement_resources/${agreementResource.slug}/preview?resize=60x80`, agreementResource)}
          subtitle={agreementResource.description}
          url={`/agreements/${agreementResource.slug}?${origin || `package=${packageData.slug}`}`} />
      </li>
    ))
  }, [agreementResources])

  const documentResourceList = useMemo(() => {
    if (!documentResources) {
      return []
    }

    return documentResources.map(documentResource => (
      <li key={`document-resource-${documentResource.slug}`}>
        <Entity
          disabled={!documentResource.slug}
          entity={documentResource}
          previewUrl={utils.usePreviewCDN(`/document_resources/${documentResource.slug}/preview?resize=60x80`, documentResource)}
          subtitle={documentResource.description}
          url={`/documents/${documentResource.slug}?${origin || `package=${packageData.slug}`}`} />
      </li>
    ))
  }, [documentResources])

  return <div style={{ marginBottom: '25px' }}>
    <strong>
      {agreementResourceList.length ? `${agreementResourceList.length} Agreement${agreementResourceList.length > 1 ? 's' : ''}` : null}
      {(agreementResourceList.length && documentResourceList.length) ? ', ' : null}
      {documentResourceList.length ? `${documentResourceList.length} Document${documentResourceList.length > 1 ? 's' : ''}` : null}
      {canRemove && <a className="is-pulled-right" style={{ fontSize: '14px' }} onClick={e => { setShowRemovePopup(true) }}>Remove</a>}
    </strong>
    <div className="separator"></div>
    <ul>{agreementResourceList}</ul>
    <ul>{documentResourceList}</ul>
    {removeModal}
  </div>
}

export default Envelope
