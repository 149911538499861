import React, { useMemo, useState } from "react"

import LoaderButton from 'components/shared/LoaderButton'
import utils from "../../utils/utils"

import Modal from "../../Modal"

const EnvelopeRemoveModal = ({
  onCancel = () => { },
  onRemove = () => { },
  agreementResources,
  documentResources,
  packageData
}) => {
  const [deleting, setDeleting] = useState(false)

  const removeEnvelope = () => {
    setDeleting(true)

    fetch(`/packages/${packageData.slug}/delete_envelope`, {
      method: "PATCH",
      body: JSON.stringify({
        agreement_resource_ids: agreementResources.map((resource) => resource.id),
        document_resource_ids: documentResources.map((resource) => resource.id)
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }).then(() => onRemove())
      .finally(() => setDeleting(false))
  }

  const confirmButton = useMemo(() => {
    if (deleting) {
      return <LoaderButton variant="is-danger" width="80px" />
    }

    return <a
      className="button is-danger"
      draggable="false"
      onClick={() => removeEnvelope()}
      style={{ width: '80px' }}>
      Remove
    </a>
  }, [deleting])

  return <Modal
    cancelAction={() => onCancel()}
    confirmButton={confirmButton}
    title="Remove Envelope"
  >
    <div className="columns">
      <div className="column">
        Are you sure?
      </div>
    </div>
  </Modal>
}

export default EnvelopeRemoveModal
