import React, { useState } from "react"
import TextField from './TextField'
import settingsUtils from '../settingsUtils'

const TextFieldPreference = ({ 
  initialValue,
  inputStyle,
  label,
  helpText,
  fieldName,
  method,
  modelName,
  placeholder,
  url
}) => {
  const [error, setError] = useState(null)

  const onChange = (value) => {
    setError(null)
    if (!settingsUtils.validURL(value)) {
      setError('Please enter a valid URL')
      value = ''
    }
    settingsUtils.saveValue(fieldName, method, modelName, url, value)
  }

  return <><TextField
    error={error}
    fieldId={`${modelName}_${fieldName}`}
    initialValue={initialValue}
    inputStyle={inputStyle}
    label={label}
    onChange={(value) => onChange(value)}
    placeholder={placeholder} />
    {helpText && <div style={{ marginTop: '-10px' }}>
      <small>{helpText}</small>
    </div>}
  </>
}

export default TextFieldPreference