import React from "react"

const SelectField = (props) => {
  const blankOption = props.blankOption
  const errors = props.errors
  const label = props.label
  const name = props.name
  const defaultOnChangeFunction = (value) => { }
  const onChange = props.onChange || defaultOnChangeFunction
  const options = props.options || {}
  const placeholder = props.placeholder
  const reff = props.reff

  const fieldId = name.replace('[', '_').replace(']', '_')

  let detectedError = errors[name]

  if (name.indexOf('[') > -1 && name.indexOf(']') > -1) {
    const brokenFieldName = name.split('[')
    const recordType = brokenFieldName[0]
    const recordField = brokenFieldName[1].replace(']', '')

    if (errors[recordType] && errors[recordType][recordField]) {
      detectedError = errors[recordType][recordField]
    }
  }

  let error
  if (detectedError && detectedError.type == 'required') {
    error = <span className="error">Required</span>
  }

  const optionsDom = []
  for (const [key, value] of Object.entries(options)) {
    optionsDom.push(<option key={key} value={key}>{value}</option>)
  }

  return <div className="field">
    <label className="label" htmlFor={fieldId}>{label}</label>
    <div className="control select is-fullwidth">
      <select
        autoComplete="off"
        className={`input ${detectedError ? 'is-danger' : ''}`}
        id={fieldId}
        name={name}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder}
        ref={reff}
        type="text">
        {blankOption && <option></option>}
        {/* {blankOption && placeholder && <option disabled="disabled" selected="selected">{placeholder}</option>} */}
        {optionsDom}
      </select>
      {error}
    </div>
  </div>
}

export default SelectField
