import React, { useEffect, useState } from "react"

import CompanyArchive from "./CompanyArchive"
import CompanyHeader from "../CompanyHeader"
import CompanyTitle from "./CompanyTitle"
import Envelopes from "./Envelopes"
import LoginCta from "../LoginCta"
import ShareDocuments from "../ShareDocuments/ShareDocuments"

const Company = ({
  currentCompanySlug,
  userIsAnonymous
}) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    setData(null)

    if (currentCompanySlug) {
      fetch(`/companies/${currentCompanySlug}.json`)
        .then(response => response.json())
        .then(data => setData(data))
    }
  }, [currentCompanySlug])

  return <>
    <div className="column is-main-content">
      <CompanyTitle
        companyName={data?.partner?.name}
        companySlug={data?.partner?.slug}
        userIsAnonymous={userIsAnonymous} />

      <div className={userIsAnonymous ? "is-envelope-list" : ""}>
        <div>
          <CompanyHeader
            company={data?.partner}
            contacts={data?.contacts || []}
            currentUserEmail={data?.currentUser?.email}
            contactCount={data?.contactCount}
            eventCount={data?.eventCount}
            isNonLoggedInView={userIsAnonymous} />
        </div>

        {data && !userIsAnonymous && !data.isArchived && <div>
          <ShareDocuments
            admins={data.shareParams.admins}
            adminEmails={data.shareParams.adminEmails}
            agreements={data.shareParams.agreements}
            availableAgreements={data.shareParams.availableAgreements}
            buttonTitle="Send"
            packageCount={data.shareParams.packageCount}
            contacts={data.shareParams.contacts}
            documents={data.shareParams.documents}
            isAdmin={data.currentUser.is_admin}
            partner={data.partner}
            planStatus={data.shareParams.planStatus}
            planAllowsLabels={data.shareParams.planAllowsLabels}
            title="Envelopes"
            type="share" />
        </div>}

        {data && <div><Envelopes
          companyName={data.company.name}
          currentCompany={data.company.id}
          currentUserId={data.currentUser.id}
          emailDomain={data.currentUser.email_domain}
          envelopes={data.envelopes}
          envelopeType="approved"
          isNonLoggedInView={userIsAnonymous}
          partnerName={data.partner.name}
          slug={data.partner.slug}
          tosAccepted={data.currentUser.tos_accepted} /></div>}
      </div>

      {data && !userIsAnonymous && <CompanyArchive companySlug={data.partner.slug} isArchived={data.isArchived} />}
    </div>
  </>
}

export default Company
