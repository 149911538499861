import React, { useMemo, useState } from "react"
import LoaderButton from 'components/shared/LoaderButton'
import Modal from "./../Modal"

import utils from '../utils/utils'

const DeleteEnvelopeModal = ({
  agreements,
  documents,
  onDone,
  onCancel,
  partnerSlug,
}) => {
  const [deleting, setDeleting] = useState(false)

  const deleteEnvelope = () => {
    setDeleting(true)
    const agreementIds = agreements.map(agreement => agreement.id)
    const documentIds = documents.map(document => document.id)

    fetch(`/companies/${partnerSlug}/delete_envelope`, {
      method: 'PATCH',
      body: JSON.stringify({ agreement_ids: agreementIds, document_ids: documentIds }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          Turbolinks.visit(window.location.toString(), { action: 'replace' })
          onDone()
        }
      })
      .finally(() => setDeleting(false))
  }

  const confirmButton = useMemo(() => {
    if (deleting) {
      return <LoaderButton variant="is-danger" width="80px" />
    }

    return <a
      className="button is-danger"
      draggable="false"
      onClick={() => deleteEnvelope()}
      style={{ width: '80px' }}>
      Delete
    </a>
  }, [deleting])

  return <Modal cancelAction={onCancel}
    confirmButton={confirmButton}
    title="Delete Envelope" >
    <div className="columns">
      <div className="column">
        <p>
          Are you sure you want to delete this envelope?
          <br />
          <br />
          The recipients will no longer have access to the files sent in this envelope.
        </p>
      </div>
    </div>
  </Modal>
}

export default DeleteEnvelopeModal
