import React from "react"
import { TailSpin } from "react-loader-spinner"

const LoaderButton = ({
  variant = 'is-info',
  width = 'inherit'
}) => {
  return <button className={`button ${variant}`} draggable="false" disabled={true} style={{ width }}>
    <TailSpin color="#FFFFFF" height={24} width={24} />
  </button>
}

export default LoaderButton
