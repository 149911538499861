import React from "react"

import BaseToggleStep from "../BaseToggleStep"

const TrustedReviewerStep = ({
  trustCenter,
  onPanelClose = () => { },
  onTrustCenterChange = () => { }
}) => {
  return <BaseToggleStep
    labelAttr="name"
    onPanelClose={() => onPanelClose()}
    onTrustCenterChange={trustCenter => onTrustCenterChange(trustCenter)}
    paramSetUrl="/trusted_reviewers.json"
    trustCenter={trustCenter}
    trustCenterAttr="trustedReviewers"
    trustCenterParam="trusted_reviewer_ids"
    title="Trusted Reviewers"
    subtitle="By enabling a Trusted Reviewer, you acknowledge that you obtained permission to use their logo.">
    <div className="separator mt-2"></div>
    <p><small>If you don’t see a specific trusted reviewer, please <a href="https://forms.gle/GjpfHPmYgfgeJ678A" target="_blank">make a request</a>.</small></p>
  </BaseToggleStep>
}

export default TrustedReviewerStep
