import React, { useState } from "react"
import { useForm } from 'react-hook-form'

import utils from '../utils/utils'

import Modal from "./../Modal"
import TextField from "./../inputs/TextField"

const ContactModalForm = (props) => {
  const {
    alreadyExistingEmails = [],
    agreementSlug,
    company,
    contact,
    deleteAction,
    includeAccessLinkCheckbox = false,
    onSave,
    partnerName,
    partnerSlug,
  } = props
  const [error, setError] = useState(false)

  const formOptions = contact ? {
    defaultValues: {
      first_name: contact.first_name,
      last_name: contact.last_name,
      email: contact.email
    },
    mode: "onChange"
  } : {
    defaultValues: {
      email: `@${company.domain}`
    },
    mode: "onChange"
  }

  const { register, handleSubmit, errors, formState } = useForm(formOptions)

  const submitContact = (data) => {
    if (contact && contact.id) {
      fetch(`/companies/${agreementSlug}/contacts/${contact.id}`, {
        method: 'PUT',
        body: JSON.stringify({ contact: data }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': utils.csrfToken()
        }
      }).then(() => onSave(Object.assign(contact, data)))
    } else {
      fetch(`/companies/${agreementSlug}/contacts`, {
        method: 'POST',
        body: JSON.stringify({ contact: data }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': utils.csrfToken()
        }
      }).then(res => res.json())
        .then((contact) => {
          setError(false)

          if (contact.message) {
            setError(contact.message)
            return
          }

          const shouldSendAccessPassToContact = data.contact_access_link == "1"

          if (shouldSendAccessPassToContact) {
            utils.sendAccessPass(undefined, contact.id, partnerSlug, 'send_reminder').then(json => {
              if (json.success) {
                onSave(contact)
              }
            })
          } else {
            onSave(contact)
          }
        })
    }
  }

  const confirmButton = <input className={`button is-info ${formState.isValid ? '' : 'disabled'} ${!!error ? 'disabled' : ''}`}
    draggable="false"
    type="submit"
    value={(contact && contact.id) ? 'Save Changes' : 'Add Contact'} />

  return <form onSubmit={handleSubmit(submitContact)}>
    <Modal
      {...props}
      confirmButton={confirmButton}
      deleteAction={deleteAction}
      deleteButtonText="Delete">
      <div className="columns">
        <div className="column is-half">
          <TextField
            disabled={!!error}
            errors={errors}
            label="First Name"
            name="first_name"
            placeholder="E.g. Susan"
            reff={register({ required: true })} />
        </div>
        <div className="column is-half">
          <TextField
            disabled={!!error}
            errors={errors}
            label="Last Name"
            name="last_name"
            placeholder="E.g. Smith"
            reff={register({ required: true })} />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <TextField
            disabled={!!error}
            emailDomain={company.domain}
            errors={errors}
            label="Email"
            name="email"
            placeholder={`E.g., name@${company.domain}`}
            reff={register({
              required: true,
              pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              validate: {
                emailDomain: value => {
                  const emailDomain = value.split('@')
                  return emailDomain[1] === company.domain
                },
                emailTaken: value => (alreadyExistingEmails.indexOf(value) === -1 || (contact !== undefined ? contact.email === value : false))
              }
            })} />
        </div>
      </div>
      {includeAccessLinkCheckbox && <div className="columns">
        <div className="column">
          <label className="pure-material-checkbox" htmlFor="contact_access_link">
            <input name="contact_access_link" type="hidden" value="0" />
            <input type="checkbox"
              disabled={!!error}
              value="1"
              name="contact_access_link"
              ref={register()}
              id="contact_access_link" />
            <span>Send access pass to all envelopes shared with {partnerName}.</span>
          </label>
        </div>
      </div>}
      {error && <p style={{ color: '#d92022' }}>{error}</p>}
    </Modal>
  </form>
}

export default ContactModalForm
