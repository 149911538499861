const csrfToken = () => {
  const tokenDom = document.querySelector("meta[name=csrf-token]")
  return tokenDom ? tokenDom.content : ''
}

const sendAccessPass = (user_id, contact_id, partnerSlug, reminderType) => {
  const params = {
    reminder: {
      user_id: user_id,
      contact_id: contact_id,
      reminder_type: reminderType
    }
  }

  return fetch(`/companies/${partnerSlug}/send_access_pass`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    }
  })
    .then(response => response.json())
}

const getJson = (url) => {
  return fetch(url, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    }
  })
    .then(response => response.json())
}

const postJson = (url, data) => {
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken()
    }
  }).then(response => response.json())
}

const retries = {}

const retryFetchImage = (e) => {
  const imgSrc = e.target.src
  const imgDom = e.target

  retries[imgSrc] = retries[imgSrc] ? retries[imgSrc] + 1 : 1

  if (retries[imgSrc] > 5) {
    return
  }

  setTimeout(() => {
    imgDom.src = imgSrc
  }, 5000)
}

const usePreviewCDN = (previewUrl, entity) => {
  if (!window.cloudFrontPreviewCdnUrl || !entity?.id) {
    return previewUrl
  }

  const cdnUrl = window.cloudFrontPreviewCdnUrl ? window.cloudFrontPreviewCdnUrl : ''
  const currentUserCdnKey = window.currentUserCdnKey ? window.currentUserCdnKey : ''
  return `${cdnUrl}${previewUrl}&cdn=${currentUserCdnKey}&UUID=${entity.id}`
}

export default {
  csrfToken,
  getJson,
  postJson,
  retryFetchImage,
  sendAccessPass,
  usePreviewCDN,
}
