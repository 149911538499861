import React, { useEffect, useState } from "react"
import ReactDOM from 'react-dom'

import PlanModalForm from './PlanModalForm'

const PlanFormCta = (props) => {
    const btnText = props.btnText
    const className = props.className
    const showModalInital = props.showModalInital || false
    const [showModal, setShowModal] = useState(showModalInital)

    const refreshCurrentPage = () => {
        Turbolinks.visit(window.location.toString().split("?")[0], { action: 'replace' })
    }

    const planModalForm = showModal && ReactDOM.createPortal(
        <PlanModalForm
            {...props}
            cancel={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            onSave={() => refreshCurrentPage()} />,
        document.getElementById('modal-container')
    )

    return <React.Fragment>
        <a className={className} onClick={() => setShowModal(true)}>
            {btnText}
        </a>
        {planModalForm}
    </React.Fragment>
}

export default PlanFormCta
