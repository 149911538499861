import React from "react"

import utils from '../utils/utils'

const Entity = ({
  disabled = false,
  entity,
  previewUrl,
  subtitle,
  url,
}) => {
  const preview = <div className="columns" style={{ opacity: (disabled ? 0.5 : 1) }}>
    <div className="column preview" style={{ flex: 'none', width: '90px' }}>
      <img alt={entity.name}
        // onError={(e) => entity.id ? utils.retryFetchImage(e) : null}
        src={previewUrl}
        style={{ borderRadius: '4px', border: 'solid 1px #424c53', display: 'block', height: '80px', width: '60px' }} />
    </div>
    <div className="column" style={{ alignItems: 'center', display: 'flex' }}>
      <p style={{ fontSize: '14px', height: '55px', color: '#18242d' }}>
        <strong>{entity.name}</strong>
        <br />
        {subtitle}
        {!entity.id && <><br /><span>Requested resource has been deleted</span></>}
      </p>
    </div>
  </div>

  const content = disabled ? preview : <a href={url}>{preview}</a>

  return (
    <div className="">{content}</div>
  )
}

export default Entity
