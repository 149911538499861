import React, { useState } from "react"

import Contact from "./Contact"
import ContactModalForm from "./ContactModalForm"

const Contacts = (props) => {
  const company = props.company
  const editionDisabled = props.editionDisabled || false
  const onContactPanelClose = props.onContactPanelClose

  const [showContactModalForm, setContactModalForm] = useState(0)
  const [contacts, setContacts] = useState(props.contacts)

  const alreadyExistingEmails = contacts.map(contact => contact.email)

  const onSave = (savedContact) => {
    setContactModalForm(false)
    if (contacts.find(contact => contact == savedContact)) {
      setContacts(contacts.map(contact => contact.id == savedContact ? savedContact : contact))
    } else {
      setContacts([...contacts, savedContact])
    }
  }

  const onDelete = (deletedContact) => {
    setContacts(contacts.filter(contact => contact.id !== deletedContact.id))
  }

  let contactModalForm
  if (showContactModalForm) {
    contactModalForm = <ContactModalForm
      agreementSlug={company.slug}
      alreadyExistingEmails={alreadyExistingEmails}
      cancelAction={() => setContactModalForm(false)}
      company={company}
      includeAccessLinkCheckbox={true}
      onSave={onSave}
      partnerName={company.name}
      partnerSlug={company.slug}
      title={"Add Contact"} />
  }

  const contactSubtitle = `${contacts.length} ${(contacts.length > 1 || contacts.length == 0) ? 'contacts' : 'contact'}`

  return <React.Fragment>
    <a className="side-panel-background" onClick={() => onContactPanelClose()}></a>
    <div className="side-panel">
      <div className="content is-title-block with-subtitle">
        <a className="delete" onClick={() => onContactPanelClose()}></a>
        {company.name} Contacts
          <div className="subtitle">{contactSubtitle}</div>
        {!editionDisabled && <a className="button is-primary" onClick={() => setContactModalForm(true)}>Add Contact</a>}
      </div>
      <div className="content">
        <br />
        <ul className="contacts">
          {contacts.map(contact => {
            return <Contact
              agreementSlug={company.slug}
              alreadyExistingEmails={alreadyExistingEmails}
              key={contact.id}
              company={company}
              editionDisabled={editionDisabled}
              contact={contact}
              partnerName={company.name}
              partnerSlug={company.slug}
              onDelete={onDelete}
              onSave={onSave} />
          })}
        </ul>
      </div>
      {contactModalForm}
    </div>
  </React.Fragment>
}

export default Contacts
