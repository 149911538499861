import React from "react"

const TextField = ({ 
  error,
  fieldId,
  initialValue,
  inputStyle,
  label,
  onChange,
  placeholder
}) => {
  return <div className="field">
    <div className="control">
      <label className="label" htmlFor={fieldId}>{label}</label>
      <input 
        className="input"
        defaultValue={initialValue}
        id={fieldId}
        onChange={(e) => onChange(e.target.value)} 
        placeholder={placeholder}
        style={inputStyle}
        type="text" />
      {error && <span className="error">{error}</span>}
    </div>
  </div>

}

export default TextField