import React from "react"

const SignInWithGoogle = ({style}) => {
    return (   
        <a 
            className="google-signin"
            rel="nofollow"
            data-method="post"
            href="/users/auth/google_oauth2"
            style={style}>
                Sign in with Google
        </a>
    )
}

export default SignInWithGoogle
