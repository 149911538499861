import React from "react"

import Preview from '../Preview'

const PreviewLink = ({
  agreementResource,
}) => (
  <a className="preview" href={`/agreements/${agreementResource.slug}`}>
    <Preview entity={agreementResource} previewUrl={`/agreement_resources/${agreementResource.slug}/preview?resize=110x144`} />
  </a>
)

export default PreviewLink
