import React from "react"

const Textarea = ({ 
  error,
  fieldId,
  initialValue,
  inputStyle,
  label,
  onChange,
  placeholder 
}) => {
  return <div className="field">
    <div className="control">
      <label className="label" htmlFor={fieldId}>{label}</label>
      <textarea 
        className="input"
        defaultValue={initialValue}
        id={fieldId}
        onChange={(e) => onChange(e.target.value)} 
        style={inputStyle}
        placeholder={placeholder}></textarea>
      {error && <span className="error">{error}</span>}
    </div>
  </div>

}

export default Textarea