import React, { useState } from "react"
import Modal from './../Modal'

const AgreementAdded = (props) => {
  const agreementSlug = props.agreementSlug

  const [showModal, setShowModal] = useState(true)

  const customizeAgreementUrl = `/agreements/${agreementSlug}/create_hellosign_template`

  const cancelButton = <a className="button" draggable="false" onClick={() => setShowModal(false)}>Cancel</a>

  const next = () => {
    window.location.href = customizeAgreementUrl
  }

  return showModal ? <Modal cancelAction={() => setShowModal(false)}
    cancelButton={cancelButton}
    confirmAction={() => next()}
    confirmButtonText="Next"
    title="Agreement Added"
    modalCardStyle={{ minHeight: '600px', minWidth: '750px' }}
    modalCardBodyStyle={{ padding: 0 }}>
    <div className="content">
      <div className="columns">
        <div className="column document-list">
          <div className="has-text-centered" style={{ paddingTop: '80px' }}>
            <div style={{ border: 'solid 4px #2bc9b4', borderRadius: '50%', padding: '9px', width: '72px', height: '72px', margin: 'auto' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                <path fill="#2BC9B4" fillRule="evenodd" d="M18 32.34L9.66 24 6.82 26.82 18 38 42 14 39.18 11.18z" />
              </svg>
            </div>
            <h2 style={{ fontSize: '26px', margin: '1rem 0 0 0' }}>Next, customize this agreement.</h2>
            <p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '12px 16px', maxWidth: '500px', marginTop: '40px' }}>
              To share this agreement, you must add fields and customize the agreement template. <a href="https://getpima.notion.site/2-Upload-and-configure-an-agreement-26f9ed1aacdd4d598c12ed3916ede98f">Learn More</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Modal> : <React.Fragment />
}

export default AgreementAdded
