import React from "react"

const ErrorContent = ({
  content,
  paddingTop = '80px',
  title
}) => {
  return <div className="has-text-centered" style={{ paddingTop: paddingTop }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72">
      <path fill="#FADB47" d="M36,0 C55.882251,0 72,16.117749 72,36 C72,55.882251 55.882251,72 36,72 C16.117749,72 0,55.882251 0,36 C0,16.117749 16.117749,0 36,0 Z M36,4 C18.326888,4 4,18.326888 4,36 C4,53.673112 18.326888,68 36,68 C53.673112,68 68,53.673112 68,36 C68,18.326888 53.673112,4 36,4 Z M38,49 L38,53 L34,53 L34,49 L38,49 Z M38,19 L38,45 L34,45 L34,19 L38,19 Z" />
    </svg>

    <h2 style={{ fontSize: '26px', margin: '1rem 0 0 0' }}>{title}</h2>

    <p style={{ borderRadius: '8px', backgroundColor: '#f4f5f5', fontSize: '18px', margin: 'auto', padding: '12px 16px', maxWidth: '500px', marginTop: '40px' }}>
      {content}
    </p>
  </div>
}

export default ErrorContent
