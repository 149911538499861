import React from "react"

import { scrollTopInCookie } from 'components/TrustCenters/TrustCenterEdit/utils'

import styles from "./style.module"

const Title = ({
  edit,
  trustCenter,
}) => {
  const domain = new URL(trustCenter.url).hostname.replace('www.', '')

  return <div className={styles.Title}>
    {edit && <a onClick={scrollTopInCookie} href={`/trust_centers/${trustCenter.slug}/edit?top=1`} className="is-pulled-right has-text-weight-bold">Edit</a>}
    <div><h2 className={styles.h2}>{trustCenter.title}</h2></div>
    <div className="is-flex-desktop">
      <div style={{ marginBottom: '10px', marginRight: '30px' }} className="is-flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="#8C9296" fillRule="evenodd" d="M3.9,12 C3.9,10.29 5.29,8.9 7,8.9 L11,8.9 L11,7 L7,7 C4.24,7 2,9.24 2,12 C2,14.76 4.24,17 7,17 L11,17 L11,15.1 L7,15.1 C5.29,15.1 3.9,13.71 3.9,12 Z M8,13 L16,13 L16,11 L8,11 L8,13 Z M17,7 L13,7 L13,8.9 L17,8.9 C18.71,8.9 20.1,10.29 20.1,12 C20.1,13.71 18.71,15.1 17,15.1 L13,15.1 L13,17 L17,17 C19.76,17 22,14.76 22,12 C22,9.24 19.76,7 17,7 Z" />
        </svg>
        <div style={{ marginLeft: '10px' }}>
          <a href={trustCenter.url} target="_blank">{domain}</a>
        </div>
      </div>
      <div className="is-flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="#8C9296" fillRule="evenodd" d="M11.99,2 C6.47,2 2,6.48 2,12 C2,17.52 6.47,22 11.99,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 11.99,2 Z M12,4 C16.42,4 20,7.58 20,12 C20,16.42 16.42,20 12,20 C7.58,20 4,16.42 4,12 C4,7.58 7.58,4 12,4 Z M12.5,7 L11,7 L11,13 L16.25,16.15 L17,14.92 L12.5,12.25 L12.5,7 Z" />
        </svg>
        <div style={{ marginLeft: '10px' }}>
          Updated on {trustCenter.updatedAt}
        </div>
      </div>
    </div>
  </div>
}

export default Title
