import React, { useMemo, useState } from "react"
import Modal from '../Modal'

import robot from './robot.svg'

import utils from '../utils/utils'

const Onboarding = ({
  cancelAction = false
}) => {
  const content = [
    {
      "title": "Welcome to Pima!",
      "content": "Pima is the lightning-fast way to send confidential compliance documents and agreements."
    }, {
      "title": "Package the power",
      "content": "Build packages of documents and agreements, reducing the time it takes to send your prospects what they need.",
    }, {
      "title": "Control access",
      "content": "Bind any document with any agreement, ensuring a signature is provided before a document is viewed.",
    }, {
      "title": "Make it automatic",
      "content": "Embed a Request Center on your public website, providing prospects with a seamless self-serve workflow.",
    }, {
      "title": "Integrations & API",
      "content": "Get notified or build powerful workflows with Slack and Zapier. Or, integrate directly with Pima’s API.",
    }
  ]

  const [position, setPosition] = useState(0)
  const [hidePopup, setHidePopup] = useState(false)
  const currentContent = useMemo(() => content[position], [position])

  const closeOnboarding = () => {
    fetch('/mark_onboarding_as_completed', {
      body: JSON.stringify({ user: { has_seen_onboarding: true } }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      },
      method: 'POST'
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          if (cancelAction) {
            cancelAction()
          } else {
            setHidePopup(true)
          }
        }
      })
  }

  const backwardArrow = <svg style={{ position: 'absolute', left: '12px' }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd"><g fill="#424C53"><g><g><g><g>
      <path d="M15 8.25L5.872 8.25 10.065 4.058 9 3 3 9 9 15 10.057 13.943 5.872 9.75 15 9.75z" transform="translate(-391 -735) translate(347 140) translate(0 568) translate(32 18) translate(12 9)" />
    </g></g></g></g></g></g>
  </svg>

  const forwardArrow = <svg style={{ position: 'absolute', right: '12px' }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd"><g fill="#0075E0"><g><g><g><g><g>
      <path d="M3 8.25L12.127 8.25 7.935 4.058 9 3 15 9 9 15 7.942 13.943 12.127 9.75 3 9.75z" transform="translate(-1185 -735) translate(347 140) translate(0 568) translate(779 18) translate(16 9) translate(43)" />
    </g></g></g></g></g></g></g>
  </svg>

  const deleteButton = position !== 0 ? <a className="button submit-form-on-enter-key-press"
    style={{ position: 'absolute', left: '40px', paddingLeft: '40px' }}
    draggable="false" onClick={() => setPosition(position - 1)}>
    {backwardArrow} Back
  </a> : null
  const onLastPage = (position + 1) == content.length

  let confirmButton

  if (onLastPage) {
    confirmButton = <a className="button is-info submit-form-on-enter-key-press"
      draggable="false"
      onClick={() => closeOnboarding()}>
      Let's Go!
    </a>
  } else {
    confirmButton = <a className="button submit-form-on-enter-key-press"
      draggable="false"
      onClick={() => setPosition(position + 1)}
      style={{ borderColor: '#0075E0', color: '#0075E0', paddingRight: '40px' }}>
      Next {forwardArrow}
    </a>
  }

  const progression = <div style={{ position: 'absolute', left: 'calc( 50% - 60px )' }}>
    {content.map((_content, index) => {
      return <span key={index}
        style={{
          margin: '0 5px',
          border: `solid 5px ${index === position ? '#0075e0' : '#ced0d2'}`,
          borderRadius: '50%',
          display: 'inline-block'
        }}
        className={`onboarding-progress-bar ${index === position ? 'checked' : ''}`}>
      </span>
    })}
  </div>

  return !hidePopup ? <Modal
    cancelAction={cancelAction || closeOnboarding}
    cancelButton={progression}
    confirmButton={confirmButton}
    deleteButton={deleteButton}
    modalCardStyle={{ width: '900px' }}
    newTitle={true}
    title={currentContent.title}>
    <div style={{ height: '360px' }}>
      <div style={{ fontSize: '18px', width: '500px', margin: 'auto', textAlign: 'center', marginTop: '-20px' }} dangerouslySetInnerHTML={{ __html: currentContent.content }}></div>
      <div style={{ textAlign: 'center' }}>
        <img style={{ height: '220px', margin: '30px 0px' }} src={robot} alt="React Logo" />
      </div>
      <div style={{ backgroundColor: '#D9EBFB', borderRadius: '8px', fontWeight: '600', color: '#0075E0', fontSize: '14px', padding: '4px 8px 4px 8px', textAlign: 'center', width: '220px', margin: 'auto' }}>
        Your first 10 shares are free!
      </div>
    </div>
  </Modal> : null
}

export default Onboarding
