import React from "react"
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import PaymentInfoForm from './PaymentInfoForm'

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const fonts = [{ cssSrc: "https://fonts.googleapis.com/css?family=Nunito+Sans:400" }]
const stripePromise = loadStripe(window.stripe_public_key)

const StripeCreditCardForm = (props) => {
  return <Elements fonts={fonts} stripe={stripePromise}>
    <PaymentInfoForm {...props} />
  </Elements>
}

export default StripeCreditCardForm