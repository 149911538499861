import React, { useEffect, useState } from "react";
import HelloSign from 'hellosign-embedded'

import utils from '../utils/utils'
import Turbolinks from "turbolinks";

const Agreement = (props) => {
  const agreementResourceSlug = props.agreementResourceSlug
  const agreementSlug = props.agreementSlug
  const clientId = props.clientId
  const partnerSlug = props.partnerSlug
  const testMode = props.testMode
  const testSignature = props.testSignature || false
  const url = props.url

  let client

  const [clientClosed, setClientClosed] = useState(false)

  const successCallback = (url, method, args, successUrl, fullpageReload) => {
    setClientClosed(true)

    const params = {
      body: JSON.stringify(args),
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }

    fetch(url, params).then(json => {
      if (fullpageReload) {
        window.location = successUrl
      } else {
        Turbolinks.visit(successUrl)
      }
    })
  }

  const agreementSigned = (eventData) => {
    successCallback(`/agreements/${agreementSlug}/signed`, 'POST', eventData, `/companies/${partnerSlug}`, true)
  }

  const templateCreated = (templateData) => {
    client.close()
    document.getElementById('HSContainer').innerHTML = '<div style="position:absolute;top: calc( 50% - 105px );left: calc( 50% - 66px );"><div style="border:solid 4px #2bc9b4;border-radius:50%;padding:9px;width:72px;height:72px;margin:auto;"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><path fill="#2BC9B4" fillRule="evenodd" d="M18 32.34L9.66 24 6.82 26.82 18 38 42 14 39.18 11.18z"/></svg></div><h2 style="font-size:26px; margin:1rem 0 0 0;">Creating template...</h2></div>';

    successCallback(
      `/agreements/${agreementResourceSlug}`,
      'PATCH',
      { agreement_resource: { hellosign_template: templateData, status: 'approved' } },
      `/agreements`
    )
  }

  const redirectToAgreementResources = () => {
    Turbolinks.visit(`/agreements/${agreementResourceSlug}/edit`)
  }

  const adaptPageLayout = () => {
    const titleBlockHeight = document.querySelector('div.content.is-title-block').offsetHeight
    document.querySelector("div.main-content").style.height = '100%'
    document.querySelector('div[data-react-class="Hellosign/Agreement"]').style.height = `calc( 100% - ${titleBlockHeight}px )`
  }

  const setupHellosign = () => {
    client = new HelloSign({ clientId: clientId })

    client.on(HelloSign.events.SIGN, testSignature ? redirectToAgreementResources : agreementSigned)
    client.on(HelloSign.events.CREATE_TEMPLATE, templateCreated)

    client.open(url, {
      allowCancel: false,
      testMode: testMode == 'true',
      container: document.getElementById('HSContainer')
    })
  }

  useEffect(() => {
    adaptPageLayout()
    setupHellosign()

    return () => {
      if (clientClosed) {
        return
      }

      client.close()
    }
  })

  return <div id="HSContainer" style={{ height: '100%', position: 'relative' }}></div>
}

export default Agreement
