import React from "react"

const BulkWatermarkedDocumentResourceDownload = ({
  companyName,
  downloadUrl,
}) => {
  return <>
    <div className="content is-title-block" style={{ zIndex: 10 }}>Download Archive</div>
    <div 
        style={{ height: '100%', position: 'absolute', left: 0, right: 0, bottom: '60px', top: '60px', backgroundColor: '#f4f5f5', zIndex: 0 }} 
        className="is-flex has-text-centered is-align-content-center is-align-self-center is-align-items-center is-justify-content-center"
      >
      <div>
        <div style={{ border: 'solid 4px #2bc9b4', borderRadius: '50%', padding: '9px', width: '72px', height: '72px', margin: 'auto' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <path fill="#2BC9B4" fillRule="evenodd" d="M18 32.34L9.66 24 6.82 26.82 18 38 42 14 39.18 11.18z" />
          </svg>
        </div>
        <h2 style={{ fontSize: '26px', fontWeight: 'bold', margin: '1rem 0' }}>Your documents for {companyName} are ready</h2>
        <a className="button is-primary" href={downloadUrl} target="_blank">Download Archive</a>
      </div>
    </div>
  </>
}

export default BulkWatermarkedDocumentResourceDownload
