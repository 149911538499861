import React, { useState } from "react"

import EmptyState from 'components/TrustCenters/TrustCenterEdit/EmptyState'

import SecuritySummaryStep from '../../../TrustCenterEdit/SecuritySummaryStep'
import SecuritySummaryEntry from './SecuritySummaryEntry'

import styles from "./style.module"

const SecuritySummary = ({
  edit,
  trustCenter,
}) => {
  const [securitySummaries, setSecuritySummaries] = useState(trustCenter.securitySummaries)
  const [editSecuritySummaries, setEditSecuritySummaries] = useState(false)

  return (securitySummaries.length > 0 || edit) && <div className={styles.SecuritySummaries}>
    {editSecuritySummaries && <SecuritySummaryStep
      onPanelClose={() => setEditSecuritySummaries(false)}
      onTrustCenterChange={trustCenter => setSecuritySummaries(trustCenter.securitySummaries)}
      trustCenter={trustCenter} />}

    {edit && <a onClick={() => setEditSecuritySummaries(true)} className="is-pulled-right has-text-weight-bold">Edit</a>}
    <h2 className={styles.h2}>Security summary</h2>

    {securitySummaries.length > 0 && <div className="columns">
      <div className="column is-half" style={{ marginBottom: '-23px' }}>
        {securitySummaries.filter((element, index) => index % 2 === 0).map(securitySummary => (
          <SecuritySummaryEntry key={securitySummary.slug} securitySummary={securitySummary} />
        ))}
      </div>
      <div className="column is-half">
        {securitySummaries.filter((element, index) => index % 2 === 1).map(securitySummary => (
          <SecuritySummaryEntry key={securitySummary.slug} securitySummary={securitySummary} />
        ))}
      </div>
    </div>}

    {securitySummaries.length == 0 && <EmptyState message="Create a summary of your company’s security policies." />}
  </div>
}

export default SecuritySummary
