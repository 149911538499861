import React from "react"

const CompanyArchive = ({
  companySlug,
  isArchived
}) => {
  return <div className="content has-border-top">
    <div className="columns">
      <div className="column">
        <div className="content">
          <div className="columns">
            <div className="column is-half is-offset-one-quarter">
              <p className="has-text-centered">
                {isArchived && <a
                  data-confirm="Are you sure?"
                  className="button is-primary is-outlined is-gray"
                  style={{ width: '172px' }}
                  rel="nofollow"
                  data-method="post"
                  href={`/companies/${companySlug}/restore`}>
                  Restore Company
                </a>}
                {!isArchived && <a
                  data-confirm="Are you sure?"
                  className="button is-primary is-outlined is-gray"
                  style={{ width: '172px' }}
                  rel="nofollow"
                  data-method="delete"
                  href={`/companies/${companySlug}`}>
                  Archive Company
                </a>}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default CompanyArchive
