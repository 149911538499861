import React from "react"
import utils from 'components/utils/utils'

const DocumentResourceRow = ({ documentResource }) => {
  return <tr>
    <td className="has-text-centered" style={{ height: '48px', width: '48px' }}>
      <input
        name="document_resource_ids"
        type="checkbox"
        value={documentResource.id} />
    </td>
    <td style={{ padding: '16px 15px' }}>
      <div className="columns is-align-items-center">
        <div className="column is-file-preview is-medium-file-preview">
          <a className="preview" href={`/documents/${documentResource.slug}`}>
            <img
              alt={documentResource.name}
              className="small"
              src={utils.usePreviewCDN(`/document_resources/${documentResource.slug}/preview?resize=60x80`, documentResource)} />
          </a>
        </div>
        <div className="column">
          <h3 className="is-marginless">{documentResource.name}</h3>
          <span className="is-link-with-icon icon-right-with-margin has-text-weight-normal">
            <a style={{ fontSize: '14px' }} className="is-link-with-icon icon-right-with-margin has-text-weight-normal" href={`/documents/${documentResource.slug}`}>
              View Document
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" fill="#424C53" fillRule="evenodd" />
              </svg>
            </a>
          </span>
        </div>
      </div>
    </td>
    <td>
      {documentResource.labels?.length > 0 && <ul style={{ listStyle: 'none' }}>
        {documentResource.labels.sort((a, b) => {
          const nameA = a.name.toUpperCase()
          const nameB = b.name.toUpperCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        }).map(label => (
          <li
            key={label.id}
            style={{
              backgroundColor: '#f4f5f5',
              border: 0,
              borderRadius: '18px',
              color: '#424c53',
              float: 'left',
              fontSize: '12px',
              fontWeight: 600,
              marginRight: '5px',
              marginTop: 0,
              padding: '4px 12px',
            }}>
            {label.name}
          </li>
        ))}
      </ul>}
    </td>
    <td>
      <div className="action-links-wrapper">
        <a className="resource-list-button button is-link" href={`/documents/${documentResource.slug}/edit`}>
          <span className="icon is-small">
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.71 7.043a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83zM3 17.253v3.75h3.75l11.06-11.06-3.75-3.75L3 17.253z" fill="#424C53" fillRule="evenodd" />
            </svg>
          </span>
          <span>Edit Details</span>
        </a>
        <a className="button is-link" href={`/documents/${documentResource.slug}/file_history`}>
          <span className="icon is-small">
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 4c4.42 0 8 3.58 8 8s-3.58 8-8 8-8-3.58-8-8 3.58-8 8-8zm.5 3H11v6l5.25 3.15.75-1.23-4.5-2.67V7z" fill="#424C53" fillRule="evenodd" />
            </svg>
          </span>
          <span>Stats and Tools</span>
        </a>
      </div>
    </td>
  </tr >
}

export default DocumentResourceRow



