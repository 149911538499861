import React, { useState } from "react"

import styles from "./style.module"

const RequestCenterEdit = ({
  companySlug,
  requestForm,
}) => {
  const [copyText, setCopyText] = useState('Copy Link')
  const url = `${window.location.protocol}//${window.location.hostname}/p/${companySlug}/${requestForm.slug}`

  const copyLink = () => {
    navigator.clipboard.writeText(url)
    setCopyText('Copied!')
    setTimeout(() => {
      setCopyText('Copy Link')
    }, 2000);
  }

  const openEditPackageSideBar = () => {
    const id = "open-edit-package-side-bar"
    document.getElementById(id).click()
  }

  return <div className={`content has-dark-background ${styles.requestFormEdit}`} style={{ border: 0 }}>
    <div className={`columns ${styles.columns}`}>
      <div className="column" style={{ margin: '0 -12px' }}>
        <div className={`is-flex is-align-items-center is-justify-content-space-between ${styles.published}`} style={{ width: '600px', maxWidth: '600px', margin: 'auto' }}>

          {requestForm.packages.length == 0 && <div className="is-flex is-align-items-center">
            <span>Unlisted - <b><a onClick={() => openEditPackageSideBar('package')}>Add a package</a></b></span>
          </div>}

          {requestForm.packages.length > 0 && <div className="is-flex is-align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <polygon fill="#2BC9B4" fillRule="evenodd" points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59" />
            </svg>
            <span style={{ paddingLeft: '10px' }}>Publicly available</span>
          </div>}

          {requestForm.packages.length > 0 && <div className="is-flex is-align-items-center">
            <b>
              {copyText == 'Copy Link' && <a style={{ marginRight: '10px' }} onClick={() => copyLink()}>{copyText}</a>}
              {copyText == 'Copied!' && <span style={{ marginRight: '10px' }}>{copyText}</span>}
            </b>
            <b>
              <a href={url} target="_blank" className="is-flex is-align-items-center">
                View
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ height: '18px' }}>
                  <path fill="#0075E0" fillRule="evenodd" d="M19,19 L5,19 L5,5 L12,5 L12,3 L5,3 C3.89,3 3,3.9 3,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,12 L19,12 L19,19 Z M14,3 L14,5 L17.59,5 L7.76,14.83 L9.17,16.24 L19,6.41 L19,10 L21,10 L21,3 L14,3 Z" />
                </svg>
              </a>
            </b>
          </div>}

          {requestForm.packages.length == 0 && <div style={{ color: '#8C9296', cursor: 'not-allowed' }} className="is-flex is-align-items-center">
            <div style={{ marginRight: '10px' }}>
              <b>{copyText}</b>
            </div>
            <div className="is-flex is-align-items-center">
              <b>
                View
              </b>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{ height: '18px' }}>
                <path fill="#8C9296" fillRule="evenodd" d="M19,19 L5,19 L5,5 L12,5 L12,3 L5,3 C3.89,3 3,3.9 3,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,12 L19,12 L19,19 Z M14,3 L14,5 L17.59,5 L7.76,14.83 L9.17,16.24 L19,6.41 L19,10 L21,10 L21,3 L14,3 Z" />
              </svg>
            </div>
          </div>}
        </div>
      </div>
    </div>
  </div>
}

export default RequestCenterEdit
