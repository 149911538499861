import React, { useState } from "react";
import ReactDOM from 'react-dom'
import Turbolinks from "turbolinks";

import SignAgreementModalForm from './SignAgreementModalForm'

function SignAgreementButton(props) {
  const [tosAccepted, setTosAccepted] = useState(props.tosAccepted)
  const [signAgreementModalFormOpen, setSignAgreementModalFormOpen] = useState(false)

  const refreshCurrentPage = () => {
    Turbolinks.visit(window.location.toString(), { action: 'replace' })
  }

  const willCloseAgreementModalForm = (shouldRefreshPage) => {
    if (shouldRefreshPage) {
      refreshCurrentPage()
    } else {
      setSignAgreementModalFormOpen(false)
    }
  }

  const signAgreementModalForm = signAgreementModalFormOpen ? ReactDOM.createPortal(
    <SignAgreementModalForm
      {...props}
      cancel={(shouldRefreshPage) => willCloseAgreementModalForm(shouldRefreshPage)}
      onSave={() => refreshCurrentPage()}
      onTosAccepted={(accepted) => setTosAccepted(accepted)}
      tosAcceptedInital={tosAccepted} />,
    document.getElementById('modal-container')
  ) : null

  return (<div className="column is-marginless has-text-right" style={{ position: 'relative' }}>
    <a className="button is-primary" style={{ position: 'absolute', top: '50%', right: '0.75rem', marginTop: '-25px', fontWeight: 'bold', lineHeight: '48px' }} onClick={() => setSignAgreementModalFormOpen(true)}>
      Sign
            </a>
    {signAgreementModalForm}
  </div>)
}

export default SignAgreementButton
