import React, { useEffect, useMemo, useState } from "react"

const AllRowSelect = ({
  checked,
  indeterminate,
  onChange = () => { },
  resourceList,
  resourceType,
}) => {
  const id = useMemo(() => (`all-row-select-${resourceType}-checkbox`), [resourceType])
  const inputFieldName = useMemo(() => (resourceType === 'contact' ? `${resourceType}_ids` : `${resourceType}_resource_ids`), [resourceType])
  const [selectedCount, setSelectedCount] = useState(0)

  const onSelectedRowChange = () => {
    const selectAll = document.querySelector(`#${id}`)
    const checkboxes = selectAll.closest('.modal-card-body').querySelectorAll(`input[name="${inputFieldName}"]`)

    let oneChecked = false
    let allChecked = true
    let selected = 0

    for (const checkbox of checkboxes) {
      if (checkbox.checked) {
        selected++
        oneChecked = true
      } else {
        allChecked = false
      }
    }

    setSelectedCount(selected)

    if (oneChecked) {
      if (allChecked) {
        selectAll.indeterminate = false
        selectAll.checked = true
      } else {
        selectAll.indeterminate = true
      }
    } else {
      selectAll.indeterminate = false
      selectAll.checked = false
    }
  }

  useEffect(() => {
    const checkbox = document.querySelector(`#${id}`)
    checkbox.checked = checked
    checkbox.indeterminate = !checkbox.checked ? indeterminate : undefined
  }, [checked, indeterminate])

  useEffect(() => {
    const selectAll = document.querySelector(`#${id}`)
    const checkboxes = selectAll.closest('.modal-card-body').querySelectorAll(`input[name="${inputFieldName}"]`)

    checkboxes.forEach(checkbox => {
      checkbox.addEventListener('change', (e) => onSelectedRowChange())
    })

    onSelectedRowChange()
  }, [resourceList])

  return <li style={{ borderBottom: 'solid 1px silver', marginTop: '0px', padding: '11px' }}>
    <label>
      <div className={`columns share-${resourceType}s-resource-row`}>
        <div className="column fixed-55-width">
          <div className="pure-material-checkbox is-vertically-centered-checkbox">
            <input
              defaultChecked={checked}
              id={id}
              onChange={e => {
                let selected = 0
                const resourceCheckboxes = document.querySelectorAll(`input[type="checkbox"][name="${inputFieldName}"]`)
                resourceCheckboxes.forEach((resourceCheckbox) => {
                  resourceCheckbox.checked = e.target.checked
                  if (e.target.checked) {
                    selected++
                  }
                })
                setSelectedCount(selected)
                onChange()
              }}
              type="checkbox" />
            <span></span>
          </div>
        </div>

        <div className="column is-four-fifth">
          {selectedCount == 0 && `Select at least one ${resourceType} to share.`}
          {selectedCount > 0 && `${selectedCount} selected`}
        </div>
      </div>
    </label>
  </li>
}

export default AllRowSelect
