import React, { useEffect, useRef, useState } from "react"

import utils from '../../utils/utils'

const Entity = ({
  disabled,
  entity,
  previewUrl,
  subtitle,
  url,
}) => {
  const description = useRef(null)
  const [extended, setExtended] = useState(false)
  const [extendButton, setExtendButton] = useState(false)

  useEffect(() => {
    const clientHeight = description.current.clientHeight
    const scrollHeight = description.current.scrollHeight

    if (scrollHeight - clientHeight > 1) {
      setExtendButton(true)
    }
  }, [description])

  return <div className={`columns ${extended ? '' : 'is-align-items-center'}`}>
    <div className={`column preview ${disabled ? 'disabled' : ''}`} style={{ flex: 'none', width: '90px', opacity: (disabled ? 0.5 : 1) }}>
      {!disabled && <a href={url}>
        <img alt={entity.name}
          onError={(e) => entity.id ? utils.retryFetchImage(e) : null}
          src={previewUrl}
          style={{ borderRadius: '4px', border: 'solid 1px #424c53', display: 'block', height: '80px', width: '60px' }} />
      </a>}
      {disabled && <img alt="placeholder"
        src=""
        style={{ borderRadius: '4px', border: 'solid 1px #424c53', display: 'block', height: '80px', width: '60px' }} />}
    </div>
    <div className="column" style={{ fontSize: '14px', color: '#18242d', display: 'flex', flexDirection: 'column' }}>
      <strong>{entity.name}</strong>
      <p className="m-0" ref={description} style={{ maxHeight: !extended ? '20px' : null, overflow: 'hidden' }}>
        {subtitle}
      </p>
      {extendButton && <a onClick={() => setExtended(!extended)}>Show {extended ? 'less' : 'more'}</a>}
    </div>
  </div>
}

export default Entity
