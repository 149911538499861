import React, { useState } from "react"

const AccessPassSettings = (props) => {
  const accessPassExpirationInMinutes = props.accessPassExpirationInMinutes || ''
  const [accessPassOptionAvailable, setAccessPassOptionAvailable] = useState(accessPassExpirationInMinutes !== '')

  return <div className="field">
    <div className="control">
      <label className="pure-material-radio is-small" htmlFor="auto_approve_agreements_false">
        <input type="radio"
          name="auto_approve_agreements"
          id="auto_approve_agreements_false"
          value="false"
          checked={!accessPassOptionAvailable}
          onChange={() => setAccessPassOptionAvailable(false)} />
        <span>One time click</span>
      </label>
      <br />

      <label className="pure-material-radio is-small" htmlFor="auto_approve_agreements_true">
        <input type="radio" name="auto_approve_agreements"
          id="auto_approve_agreements_true"
          value="true"
          checked={accessPassOptionAvailable}
          onChange={() => setAccessPassOptionAvailable(true)} />
        <span>Time based</span>
      </label>
      <br />

      <div className="control" style={{ marginLeft: '29px', marginTop: '10px' }}>
        <div className="field">
          <div className="control select">
            <input type="hidden" name="company[access_pass_expiration_in_minutes]" value="" />
            <select defaultValue={accessPassOptionAvailable ? accessPassExpirationInMinutes : ''}
              disabled={!accessPassOptionAvailable}
              className="valid"
              name="company[access_pass_expiration_in_minutes]"
              id="company_access_pass_expiration_in_minutes"
              aria-invalid="false"
              readOnly>
              {!accessPassOptionAvailable && <option></option>}
              <option value="1">1 Minute</option>
              <option value="60">1 Hour</option>
              <option value="1440">1 Day</option>
              <option value="11520">1 Week</option>
            </select>
          </div>
        </div>

      </div>
    </div>
  </div>
}

export default AccessPassSettings
