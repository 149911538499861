import React, { useMemo } from "react"
import moment from 'moment'
import 'moment-timezone'
import Moment from 'react-moment'
import { TailSpin } from "react-loader-spinner"

import styles from "../style.module"
import utils from '../utils'


const Activity = ({
  data,
  startDate
}) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

  const history = useMemo(() => {
    if (!data) {
      return
    }

    const history = {}

    for (const [index, envelop] of Object.entries(data)) {
      const envelopCreatedAt = moment(envelop.created_at)

      if (envelopCreatedAt < startDate) {
        continue
      }

      const date = envelopCreatedAt.startOf('day').valueOf()

      history[date] = history[date] || { 'entries': [], 'formattedDate': envelopCreatedAt.format('MMMM DD, YYYY') }

      const actor = envelop.user ? `${envelop.user.first_name} ${envelop.user.last_name}` : 'Pima'
      const partnerName = envelop.partner.name
      const contacts = (envelop.documents.concat(envelop.agreements)[0].contacts || [envelop.agreements[0].signed_by]).filter(contact => contact !== null)

      const shares = []
      if (envelop.documents.length > 0) {
        shares.push(`${envelop.documents.length} Document${envelop.documents.length > 1 ? 's' : ''}`)
      }
      if (envelop.agreements.length > 0) {
        shares.push(`${envelop.agreements.length} Agreement${envelop.agreements.length > 1 ? 's' : ''}`)
      }

      history[date].entries.push(
        <div className="my-4 p-2 is-flex is-flex-direction-row" key={`history-entry-${index}`} style={{ border: 'solid 1px #ced0d2', borderRadius: '8px', color: '#18242d' }}>
          <div className="px-3">
            <Moment format="hh:mm A zz" date={envelop.created_at} tz={tz} />
          </div>
          <div className="pl-3">
            {actor} sent an envelope with <a href={`/companies/${envelop.partner.slug}`}>{shares.join(' and ')}</a> to {contacts && contacts.length > 0 && `${contacts.map((contact) => `${contact.first_name} ${contact.last_name}`).join(', ')} at`} <a href={`/companies/${envelop.partner.slug}`}>{partnerName}</a>
          </div>
        </div>
      )
    }

    const historyByDate = []
    if (Object.keys(history).length > 0) {
      const orderedHistory = utils.sortByReversedKey({ object: history, reversed: true })
      for (const [_date, historyEntries] of Object.entries(orderedHistory)) {
        historyByDate.push(<div key={historyEntries.formattedDate}>
          <b>{historyEntries.formattedDate}</b>
          {historyEntries.entries}
        </div>)
      }
    } else {
      historyByDate.push(<div className="has-text-centered" key="0" style={{ width: '100%', marginTop: '130px' }}>
        Envelopes you send will appear here.
      </div>)
    }

    return historyByDate
  }, [data, startDate])

  return <div className={`content ${styles.module}`}>
    <div className="columns" style={{ marginBottom: 0 }}>
      <div className="column is-flex">
        <h2>Activity</h2>
      </div>
      {/* <div className="column has-text-right">
                <a href=""><b>Search</b></a>
                </div>
                <div className="column has-text-right">
                <a href=""><b>Export All</b></a>
            </div> */}
    </div>
    <div className="separator"></div>
    <div className="columns">
      <div className="column">
        {history}
        {!data && <div className="is-flex is-align-items-center is-justify-content-center" style={{ width: '100%' }}>
          <TailSpin color="#18242d" height={24} width={24} />
        </div>}
      </div>
    </div>
  </div>
}

export default Activity