import React, { useState } from "react"
import { useForm } from 'react-hook-form'
import moment from 'moment'

import utils from '../utils/utils'

import Modal from "./../Modal"
import TextArea from "./../inputs/TextArea"

const NoteModalForm = (props) => {
  const note = props.note
  const onCancel = props.onCancel
  const onDelete = props.onDelete
  const onSave = props.onSave
  const partnerSlug = props.partnerSlug

  const formOptions = { mode: "onChange" }

  if (note) {
    formOptions['defaultValues'] = { notes: note.notes }
  }

  const { register, handleSubmit, errors, formState, getValues } = useForm(formOptions)

  const [noteLength, setNoteLength] = useState(false)
  const [showDeleteNoteModal, setShowDeleteNoteModal] = useState(false)

  const noteUrl = () => {
    return `/companies/${partnerSlug}/notes${(note && note.id) ? `/${note.id}` : ''}`
  }

  const noteFetchParams = (method, data) => {
    const params = {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': utils.csrfToken()
      }
    }

    if (data) {
      params['body'] = JSON.stringify({ note: data })
    }

    return params
  }

  const submitNote = (data) => {
    if (note && note.id) {
      fetch(noteUrl(), noteFetchParams('PATCH', data))
        .then(json => {
          note.notes = data['notes']
          note.created_at = moment().format()
          onSave(note, false)
        })
    } else {
      fetch(noteUrl(), noteFetchParams('POST', data))
        .then(response => response.json())
        .then(json => onSave(json, true))
    }
  }

  const updateCount = () => {
    const values = getValues()
    if ('notes' in values) {
      setNoteLength(values['notes'].length)
    } else {
      setNoteLength(0)
    }
  }

  const deleteNote = () => {
    fetch(noteUrl(), noteFetchParams('DELETE')).then(json => onDelete(note))
  }

  const confirmButton = <input className={`button is-info ${formState.isValid ? '' : 'disabled'}`}
    draggable="false"
    type="submit"
    value="Save" />

  setTimeout(function () {
    updateCount()
  }, 0)

  const deleteAction = () => {
    setShowDeleteNoteModal(true)
  }

  const content = !showDeleteNoteModal ? <form onSubmit={handleSubmit(submitNote)}>
    <Modal
      {...props}
      confirmButton={confirmButton}
      deleteAction={(note && note.id) ? deleteAction : false}
      deleteButtonText="Delete">
      <div className="columns">
        <div className="column">
          <TextArea
            errors={errors}
            label={false}
            name="notes"
            placeholder="Write a note..."
            onChange={() => updateCount()}
            reff={register({
              required: true,
              validate: {
                length: value => value.length <= 280,
              }
            })}
            style={{ height: '140px' }} />
          <p style={{ color: (noteLength > 280 ? '#d92022' : '#4a4a4a'), textAlign: 'right' }}>
            {noteLength}/280
          </p>
        </div>
      </div>
    </Modal>
  </form> : <Modal
    {...props}
    confirmAction={deleteNote}
    confirmButtonText="Delete Forever">
    <p>Are you sure you want to delete this note? This can not be undone.</p>
  </Modal>


  return <React.Fragment>
    {content}
  </React.Fragment>
}

export default NoteModalForm
