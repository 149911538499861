import React from "react"
import utils from '../utils/utils'

import Modal from "./../Modal"

const ContactAccessLinkModal = (props) => {
  const agreementSlug = props.agreementSlug
  const contact = props.contact
  const onSent = props.onSent
  const partnerSlug = props.partnerSlug

  const sendAccessLink = () => {
    utils.sendAccessPass(undefined, contact.id, partnerSlug, 'send_reminder').then(json => {
      if (json.success) {
        onSent(true)
      }
    })
  }

  return <Modal
    {...props}
    confirmAction={sendAccessLink}
    confirmButtonText='Send' />;
}

export default ContactAccessLinkModal
