import React from "react"
import utils from 'components/utils/utils'

const Preview = ({
  previewUrl,
  entity
}) => {
  return <img alt={entity.name}
    onError={(e) => entity.id ? utils.retryFetchImage(e) : null}
    src={utils.usePreviewCDN(previewUrl, entity)} />
}

export default Preview
