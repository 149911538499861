import React, { useEffect, useMemo, useState } from "react"
import { TailSpin } from "react-loader-spinner"

import ContactModalForm from '../contact/ContactModalForm'
import Modal from './../Modal'
import ContactList from './ContactList'
import PackageList from './PackageList'
import ShareResult from '../ShareDocuments/ShareResult'
import utils from '../utils/utils'

const ShareDocumentModalForm = ({
  filterPackageByIds = [],
  onCancel,
  onSave,
  packageCount,
  partner,
}) => {
  const [contacts, setContacts] = useState([])
  const [packages, setPackages] = useState([])
  const [step, setStep] = useState('loading')
  const [isSending, setIsSending] = useState(false)

  const [response, setResponse] = useState(null)

  const [selectedContacts, setSelectedContacts] = useState([])
  const [selectedPackages, setSelectedPackages] = useState([])
  const [signer, setSigner] = useState(null)

  const [openContactModal, setOpenContactModal] = useState(false)

  const sendPackages = () => {
    setIsSending(true)

    const data = {
      contact_ids: selectedContacts,
      package_ids: selectedPackages,
      signer_id: signer
    }

    utils
      .postJson(`/companies/${partner.slug}/send_packages`, { package: data })
      .then(res => {
        setResponse(res)
        setIsSending(false)
        setStep('done')
      }).catch(err => {
        setStep('error')
      })
  }

  const cancelButton = useMemo(() => {
    switch (step) {
      case 'packages':
      case 'contacts':
        return <a className="button is-white" onClick={onCancel}>Cancel</a>
      case 'done':
      default:
        return true
    }
  }, [step])

  const signerRequired = useMemo(() => (
    packages
      .filter(packageData => selectedPackages.indexOf(packageData.id) > -1)
      .some(selectedPackage => selectedPackage.agreement_resources.length > 0)
  ), [packages, selectedPackages])

  const nextStepButton = (stepValid, onClick) => (
    <a
      className={`button is-primary ${stepValid ? '' : 'disabled'}`}
      draggable="false"
      disabled={!stepValid}
      onClick={onClick}>Next</a>
  )

  const saveButton = useMemo(() => {
    switch (step) {
      case 'packages':
        const pacakgeStepValid = selectedPackages.length > 0
        return nextStepButton(pacakgeStepValid, () => pacakgeStepValid && setStep('contacts'))
      case 'contacts':
        if (isSending) {
          return <button className="button is-info"
            draggable="false">
            <TailSpin color="#FFFFFF" height={24} width={24} />
          </button>
        }
        const contactStepValid = (!signerRequired || (signerRequired && signer)) && selectedContacts.length > 0
        return nextStepButton(contactStepValid, () => contactStepValid && sendPackages())
      case 'done':
        return <a
          className="button is-info"
          draggable="false"
          onClick={() => onSave()}>Close</a>
      case 'loading':
        return <a
          className="button is-primary disabled"
          draggable="false"
          disabled={true}>Next</a>
      case 'empty':
      default:
        return <a
          className="button is-info"
          draggable="false"
          onClick={() => onCancel()}>Close</a>
    }
  }, [step, selectedPackages, selectedContacts, signer, isSending])

  const backButton = useMemo(() => {
    if (step !== 'contacts') {
      return
    }

    return <a className="button"
      draggable="false"
      onClick={() => setStep('packages')}
      style={{ paddingLeft: '12px', position: 'absolute', left: '40px' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
          <path fill="#F4F5F5" d="M-633-21H807v1470H-633z" />
          <path fill="#FFF" d="M-32-21h839v60H-32z" />
          <g transform="translate(-12 -9)">
            <rect width="89" height="35" x=".5" y=".5" stroke="#CED0D2" rx="6" />
            <path fill="#424C53" d="M27 17.25h-9.128l4.193-4.192L21 12l-6 6 6 6 1.057-1.057-4.185-4.193H27z" />
          </g>
        </g>
      </svg>
      <span style={{ paddingLeft: '10px' }}>Back</span>
    </a>
  }, [step])

  const popupTitle = useMemo(() => {
    switch (step) {
      case 'loading':
      case 'empty':
      case 'packages':
        return 'Packages'
      case 'contacts':
        return 'Contacts'
      case 'done':
        return 'Sent!'
      case 'error':
        return 'Error'
      default:
        return 'Packages'
    }
  }, [step])

  const elementList = useMemo(() => {
    switch (step) {
      case 'empty':
        return <p>Please create a package to get started.</p>
      case 'error':
        return <p className="has-text-danger">Something went wrong, please try again.</p>
      case 'loading':
        return <div>
          {Array(packageCount).fill().map((packageIndex, index) => (
            <div key={index} style={{ position: 'relative', marginBottom: '16px' }}>
              <div className="message loading-row" style={{ borderRadius: '8px', height: '88px' }}></div>
            </div>
          ))}
        </div>
      case 'packages':
        return <PackageList
          packages={packages}
          onChange={packageIds => setSelectedPackages(packageIds)}
          selectedPackages={selectedPackages}
        />
      case 'contacts':
        return <ContactList
          contacts={contacts}
          onContactSelected={contactIds => setSelectedContacts(contactIds)}
          onSignerSelected={signer => setSigner(signer)}
          selectedContacts={selectedContacts}
          setNewContact={() => setOpenContactModal(true)}
          signer={signer}
          signerRequired={signerRequired}
        />
      case 'done':
        let sentAgreements = []
        let sentDocuments = []
        packages
          .filter(packageData => selectedPackages.indexOf(packageData.id) > -1)
          .forEach(packageData => {
            sentAgreements = sentAgreements.concat(
              packageData.agreement_resources.map(agreement => agreement.id)
            )
            sentDocuments = sentDocuments.concat(
              packageData.document_resources.map(document => document.id)
            )
          })
        return <ShareResult
          agreements={[...new Set(sentAgreements)]}
          contacts={contacts
            .filter(contact => selectedContacts.indexOf(contact.id) > -1)}
          documents={[...new Set(sentDocuments)]}
          packages={packages.filter(packageData => selectedPackages.indexOf(packageData.id) > -1)}
          selectedPackages={selectedPackages}
          response={response}
          title={`Package${selectedPackages.length > 1 ? 's' : ''} Sent!`} />
    }
  }, [step, packages, contacts])

  const onContactSave = (contact) => {
    setContacts([...contacts, contact])
    setOpenContactModal(false)
  }

  const contactModalForm = useMemo(() => (
    <ContactModalForm
      alreadyExistingEmails={contacts.map(contact => contact.email)}
      agreementSlug={partner.slug}
      cancelAction={() => setOpenContactModal(false)}
      company={partner}
      deleteAction={false}
      onSave={(contact) => onContactSave(contact)}
      partnerName={partner.name}
      partnerSlug={partner.slug}
      title={"Add Contact"}
    />
  ), [contacts])

  const modalForm = <Modal
    cancelAction={() => onCancel()}
    cancelButton={cancelButton}
    confirmButton={saveButton}
    deleteButton={backButton}
    title={popupTitle}
    modalCardStyle={{ height: '600px', minWidth: '750px', overflowX: 'hidden' }}
    modalCardBodyStyle={{ padding: 0, overflowX: 'hidden' }}>
    <div className="content">
      <div className="columns">
        <div className="column document-list">
          {elementList}
        </div>
      </div>
    </div>
  </Modal>

  useEffect(() => {
    utils.getJson(`/companies/${partner.slug}/contacts.json`).then(contacts => setContacts(contacts))
    utils.getJson(`/packages.json${filterPackageByIds.length ? `?ids[]=${filterPackageByIds.join('&ids[]=')}` : ''}`).then(packages => {
      setStep(packages.length > 0 ? 'packages' : 'empty')
      setPackages(packages.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
    })
  }, [])

  useEffect(() => {
    if (step != 'packages' || selectedPackages.length == 0) {
      return
    }

    const topOffset = document.querySelector(`#package-${selectedPackages[0]}`).parentElement.offsetTop - 70
    document.querySelector('.modal-card-body').scrollTop = topOffset
  }, [step])

  return <>
    {!openContactModal && modalForm}
    {openContactModal && contactModalForm}
  </>
}

export default ShareDocumentModalForm
