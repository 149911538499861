import React, { useEffect, useState } from "react"
import moment from 'moment'

import Activity from './Activity'
import Graph from './Graph'

const CompanyDashboard = () => {
  const [dashboardData, setDataboardData] = useState(null)
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'))

  useEffect(() => {
    fetch('/dashboard.json')
      .then(response => response.json())
      .then(responseJson => setDataboardData(responseJson))
  }, [])

  return <div>
    <div className="content" style={{ backgroundColor: '#f4f5f5' }}>
      <div className="columns">
        <div className="column">
          <Graph
            data={dashboardData}
            onStartDateChange={startDate => setStartDate(startDate)}
            startDate={startDate} />
        </div>
      </div>
    </div>

    <Activity
      data={dashboardData}
      startDate={startDate} />
  </div>
}

export default CompanyDashboard
