import React, { useEffect, useMemo, useState } from "react"
import ReactDOM from 'react-dom'
import Turbolinks from "turbolinks"

import ShareActionSelector from '../SharePackages/ShareActionSelector'
import ShareDocumentModalForm from './ShareDocumentModalForm'
import SharePackageModalForm from '../SharePackages/SharePackageModalForm'
import UserPlanLimitReachBtn from '../Plans/UserPlanLimitReachBtn'

const ShareDocuments = (props) => {
  const {
    admins,
    adminEmails,
    buttonTitle,
    isAdmin,
    forceShareEnvelopes = false,
    packageCount,
    partner,
    planStatus,
    title,
    type,
  } = props

  const [showShareDocumentModalFormOpen, setShareDocumentModalFormOpen] = useState(false)
  const [showSharePackageModalForm, setShowSharePackageModalForm] = useState(false)
  const [showActionSelector, setShowActionSelector] = useState(false)

  const refreshCurrentPage = () => {
    Turbolinks.visit(window.location.toString(), { action: 'replace' })
  }

  const shareDocumentModalForm = useMemo(() => {
    if (!showShareDocumentModalFormOpen) {
      return
    }

    return ReactDOM.createPortal(
      <ShareDocumentModalForm
        {...props}
        cancel={() => setShareDocumentModalFormOpen(false)}
        onSave={() => refreshCurrentPage()} />,
      document.getElementById('modal-container')
    )
  }, [showShareDocumentModalFormOpen])

  const sharePackageModalForm = useMemo(() => {
    if (!showSharePackageModalForm) {
      return
    }

    return ReactDOM.createPortal(
      <SharePackageModalForm
        onCancel={() => setShowSharePackageModalForm(false)}
        onSave={() => refreshCurrentPage()}
        packageCount={packageCount}
        partner={partner} />,
      document.getElementById('modal-container')
    )
  }, [showSharePackageModalForm])

  const shareActionSelector = useMemo(() => {
    if (!showActionSelector) {
      return
    }

    return ReactDOM.createPortal(
      <ShareActionSelector
        canSharePackages={packageCount > 0}
        onCancel={() => setShowActionSelector(false)}
        onShareOptionSelect={option => {
          setShowActionSelector(false)
          switch (option) {
            case 'envelopes':
              setShareDocumentModalFormOpen(true)
              break;
            case 'packages':
              setShowSharePackageModalForm(true)
              break;
          }
        }} />,
      document.getElementById('modal-container')
    )
  }, [showActionSelector])

  return (
    <div className="content">
      <div className="columns">
        <div className="column">
          <h3 style={{ color: '#16232f', fontSize: '26px', fontWeight: 'bold' }}>
            {title}
          </h3>
        </div>
        {buttonTitle && <div className="column has-text-right">
          {planStatus.reached && <UserPlanLimitReachBtn
            type={type}
            limit={planStatus.limit}
            admins={admins}
            adminEmails={adminEmails}
            isAdmin={isAdmin} />}
          {!planStatus.reached && <a className="button is-primary"
            onClick={() => {
              if (forceShareEnvelopes) {
                setShareDocumentModalFormOpen(true)
                return
              }

              setShowActionSelector(!showActionSelector)
            }}
            style={{ fontWeight: 'bold', lineHeight: '48px' }}>
            {buttonTitle}
          </a>}
          {shareActionSelector}
          {shareDocumentModalForm}
          {sharePackageModalForm}
        </div>}
      </div>
    </div>)
}

export default ShareDocuments
