import React from "react"

const FileField = (props) => {
  const errors = props.errors
  const label = props.label
  const name = props.name
  const reff = props.reff

  const fieldId = name.replace('[', '_').replace(']', '_')

  let detectedError = errors[name]

  if (name.indexOf('[') > -1 && name.indexOf(']') > -1) {
    const brokenFieldName = name.split('[')
    const recordType = brokenFieldName[0]
    const recordField = brokenFieldName[1].replace(']', '')

    if (errors[recordType] && errors[recordType][recordField]) {
      detectedError = errors[recordType][recordField]
    }
  }

  let error

  if (detectedError && detectedError.type == 'required') {
    error = <label className="error">Required</label>
  }

  if (detectedError && [
    'agreementResourceFileFormat',
    'agreementResourceFileFormat',
  ].includes(detectedError.type)) {
    error = <label className="error">
      Only PDFs under 50 Mo can be uploaded at this time.
    </label>
  }

  if (detectedError && [
    'documentResourceFileFormat',
    'documentResourceFileSize'
  ].includes(detectedError.type)) {
    error = <label className="error">
      Only Excel and Word documents and PDFs under 50 Mo can be uploaded at this time.
    </label>
  }

  return <div className="field">
    <div className="control">
      <label className="label" htmlFor={fieldId}>{label}</label>
      <div className="file">
        <label>
          <input className={`file-input ${detectedError ? 'error' : ''}`} ref={reff} type="file" name={name} id={fieldId} />
          {error}
          <span className="file-cta button is-primary">
            <span className="icon is-small">
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" fill="#424C53" fillRule="evenodd"></path>
              </svg>
            </span>
            <span className="file-label">Choose File</span>
          </span>
          <span className="file-name"></span>
        </label>
      </div>
    </div>
  </div>
}

export default FileField
