const supportedAgreementResourceFormats = [
  "application/pdf"
]

const supportedDocumentResourceFormats = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.ms-excel.sheet.macroenabled.12",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf"
]

const fileFormatValid = (fileInput, supportedFormats) => {
  const file = fileInput[0]
  return supportedFormats.includes(file.type)
}

const fileSizeValid = (fileInput) => {
  const file = fileInput[0]
  const fileSizeInMB = file.size / 1024 / 1024
  return fileSizeInMB <= 50
}

const requestCenterOptions = (requestCenters) => {
  return requestCenters.sort((a, b) => {
    const nameA = a.name.toUpperCase() // ignore upper and lowercase
    const nameB = b.name.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }).map(requestCenter => {
    return { label: requestCenter.name, value: requestCenter.id }
  })
}

export default {
  fileFormatValid,
  fileSizeValid,
  requestCenterOptions,
  supportedAgreementResourceFormats,
  supportedDocumentResourceFormats
}
