import React, { useMemo, useState } from "react"
import ReactDOM from 'react-dom'
import RequestCenterUpgradeModal from './RequestCenterUpgradeModal'

const RequestCenterUpgrade = ({ requestCenter }) => {
  const [requestCenterModalFormOpen, setRequestCenterModalFormOpen] = useState(false)

  const requestCenterModalForm = useMemo(() => {
    if (!requestCenterModalFormOpen) return

    return ReactDOM.createPortal(
      <RequestCenterUpgradeModal
        onCancel={() => setRequestCenterModalFormOpen(false)}
        requestCenter={requestCenter} />,
      document.getElementById('modal-container')
    )
  }, [requestCenterModalFormOpen])

  return <>
    <a onClick={() => setRequestCenterModalFormOpen(true)}>Migrate</a>
    {requestCenterModalForm}
  </>
}

export default RequestCenterUpgrade