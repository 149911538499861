import React from "react"

const LoginCta = () => {
  return (
    <div className="content login-cta">
      <div className="columns">
        <div className="column is-main-content">
          <div className="has-text-centered" style={{ padding: '24px 0' }}>
            <h2>What is Pima?</h2>
            <p>
              Pima offers a simple way to deliver confidential documents to your
              <br />
              prospects. Here is how it works:
            </p>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column column-with-icon">
          <svg width="56px" height="56px" viewBox="0 0 56 56">
            <title>Education Assets / edu-upload-documents</title>
            <g id="Education-Assets-/-edu-upload-documents" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Group-4" transform="translate(4.000000, 16.000000)">
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path d="M14,2 L6,2 C4.9,2 4.01,2.9 4.01,4 L4,20 C4,21.1 4.89,22 5.99,22 L18,22 C19.1,22 20,21.1 20,20 L20,8 L14,2 Z M16,18 L8,18 L8,16 L16,16 L16,18 Z M16,14 L8,14 L8,12 L16,12 L16,14 Z M13,9 L13,3.5 L18.5,9 L13,9 Z" id="Shape" fill="#0075E0" fillRule="nonzero"></path>
                <polygon id="Path" points="25 0 49 0 49 24 25 24"></polygon>
                <path d="M44.35,10.04 C43.67,6.59 40.64,4 37,4 C34.11,4 31.6,5.64 30.35,8.04 C27.34,8.36 25,10.91 25,14 C25,17.31 27.69,20 31,20 L44,20 C46.76,20 49,17.76 49,15 C49,12.36 46.95,10.22 44.35,10.04 Z M39,13 L39,17 L35,17 L35,13 L32,13 L37,8 L42,13 L39,13 Z" id="Shape" fill="#0075E0" fillRule="nonzero"></path>
              </g>
            </g>
          </svg>
          <h3>1. Upload Documents and Agreements</h3>
          <p className="with-max-width">Pima provides a central location to manage these important files.</p>
        </div>

        <div className="column column-with-icon">
          <svg width="56px" height="56px" viewBox="0 0 56 56" version="1.1">
            <title>Education Assets / edu-sharing-options</title>
            <g id="Education-Assets-/-edu-sharing-options" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Group-2" transform="translate(8.000000, 18.000000)" fill="#0075E0" fillRule="nonzero">
                <path d="M38,14.08 C37.24,14.08 36.56,14.38 36.04,14.85 L28.91,10.7 C28.96,10.47 29,10.24 29,10 C29,9.76 28.96,9.53 28.91,9.3 L35.96,5.19 C36.5,5.69 37.21,6 38,6 C39.66,6 41,4.66 41,3 C41,1.34 39.66,-2.13162821e-14 38,-2.13162821e-14 C36.34,-2.13162821e-14 35,1.34 35,3 C35,3.24 35.04,3.47 35.09,3.7 L28.04,7.81 C27.5,7.31 26.79,7 26,7 C24.34,7 23,8.34 23,10 C23,11.66 24.34,13 26,13 C26.79,13 27.5,12.69 28.04,12.19 L35.16,16.35 C35.11,16.56 35.08,16.78 35.08,17 C35.08,18.61 36.39,19.92 38,19.92 C39.61,19.92 40.92,18.61 40.92,17 C40.92,15.39 39.61,14.08 38,14.08 Z" id="Path"></path>
                <path d="M10,0 L2,0 C0.9,0 0.01,0.9 0.01,2 L0,18 C0,19.1 0.89,20 1.99,20 L14,20 C15.1,20 16,19.1 16,18 L16,6 L10,0 Z M12,16 L4,16 L4,14 L12,14 L12,16 Z M12,12 L4,12 L4,10 L12,10 L12,12 Z M9,7 L9,1.5 L14.5,7 L9,7 Z" id="Shape"></path>
              </g>
            </g>
          </svg>
          <h3>3. Multiple Sharing Options</h3>
          <p className="with-max-width">Prospects can request documents directly from your website or your team can manually send them as needed.</p>
        </div>
      </div>

      <div className="columns">
        <div className="column column-with-icon">
          <svg width="56px" height="56px" viewBox="0 0 56 56" version="1.1">
            <title>Education Assets / edu-configure-sharing</title>
            <g id="Education-Assets-/-edu-configure-sharing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Group-5" transform="translate(8.000000, 17.000000)" fill="#0075E0" fillRule="nonzero">
                <g id="Group">
                  <path d="M15,0 L5,0 C2.24,0 0,2.24 0,5 C0,7.76 2.24,10 5,10 L15,10 C17.76,10 20,7.76 20,5 C20,2.24 17.76,0 15,0 Z M5,8 C3.34,8 2,6.66 2,5 C2,3.34 3.34,2 5,2 C6.66,2 8,3.34 8,5 C8,6.66 6.66,8 5,8 Z" id="Shape"></path>
                  <path d="M35,13 L25,13 C22.24,13 20,15.24 20,18 C20,20.76 22.24,23 25,23 L35,23 C37.76,23 40,20.76 40,18 C40,15.24 37.76,13 35,13 Z M35,21 C33.34,21 32,19.66 32,18 C32,16.34 33.34,15 35,15 C36.66,15 38,16.34 38,18 C38,19.66 36.66,21 35,21 Z" id="Shape"></path>
                </g>
              </g>
            </g>
          </svg>
          <h3>2. Configure Automatic Sharing Rules</h3>
          <p className="with-max-width">For example, require a prospect to sign an NDA before receiving your SOC 2 report.</p>
        </div>

        <div className="column column-with-icon">
          <svg width="56px" height="56px" viewBox="0 0 56 56" version="1.1">
            <title>Education Assets / edu-delivery-tracking</title>
            <g id="Education-Assets-/-edu-delivery-tracking" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Group-3" transform="translate(10.000000, 20.000000)" fill="#0075E0">
                <path d="M34,0 L18,0 C16.9,0 16.01,0.9 16.01,2 L16,14 C16,15.1 16.9,16 18,16 L34,16 C35.1,16 36,15.1 36,14 L36,2 C36,0.9 35.1,0 34,0 Z M34,4 L26,9 L18,4 L18,2 L26,7 L34,2 L34,4 Z" id="Shape" fillRule="nonzero"></path>
                <rect id="Rectangle" x="0" y="4" width="12" height="3"></rect>
                <rect id="Rectangle" x="5" y="9" width="7" height="3"></rect>
              </g>
            </g>
          </svg>
          <h3>4. Automated Delivery and Tracking</h3>
          <p className="with-max-width">Watermarking, logging, tracking and delivery of your reports are automatically handled by Pima.</p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-main-content">
          <div className="has-text-centered" style={{ padding: '24px 0' }}>
            <h2>Are You Ready?</h2>
            <p>
              Start sharing compliance documents with your prospects.<br />The first 10 shares are free.
            </p>
            <a href="/login" className="button is-primary is-outlined">Get Started for Free</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginCta
