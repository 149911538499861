import React from "react"
import NumberFormat from 'react-number-format'

const PlanRow = (props) => {
  const available = props.available || false
  const checked = props.checked || false
  const isCurrentPlan = props.isCurrentPlan || false
  const plan = props.plan || {}
  const hasPlan = Object.keys(plan).length > 0
  const onPlanSelectedDefault = () => { }
  const onPlanSelected = props.onPlanSelected || onPlanSelectedDefault

  return <div className={`is-relative ${!hasPlan ? 'loading-row' : ''}`}>
    <input
      defaultChecked={checked}
      disabled={available === false}
      key="actionSelectDocument"
      id={`plan_${plan.name}`}
      name="action"
      onChange={() => onPlanSelected(plan)}
      style={{ left: '35px', top: '37px' }}
      type="radio"
      value={`plan_${plan.name}`} />
    <label
      className="button"
      htmlFor={`plan_${plan.name}`}
      style={{ borderRadius: '8px', borderWidth: checked ? '2px' : '1px', borderColor: checked ? '#0075e0' : '#ced0d2', height: '88px', padding: checked ? "19px 0px 0px 74px" : "20px 0px 0px 75px", marginBottom: '16px' }}>
      <strong style={{ opacity: (available === false ? '0.5' : '1') }}>
        {hasPlan && `${plan.name}${isCurrentPlan ? ' (Current Plan)' : ''}`}
        {!hasPlan && <div style={{ backgroundColor: '#ced4da', height: '18px', opacity: '0.7', width: '150px' }}></div>}
      </strong>
      <br />
      <small style={{ fontSize: '14px', fontWeight: 'normal', opacity: (available === false ? '0.5' : '1') }}>
        {hasPlan && (plan.price ? <React.Fragment>
          <NumberFormat value={plan.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per month
        </React.Fragment> : 'No monthly charge')}
        {!hasPlan && <div style={{ backgroundColor: '#ced4da', height: '14px', width: '250px', position: 'absolute', bottom: '20px' }}></div>}
      </small>
      {hasPlan && <small style={{ color: checked ? '#0075e0' : '#8c9296', position: 'absolute', right: checked ? '29px' : '30px', top: !available ? 'calc( 50% - 18px )' : 'calc( 50% - 9px )' }}>
        {available && `${plan.share_count == 0 ? 'Unlimited' : plan.share_count} share${plan.share_count > 1 || plan.share_count == 0 ? 's' : ''}, ${plan.document_count == 0 ? 'Unlimited' : plan.document_count} document${plan.document_count > 1 || plan.document_count == 0 ? 's' : ''}, ${plan.agreement_count == 0 ? 'Unlimited' : plan.agreement_count} agreement${plan.agreement_count > 1 || plan.agreement_count == 0 ? 's' : ''}`}
        {!available && <React.Fragment>
          Remove documents or agreements to downgrade.
          <br />
          <a style={{ textAlign: 'right', width: '100%', display: 'inline-block' }} href="https://pima.app/pricing/" target="_blank">Learn More</a>
        </React.Fragment>}
      </small>}
    </label>
  </div>
}

export default PlanRow
