import React, { useEffect, useMemo, useState } from "react"
import ReactDOM from 'react-dom'

import Contacts from "../contact/Contacts"
import History from "../CompanyHistory/History"

const CompanyHeader = ({
  archived,
  company,
  contact,
  contacts,
  contactCount,
  currentUserEmail,
  eventCount,
  isNonLoggedInView = false,
}) => {
  const [highlightRequestSlug, sethighlightRequestSlug] = useState(null)
  const [showContactPanel, setShowContactPanel] = useState(() => new URLSearchParams(window.location.search).has('highlightContact'))
  const [showHistoryPanel, setShowHistoryPanel] = useState(false)

  useEffect(() => {
    if (showContactPanel) {
      const params = new URLSearchParams(document.location.search)
      params.delete('highlightContact')
      window.history.pushState(
        { companySlug: document.location.pathname }, '', `${document.location.pathname}${params}`
      )
    }
  }, [showContactPanel])

  const contactPanel = useMemo(() => {
    if (showContactPanel && company) {
      return ReactDOM.createPortal(
        <Contacts
          company={company}
          editionDisabled={archived}
          contacts={contacts}
          onContactPanelClose={() => setShowContactPanel(false)}
        />,
        document.getElementById('modal-container'))
    }
  }, [company, showContactPanel])

  const historyPanel = useMemo(() => {
    if (showHistoryPanel && company) {
      return ReactDOM.createPortal(
        <History
          companyId={company.id}
          highlightRequestSlug={highlightRequestSlug}
          onPanelClose={() => setShowHistoryPanel(false)}
          partnerName={company.name}
          partnerSlug={company.slug}
        />,
        document.getElementById('modal-container'))
    }
  }, [company, showHistoryPanel])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const highlightRequest = searchParams.get('highlightRequest')
    if (highlightRequest) {
      sethighlightRequestSlug(highlightRequest)
      setShowHistoryPanel(true)

      const params = new URLSearchParams(document.location.search)
      params.delete('highlightRequest')
      window.history.pushState(
        { companySlug: document.location.pathname }, '', `${document.location.pathname}${params}`
      )
    }
  }, [])

  return <div className={`content ${!isNonLoggedInView ? 'has-dark-background' : ''}`}>
    <div className="columns">
      <div className="column">
        {company && isNonLoggedInView && company.logo && <img src={company.logo} alt={company.name} style={{ display: 'block', maxHeight: '30px' }} />}
        {company && (!isNonLoggedInView || !company.logo) && <h1>{company.name}</h1>}

        {!company && !isNonLoggedInView && <div style={{ position: 'relative', overflow: 'hidden' }}>
          <div className="message loading-row loading-row-light" style={{ height: '76px' }}></div>
        </div>}

        {!company && isNonLoggedInView && <div style={{ paddingBottom: '400px', paddingTop: '10px' }}>
          <div style={{ position: 'relative', overflow: 'hidden', width: '40%' }}>
            <div className="message loading-row loading-row-light loading-row-dark-font" style={{ height: '40px' }}></div>
          </div>
          <br />
          <div style={{ position: 'relative', overflow: 'hidden', width: '20%' }}>
            <div className="message loading-row loading-row-light loading-row-dark-font" style={{ height: '24px' }}></div>
          </div>
          <br />
          <br />
          <br />
          <div class="content has-no-border-bottom" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div class="columns">
              <div class="column">
                <div className="is-flex is-flex-direction-row is-justify-content-space-between" style={{ marginBottom: '4px' }}>
                  <div style={{ position: 'relative', overflow: 'hidden', width: '20%' }}>
                    <div className="message loading-row loading-row-light loading-row-dark-font" style={{ height: '16px' }}></div>
                  </div>
                  <div style={{ position: 'relative', overflow: 'hidden', width: '10%' }}>
                    <div className="message loading-row loading-row-light loading-row-dark-font" style={{ height: '16px' }}></div>
                  </div>
                </div>
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <div className="message loading-row loading-row-light loading-row-dark-font" style={{ height: '10px' }}></div>
                </div>
                <div style={{ position: 'relative', overflow: 'hidden', marginTop: '8px' }}>
                  <div className="message loading-row loading-row-light loading-row-dark-font" style={{ height: '10px' }}></div>
                </div>
                <div style={{ position: 'relative', overflow: 'hidden', marginTop: '8px' }}>
                  <div className="message loading-row loading-row-light loading-row-dark-font" style={{ height: '10px' }}></div>
                </div>
                <div style={{ position: 'relative', overflow: 'hidden', marginTop: '8px' }}>
                  <div className="message loading-row loading-row-light loading-row-dark-font" style={{ height: '10px' }}></div>
                </div>

              </div>
            </div>
          </div>
          <br />
          <br />
          <p className="has-text-centered" style={{ color: '#8c9296' }}>We are loading your data.</p>
        </div>}

        {company && !isNonLoggedInView && <a target="_blank" href={`https://${company.domain}`}>{company.domain}</a>}
        {company && isNonLoggedInView && <p style={{ color: '#424c53' }}>These documents were sent to {currentUserEmail} by {company.name}.</p>}
      </div >
    </div >
    {
      !isNonLoggedInView && <div className="columns">
        <div className="column">
          {company && <>
            {contact && <div className="button is-cell has-two-lines">
              <div style={{ float: 'left', width: '50px' }}>
                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" style={{ height: '32px', top: 'calc(50% - 16px)', position: 'absolute', width: '32px' }}>
                  <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,5 C13.66,5 15,6.34 15,8 C15,9.66 13.66,11 12,11 C10.34,11 9,9.66 9,8 C9,6.34 10.34,5 12,5 Z M12,19.2 C9.5,19.2 7.29,17.92 6,15.98 C6.03,13.99 10,12.9 12,12.9 C13.99,12.9 17.97,13.99 18,15.98 C16.71,17.92 14.5,19.2 12,19.2 Z" fill="#555" fillRule="evenodd"></path>
                </svg>
              </div>
              <div style={{ float: 'left', marginLeft: '50px' }}>
                <strong>{contact.first_name} {contact.last_name}</strong>
                <br />
                <small>{contact.email}</small>
              </div>
            </div>}

            {contactCount !== undefined && <a className="button is-cell has-two-lines" onClick={() => setShowContactPanel(true)}>
              <strong>Contacts</strong>
              <br />
              <small>{contactCount} person</small>
              <span className="carret_right">
                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6z" fill="#555" fillRule="evenodd"></path>
                </svg>
              </span>
            </a>}
          </>}
          {!company && <div style={{ position: 'relative', overflow: 'hidden' }}>
            <div className="message loading-row loading-row-light" style={{ height: '72px' }}></div>
          </div>}
        </div>
        <div className="column">
          {company && <a className="button is-cell has-two-lines" onClick={() => setShowHistoryPanel(true)}>
            <strong>History &amp; Tools</strong>
            <br />
            <small>{eventCount} event{eventCount != 1 ? 's' : ''}</small>
            <span className="carret_right">
              <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6z" fill="#555" fillRule="evenodd"></path>
              </svg>
            </span>
          </a>}
          {!company && <div style={{ position: 'relative', overflow: 'hidden' }}>
            <div className="message loading-row loading-row-light" style={{ height: '72px' }}></div>
          </div>}
        </div>
      </div>
    }
    {contactPanel}
    {historyPanel}
  </div >
}

export default CompanyHeader
