import React from "react"

import styles from "./style.module"

const TrustedReviewer = ({
  trustedReviewer,
}) => {
  return <div className={styles.TrustedReviewer}>
    <div style={{
      backgroundImage: `url(${trustedReviewer.logo})`,
      backgroundPosition: 'center center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '100px',
      width: '200px',
    }}></div>
  </div>
}

export default TrustedReviewer
